import React, {useState, useEffect} from 'react';
import { Input } from 'antd';
import { useScripter } from 'Scripter/Context/ScripterContext';
import useAxios from "axios-hooks";
import { Alert } from 'antd';
import { promiseMessage } from 'Shared/messages';
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { StyledModal } from 'Scripter/Shared/ModalStyles';



const ScopeModal = () => {
    const {scopeModalProps, setScopeModalProps} = useScripter();
    const [selector, setSelector] = useState("");
    const [CientError, setCientError] = useState({hasError: false, msg: ""});
      
    const [{ data: savedData, error: savedError }, saveScope] = useAxios(
        {
            method: "POST",
            url: `/scripter/scope/save`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: scopeData, error: scopeError }, getScope] = useAxios(
        {
            method: "GET",
            url: `/scripter/scope/${scopeModalProps.id}`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    useEffect(() => {
        scopeModalProps && getScope();
     }, [scopeModalProps])

    useEffect(() => {
        scopeData && setSelector(scopeData.selector ?? "body");
     }, [scopeData])

    useEffect(() => {
        savedData && setScopeModalProps({...scopeModalProps, visible: false});
    }, [savedData])

    useEffect(() => {
        if(savedError){
            const statusCode = savedError?.response?.status;
            if(statusCode != 500){
                const serverError = savedError?.response?.data?.Errors?.join(",")
                setCientError({hasError: true, msg: serverError});
            }
        }
     }, [savedError])

    const onSave = async () => {
        const result = await promiseMessage({type: "validate_scope_selector", selector});
        const url =  await getCurrentUrl();

        if(result.isValid && url){
            
            saveScope({data:{id: scopeModalProps.id, url,selector}});
            setCientError({hasError: false, msg: ""});
        }
        else {
            setCientError({hasError: true, msg: result?.error?.message || "Element is not exist on the page"});
        }
    }


    return (
        <StyledModal
            mask="false"
            title={`PROPERTIES: ${scopeModalProps.folderName} folder`}
            visible={scopeModalProps.visible}
            onOk={onSave}
            onCancel={() => setScopeModalProps({...scopeModalProps, visible: false})}
            okText="SAVE"
            cancelText="CANCEL"
        >
            {CientError.hasError === true && <Alert style={{marginBottom: 5}} message={CientError.msg} type="error" showIcon /> }
            <Input 
                onChange={(e) => setSelector(e.target.value)} 
                id="scope-selector" 
                addonBefore={<label for="scope-selector">CONTEXT</label>} 
                value={selector}
            />
        </StyledModal>
    )
 
};

export default ScopeModal;