import React, { useEffect, useState } from "react";
import { Tree, notification, Input, Popover, Button } from "antd";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import StyledSearchMenu from "Main/Views/Sidebar/PatternsTree/StyledSearchMenu";
import { treeData } from "./TreeItem/TreeItem";
import { DownOutlined, MoreOutlined, LoadingOutlined } from "@ant-design/icons";
import { TheSpin } from "../History/HistoryList";
import { StyledTree, StyledSearch } from "./Styles";
import {
  ShowOnlyVisibleAndNotBrokenTreeDictionaryElements,
  ShowOnlyVisibleAndNotVisibleTreeDictionary,
  ShowOnlyVisibleAndBroken,
  ShowPreviewHidden,
  FILTER_TYPES,
} from "./filters";
const { Search } = Input;

const PatternsTree = ({ backgroundColor, sideBarWidth }) => {
  const {
    treeDictionary,
    TreeLoading,
    TreeErrors,
    updatePatternTree,
    setQuery,
    query,
    highlightedItem,
    setHighlightedItem,
    selectedFilterOptions,
    expandedKeys,
    setExpandedKeys,
  } = useTree();
  const [treeDataList, setTreeDataList] = useState([]);
  const [computedTreeData, setComputedTreeData] = useState(null);
  useEffect(() => {
    if (treeDictionary) {
      const result = treeData(treeDictionary, query);
      setComputedTreeData(result);
    }
  }, [treeDictionary]);

  useEffect(() => {
    if (computedTreeData) {
      filterTree(selectedFilterOptions);
      if (Object.keys(treeDictionary).length === 1) {
        let keys = [...expandedKeys, Object.keys(treeDictionary)[0]];
        if (highlightedItem && highlightedItem.key) {
          keys = [...keys, highlightedItem.key];
        }
        setExpandedKeys(keys);
      } else if (highlightedItem && highlightedItem.key) {
        setExpandedKeys([...expandedKeys, highlightedItem.key]);
      }
    }
  }, [computedTreeData]);

  useEffect(() => {
    if (computedTreeData && computedTreeData.length > 0) {
      filterTree(selectedFilterOptions);
    }
  }, [selectedFilterOptions]);

  const runFilter = (filterByTypeCallback) => {
    const filteredTreeDictionary = {};
    filterByTypeCallback(treeDictionary, filteredTreeDictionary);
    const filteredComputedTreeData = treeData(filteredTreeDictionary, query);
    setTreeDataList(filteredComputedTreeData);
  };

  const filterTree = (checkedValues) => {
    switch (checkedValues.length) {
      case 2:
        setTreeDataList(computedTreeData);
        break;
      case 1:
        if (checkedValues[0] === FILTER_TYPES.BROKEN) {
          runFilter(ShowOnlyVisibleAndBroken);
        } else if (checkedValues[0] === FILTER_TYPES.NOT_ON_PAGE) {
          runFilter(ShowOnlyVisibleAndNotVisibleTreeDictionary);
        } else if (checkedValues[0] === FILTER_TYPES.HIDDEN) {
          runFilter(ShowPreviewHidden);
        }
        break;
      case 0:
        runFilter(ShowOnlyVisibleAndNotBrokenTreeDictionaryElements);
        break;
      default:
        setTreeDataList(computedTreeData);
        break;
    }
  };

  useEffect(() => {
    if (TreeErrors && TreeErrors.message) {
      notification.error({
        message: "Error",
        description: `Error occur while trying to get the patterns. Msg: ${TreeErrors.message}`,
        placement: "bottomRight",
        duration: 7,
      });
    }
  }, [TreeErrors]);
  return (
    <>
      <StyledSearch
        enterButton
        placeholder="SEARCH SELECTOR"
        allowClear="true"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        onSearch={(value, event) => {
          setQuery(value);
          updatePatternTree(value);
        }}
        addonAfter={
          <Popover
            content={StyledSearchMenu}
            placement="leftTop"
            trigger="click"
          >
            <Button
              type="link"
              icon={<MoreOutlined />}
              style={{ width: "20px", height: "38px" }}
            />
          </Popover>
        }
      />
      {TreeLoading && (
        <TheSpin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      )}
      {!TreeLoading && (
        <StyledTree
          backgroundColor={backgroundColor}
          treeData={treeDataList}
          autoExpandParent={true}
          defaultExpandAll={true}
          switcherIcon={<DownOutlined />}
          onExpand={(expanded) => setExpandedKeys(expanded)}
          expandedKeys={expandedKeys}
        />
      )}
    </>
  );
};

export default PatternsTree;
