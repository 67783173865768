import React, { useState, useEffect } from 'react';
import useAxios from "axios-hooks";
import { Form, Input, Button, Radio, Popconfirm, Typography, Modal } from 'antd';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getCurrentUrl } from 'Shared/Services/getCurrentUrl';
import { toastError, toastSuccess } from "Shared/utils";
import { colors } from "Shared/colors";
const { Text } = Typography;

export const StyledSaveButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  border: none;
  border-radius: 5px;
  background: ${colors.BLUE} !important;
  &:hover {
    background: ${colors.DARK_BLUE} !important;
  }
  &:focus {
    background: ${colors.DARK_BLUE} !important;
  }
  :disabled{
    background: grey !important;
    color: #999;
  }
`;
const StyledContainer = styled.div`

`;

const StyledLabel = styled.div`
`;

const StyledFormItem = styled(Form.Item)`
width:440px
`;

const StyledInputFirstRowContainer = styled.div`

`;

const StyledInput = styled(Input)`

`;
const StyledEditOutlined = styled(EditOutlined)`
color: black;
font-size:large;
`

const StyledButton = styled(Button)`
border:0px;
position: absolute;
right: 20px;
top: 110px;
`

const Copy = ({ }) => {
    const [isFromDisabled, setIsFromDisabled] = useState(true);
    const [isFromValid, setIsFromValid] = useState(null);
    const [isToValid, setIsToValid] = useState(null);
    const [timeoutFlag, setTimeoutFlag] = useState(null);
    const [popconfirmVisible, setPopconfirmVisible] = useState(false);

    const [{ data: fromData, loading: fromLoading, error: fromError }, fetchFrom,] = useAxios(
        {
            method: "POST",
            url: `/site/get-site-domain-and-id`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: toData, loading: toLoading, error: toError }, fetchTo,] = useAxios(
        {
            method: "POST",
            url: `/site/get-site-domain-and-id`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: copyData, loading: copyLoading, error: copyError }, fetchCopy,] = useAxios(
        {
            method: "POST",
            url: `/site/copy`,
            headers: {
                "content-type": "application/json",
            },
            validateStatus: false
        },
        { manual: true }
    );

    useEffect(() => {
        if (copyData) {
            if (copyData.Errors) {
                toastError(copyData.Errors.join(" "));
            } else {
                toastSuccess("Copy success");
            }
            setPopconfirmVisible(false);
        }
    }, [copyData]);

    const fetchData = (fetchAxiosCallBack, additionalData = null) => {
        (async () => {
            const url = await getCurrentUrl();
            if (url) {
                const dataToFetch = { url: url }
                if (typeof additionalData === 'object' && additionalData !== null) {
                    for (var key in additionalData) {
                        if (additionalData.hasOwnProperty(key)) {
                            dataToFetch[key] = additionalData[key];
                        }
                    }
                }
                fetchAxiosCallBack({ data: dataToFetch })
            }
        })();
    }

    const validateInput = (id, fetch, setIsValid) => {
        setIsValid(null);
        if (timeoutFlag != null) {
            clearTimeout(timeoutFlag);
        }
        setTimeoutFlag(setTimeout((e) => {
            fetchData(fetch, { id })
        }, 2000))
    }

    useEffect(() => {
        fetchData(fetchFrom)
    }, []);

    useEffect(() => {
        if (fromData) {
            console.log(fromData)
            const id = fromData.id;
            const domain = fromData.domain;

            form.setFieldsValue({
                "from": `${id}    ${domain} `,
            });
            setIsFromValid(true);
        }
    }, [fromData]);

    useEffect(() => {
        if (toData) {
            const id = toData.id;
            const domain = toData.domain;
            form.setFieldsValue({
                "to": `${id}    ${domain} `,
            });
            setIsToValid(true);
        }
    }, [toData]);

    useEffect(() => {
        if (toError) {
            setIsToValid(false);
        }
    }, [toError]);

    useEffect(() => {
        if (fromError) {
            setIsFromValid(false);
        }
    }, [fromError]);

    const [form] = Form.useForm();
    return (
        <StyledContainer>
            <Form
                form={form}
                layout="vertical"
            >
                <StyledFormItem
                    name="from"
                    label={<StyledLabel>From SiteID</StyledLabel>}
                    validateStatus={isFromValid == null ? "" : isFromValid == true ? "success" : "error"}
                    hasFeedback
                    rules={[
                        { required: true, message: "Please enter site id" },
                    ]}
                >
                    <StyledInput
                        autoComplete="off"
                        disabled={isFromDisabled}
                        placeholder="Enter site id"
                        onChange={(e) => {
                            validateInput(e.target.value, fetchFrom, setIsFromValid);
                        }}
                    />
                </StyledFormItem>
                <StyledButton onClick={() => {
                    setIsFromDisabled(false);
                }} icon={<StyledEditOutlined />} />
                <StyledFormItem
                    name="to"
                    label={<StyledLabel>To SiteID</StyledLabel>}
                    validateStatus={isToValid == null ? "" : isToValid == true ? "success" : "error"}
                    hasFeedback
                    rules={[
                        { required: true, message: "Please enter site id" },
                    ]}
                >
                    <StyledInput
                        autoComplete="off"
                        onChange={(e) => {
                            validateInput(e.target.value, fetchTo, setIsToValid);
                        }}
                        placeholder="Enter site id"
                    />
                </StyledFormItem>
                <Form.Item>
                    <Popconfirm
                        disabled={!(isFromValid && isToValid)}
                        title={() =>
                            <>
                                <div>Are you sure you want copy site definitions of</div>
                                <div><Text strong>{fromData.domain} ({fromData.id})</Text> to <Text strong>{toData.domain} ({toData.id})</Text></div>
                            </>
                        }
                        visible={popconfirmVisible}
                        okButtonProps={{ loading: copyLoading }}
                        onConfirm={() => {
                            fetchCopy({ data: { "from_site_id": fromData.id, "to_site_id": toData.id } })
                        }}
                        onCancel={() => { setPopconfirmVisible(false) }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <StyledSaveButton
                            onClick={() => { setPopconfirmVisible(true) }}
                            disabled={!(isFromValid && isToValid)}
                            type="primary">
                            Copy
                            </StyledSaveButton>
                    </Popconfirm>
                </Form.Item>
            </Form>
        </StyledContainer>
    )
}

export default Copy;