import { selectorValidator } from '../Selector/Selector';
import { toastError } from 'Shared/utils';
import { getWidgetsData } from './widgetsService';
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { createPortal } from 'react-dom';

export const submitWidgets = (form, fetchCodefix, patternId, selectedWidget, hash, menuLevel0Form, menuLevel1Form, menuScriptsData, codefixEditData) => {
    let selector = form.getFieldValue("selector");
    if (selectedWidget === undefined) {
        toastError(`role is required`);
        return;
    }

    (async () => {
        if(selectedWidget.key === "menu"){
            const isMenuValid = validateMenu(menuLevel0Form, menuLevel1Form);
            if(!isMenuValid)
             return;
        }

        if (selectedWidget.key === "dataTable"){
            const isDataTableValid = validateDataTable(form);
            if (!isDataTableValid)
                return;
        }
    
        var url = await getCurrentUrl();
        if (url) {
            form.validateFields().then((values) => {
                selectorValidator(selector, patternId).then((result) => {
                    let codefix = {
                        "hash": hash,
                        "url": url,
                        "pattern_id": patternId,
                        "selector": selector,
                        "type": "widget",
                        "name": selectedWidget.value?.toLowerCase(),
                        "data": getWidgetsData(form, selectedWidget, menuLevel0Form, menuLevel1Form, menuScriptsData, codefixEditData),
                        "devices": [],
                        "browsers": [],
                        "urls": []
                    }
                    fetchCodefix({ data: codefix })
                }).catch(errorInfo => {
                    toastError(errorInfo)
                })
            }).catch(err =>  toastError("Not all fields are correct"))
        }
    })();
}

const validateMenu = (menuLevel0Form, menuLevel1Form) => {
        const firstLevelFields = menuLevel0Form.getFieldsValue(null, (f) => f.name[0].includes("selector"));
        const isFirstLevelValid = !Object.keys(firstLevelFields).some(k => firstLevelFields[k].trim() === "");
        
        const secondLevelFields = menuLevel1Form.getFieldsValue(null, (f) => f.name[0].includes("selector"));
        const isSecondLevelValid = !Object.keys(secondLevelFields).some(k => secondLevelFields[k] == undefined || secondLevelFields[k].trim() === "");

        if(!isFirstLevelValid){
            toastError("Not all fields in level 0 are correct");
            return;
        }
        else if(!isSecondLevelValid){
            toastError("Not all fields in level 1 are correct");
            return;
        }

        return true;
}

const validateDataTable = (form) => {
    let rows = (form.getFieldValue("rows") ?? "").split(',');
    let columns = (form.getFieldValue("columns") ?? "").split(',');
    let validRows = rows.every(r => r > 0);
    let validCols = columns.every(c => c > 0);
    if (!validRows)
        validRows = rows[0] === "";
    if (!validCols)
        validCols = columns[0] === "";
    let bothEmpty = (rows[0] === "") && (columns[0] === "");
    return validRows && validCols && !bothEmpty;
}
