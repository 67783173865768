import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {StyledButton} from './Codefix';
import { colors } from "Shared/colors";

const ScriptArea = styled.div`
  position:relative;
  background-color: white;
  overflow: hidden;
  height: 60vh;
  width: 61vw;
  z-index: 1;
  pre {
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 1rem;
  }
`;

const ScriptOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2147483647 !important;
    opacity: 0.5;
    background-color: #111;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.9;
    }

    button:hover {
        background-color: ${colors.BLUE} !important;
    }
`;


const ScriptPreview = ({isEditScript, scriptContent, onScriptClicked}) => {
    const defaultContent = `var init: Init = function(domElement, $, options) {\n    /*!<documentation>\n    { "name": "MP_KN scroll top to main pattern", "description": "scroll top to main pattern"}\n    </documentation>*/\n\nif (options.isKeyboardNav)\n    {\n     $(".elementor-element-289f33f3").focus(function() {\n      window.scrollTo(0,0);\n     });\n}\n}`

    return (
        <>
            <ScriptArea>
                <pre>
                    <code>
                    {unescape(scriptContent) || defaultContent}
                    </code>
                </pre>
                <ScriptOverlay>
                    <StyledButton
                        onClick={onScriptClicked}
                        background={colors.BLUE}
                        type="primary"
                    >
                        {isEditScript ? 'EDIT SCRIPT' : 'CREATE SCRIPT' }
                    </StyledButton>
                </ScriptOverlay>
            </ScriptArea>
        </>
    )
}

export default ScriptPreview;