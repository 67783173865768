import { KEYS, CRATE_PATTERN_KEYS } from "./PatternForm/consts";
import { selectorValidator } from "./PatternForm/Fields/Selector";
import { ensureArray, arrayValuesToLowerCase, toastError } from "Shared/utils";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";

export const submitPattern = (
  event,
  displayForm,
  form,
  fetchFunc,
  displayNameType,
  edit,
  displayData,
  allowGrouping
) => {
  event.preventDefault();
  (async () => {
    var url = await getCurrentUrl();
    if (url) {
      const fields = CRATE_PATTERN_KEYS.filter((k) => k !== KEYS.SELECTOR);
      form
        .validateFields(fields)
        .then((values) => {
          let selector = form.getFieldValue(KEYS.SELECTOR);
          let hierarchy = ensureArray(values[KEYS.HIERARCHY]);
          let supportedDevices = ensureArray(values[KEYS.SUPPORTED_DEVICES]);
          let specialProperties =
            values[KEYS.SPECIAL_PROPERTIES] &&
            ensureArray(values[KEYS.SPECIAL_PROPERTIES]);
          let ignoreInitialEditSelector =
            edit && selector == edit[KEYS.SELECTOR];
          let displayName = displayNameHandler(
            displayForm,
            displayNameType,
            displayData
          );
          let patternId = edit ? edit.id : null;

          selectorValidator(
            selector,
            ignoreInitialEditSelector,
            form,
            null,
            patternId,
            allowGrouping
          )
            .then((result) => {
              let newPattern = {
                [KEYS.ID]: patternId,
                [KEYS.URL]: url,
                [KEYS.PARENT_PATTERN]: result ? result.parentPatterns[0] : null,
                children: result ? result.children : [],
                name: values[KEYS.PATTERN_NAME],
                [KEYS.SELECTOR]: selector,
                [KEYS.PARENT]: values[KEYS.PARENT],
                [KEYS.HIERARCHY]: arrayValuesToLowerCase(hierarchy),
                [KEYS.TYPE]: values[KEYS.TYPE]?.toLowerCase(),
                [KEYS.SPECIAL_PROPERTIES]: specialProperties
                  ? arrayValuesToLowerCase(specialProperties)
                  : [],
                [KEYS.DISPLAY_NAME_SELECTOR]: displayName.displayNameSelector,
                [KEYS.DISPLAY_NAME]: displayName.displayNameLangs,
                [KEYS.SUPPORTED_DEVICES]: arrayValuesToLowerCase(
                  supportedDevices
                ),
                [KEYS.ALLOW_GROUPING]: allowGrouping,
              };
              fetchFunc({ data: newPattern });
            })
            .catch((errorInfo) => {
              toastError(errorInfo);
            });
        })
        .catch((errorInfo) => {
          toastError(
            errorInfo.errorFields &&
              errorInfo.errorFields.map((err) => err.errors)
          );
        });
    }
  })();
};

export const displayNameHandler = (
  displayForm,
  displayNameType,
  displayData
) => {
  let displayNameSelector = null;
  let displayNameLangs = null;

  if (displayNameType === KEYS.DISPLAY_NAME_SELECTOR) {
    displayNameSelector = displayForm.getFieldValue(KEYS.DISPLAY_NAME_SELECTOR);
  } else if (displayNameType === KEYS.LANGUAGES) {
    let values = displayForm.getFieldsValue();
    displayNameLangs = Object.keys(values).filter(
      (f) => f !== KEYS.DISPLAY_NAME_SELECTOR
    );
    displayNameLangs = displayNameLangs.map((langcode) => {
      return {
        lang_code: langcode,
        text: displayForm.getFieldsValue(langcode)[langcode],
        pattern_id: displayData ? displayData.patternId : null,
        text_id: displayData ? displayData.textId : null
      };
    });
  }

  if (displayData && !displayNameSelector && (!displayNameLangs || !displayNameLangs.length))
  {
    displayNameLangs = Object.keys(displayData)
      .filter((dn) => dn !== KEYS.DISPLAY_NAME_SELECTOR && dn !== "patternId" && dn !== "textId")
      .map((dd) => {
        return { lang_code: dd, text: displayData[dd], pattern_id: displayData.patternId, text_id: displayData.textId };
    });

    displayNameSelector = displayData[KEYS.DISPLAY_NAME_SELECTOR]
      ? displayData[KEYS.DISPLAY_NAME_SELECTOR]
      : null;
  }
  return { displayNameSelector, displayNameLangs };
};
