import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "antd";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { StyledContainer } from "../Patterns/CreatePattern";
import { colors } from "Shared/colors";
import { TYPES, KEYS } from "./consts";
import { submitRole } from "./Roles/Roles";
import useAxios from "axios-hooks";
import { useGlobal } from "GlobalContext/GlobalContext";
import Selector, { selectorValidator } from "./Selector/Selector";
import ActionButtons from "./ActionButtons";
import { toastError, toastSuccess } from "Shared/utils";
import SmartAutocomplete from "./SmartAutoComplete/SmartAutocomplete";
import { submitBehavior } from "./Behaviors/Behaviors";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { useCodefix } from "Main/Context/Codefix/CodefixContext";
import { TooltipLabel } from "Shared/Components/TooltipLabel";
import { handleDynamicBehaviors } from "./Behaviors/behaviorService";
import ScriptPreview from "./ScriptPreview";
import { handleDynamicWidgets } from "./Widgets/widgetsService";
import { submitWidgets } from "./Widgets/Widgets";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { useMenuWidget } from "Main/Context/MenuWidget/MenuWidgetContext";

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 9fr;
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  border: none;
  border-radius: 5px;
  background: ${colors.BLUE} !important;
  &:hover {
    background: ${colors.DARK_BLUE} !important;
  }
  &:focus {
    background: ${colors.DARK_BLUE} !important;
  }
`;

const StyledLinkButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  color: ${colors.MID_GREY};
  &:hover {
    color: ${colors.DARK100};
  }
  &:focus {
    color: ${colors.DARK100};
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-rows: calc(89vh - 4rem) 2.2rem;
  width: 100%;
  height: 100%;
`;

const StyledInnerContainer = styled.div`
  flex: 1;
`;

const StyledDynamicScrollableContainer = styled.div`
  padding-right: 5px;
  max-height: ${(props) => (props.isCreateNew ? "350px" : "400px")};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #5977ad !important;
    outline: 1px solid slategrey;
    border-radius: 20px;
  }
`;

const PatternNameTitle = styled.span`
  position: absolute;
  top: 50px;
  right: 20px;
  font-weight: 500;
`;

const Codefix = ({
  setSideBarCollaped,
  setCollapseColor,
  patternId,
  codefixHash,
  codefixEditData,
  fetchUpdateCodefix,
  updatedCodefixData,
  isEditScript,
  fetchUpdateSelector,
  sendSocketMessage,
  isMobile
}) => {
  const [form] = Form.useForm();
  const { constants } = useGlobal();
  const {
    clearAllStates,
    selectorErrors,
    setSelectorErros,
    setSelector,
    selectedOption,
    setSelectedOption,
    setScriptSelector,
    selector,
    description,
    setPatternSelectorSelected,
    menuScriptModal,
    setMenuScriptModal,
    setPatternId
  } = useCodefix();

  const { updatePatternTree, treeDictionary, setHighlightedItem, selectedItem } = useTree();
  const [currentOptionsList, setOptionsList] = useState([]);
  const [patternName, setPatternName] = useState("");
  const [parentPatternId, setParentPatternId] = useState(null);
  const isBodyPattern =
    treeDictionary &&
    treeDictionary[patternId] &&
    treeDictionary[patternId].selector === "{BODY}";
  const [activeCodefixType, setActiveCodefixType] = useState(
    isBodyPattern ? TYPES.BEHAVIORS : TYPES.ROLES
  );
  const [menuLevel0Form] = Form.useForm();
  const [menuLevel1Form] = Form.useForm();
  const { menuScriptsData } = useMenuWidget();

  setCollapseColor(colors.DARK90);

  useEffect(() => {
    if (patternId) {
      setPatternId(patternId);
    }
  }, [patternId]);

  const [
    { data: codefixData, loading: loading, error: codefixError },
    fetchCodefix,
  ] = useAxios(
    {
      method: "POST",
      url: `/codefixes`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if(isMobile){
        setSideBarCollaped(true);
    }
  } , [])

  useEffect(() => {
    if(selectedItem){
      setPatternName(selectedItem.name);
    }
  } , [selectedItem])

  useEffect(() => {
    if (codefixEditData) {
      form.setFieldsValue({
        [KEYS.SELECTOR]: codefixEditData.selector,
      });
      if (codefixEditData.selector === ".") {
        setPatternSelectorSelected(true);
      }

      let pluralType = codefixEditData.type + "s";
      if (isEditScript) {
        setActiveCodefixType(TYPES.SCRIPT);
        setScriptSelector(codefixEditData.selector);
      } else {
        onTypeClicked(codefixEditData.type, constants[pluralType]);
        setSelector(codefixEditData.selector);
      }
      setPatternName(codefixEditData.pattern_name);
    }
  }, [codefixEditData, isEditScript]);

  useEffect(() => {
    return () => {
      clearAllStates();
    };
  }, []);

  useEffect(() => {
    if (patternId) {
      setParentPatternId(patternId);
    }
  }, [patternId]);

  useEffect(() => {
    if (codefixData || updatedCodefixData) {
      onCreateSuccess(codefixData || updatedCodefixData);
    }
  }, [codefixData, updatedCodefixData]);

  useEffect(() => {
    if (constants) {
      !codefixEditData && setOptionsList(constants[activeCodefixType + "s"]);
    }
  }, [constants, codefixEditData]);

  useEffect(() => {
    if (codefixError) {
      const errors =
        codefixError.response &&
        codefixError.response.data &&
        codefixError.response.data.Errors;
      toastError(`Error while trying to create codefix. Msg: ${errors}`);
    }
  }, [codefixError]);

  const onTypeClicked = (type, options) => {
    setActiveCodefixType(type);
    setOptionsList(options);
  };

  const onScriptClicked = () => {
    setSideBarCollaped(true);
    setScriptSelector(selector);
    if (isEditScript) {
      navigate(`/main/${patternId}/codefix/scripts/${isEditScript.hash}`);
    } else {
      navigate(`/main/${patternId}/codefix/scripts/new`);
    }
  };

  const onCancel = () => {
    clearAllStates();
    navigate(`/main/edit-pattern/${patternId}`);
  };

  const onCreateSuccess = (codefixData) => {
    updatePatternTree();
    form.resetFields();
    navigate(`/main/${patternId}/codefix`);
    toastSuccess(
      codefixEditData
        ? "Codefix updated successfully"
        : "Codefix created successfully"
    );
    selectedItem && setHighlightedItem({key: patternId, name: selectedItem.name, mark: false});
    setPatternSelectorSelected(false);
    sendSocketMessage({action: "create", type: "codefix", id: patternId, hash: codefixData.hash, name: activeCodefixType}); 
    clearAllStates();
  };

  const onSubmitCodefix = (e) => {
    e.preventDefault();
    const fetchMethod = fetchUpdateCodefix ? fetchUpdateCodefix : fetchCodefix;

    switch (activeCodefixType) {
      case TYPES.ROLES:
        submitRole(form, fetchMethod, patternId, codefixHash, setSelectorErros);
        break;
      case TYPES.BEHAVIORS:
        submitBehavior(
          form,
          fetchMethod,
          patternId,
          codefixHash,
          selectedOption,
          description
        );
        break;
      case TYPES.SCRIPT:
        selectorValidator(selector, patternId)
          .then((result) => {
            (async () => {
              var url = await getCurrentUrl();
              if (url) {
                fetchUpdateSelector({
                  data: {
                    url: url,
                    hash: codefixHash.split("-")[1],
                    pattern_id: codefixEditData.pattern_id,
                    selector: selector,
                    name: codefixEditData.name,
                  },
                })
                  .then(() => {
                    toastSuccess(`script selector updated successfully`);
                  })
                  .catch((error) => {
                    toastError(error.response.data.Errors);
                  });
              }
            })();
          })
          .catch((errorInfo) => {
            toastError(errorInfo);
          });
        break;
      case TYPES.WIDGETS:
        submitWidgets(
          form,
          fetchMethod,
          patternId,
          selectedOption,
          codefixHash,
          menuLevel0Form,
          menuLevel1Form,
          menuScriptsData,
          codefixEditData
        );
        break;
      default:
        break;
    }
  };

  return (
    <StyledContainer id="codefix-container">
      <PatternNameTitle>{patternName}</PatternNameTitle>
      <StyledForm labelCol={{ span: 24 }} form={form} name="codefix">
        <StyledInnerContainer id="formScrollableSection">
          <TooltipLabel
            required
            value="SELECTOR"
            tooltip={{ text: "fill later" }}
          />
          <Selector patternId={parentPatternId} form={form} />
          <ActionButtons
            codefixEditData={codefixEditData}
            selectorErrors={selectorErrors}
            isBodyPattern={isBodyPattern}
            form={form}
            activeCodefixType={activeCodefixType}
            onTypeClicked={onTypeClicked}
          />
          {activeCodefixType !== TYPES.SCRIPT ? (
            <>
              <SmartAutocomplete
                form={form}
                codefixEditData={codefixEditData}
                isBodyPattern={isBodyPattern}
                patternId={patternId}
                setSelectedOption={setSelectedOption}
                activeCodefixType={activeCodefixType}
                currentOptionsList={
                  isBodyPattern
                    ? currentOptionsList.filter(
                        (op) => op.key === "stateChange"
                      )
                    : currentOptionsList
                }
              />
              {activeCodefixType === TYPES.BEHAVIORS &&
                handleDynamicBehaviors(
                  form,
                  selectedOption,
                  codefixEditData,
                  patternId
                )}
              {activeCodefixType === TYPES.WIDGETS && (
                <StyledDynamicScrollableContainer
                  isCreateNew={!(codefixHash && codefixHash.length)}
                >
                  {handleDynamicWidgets(
                    form,
                    selectedOption,
                    codefixEditData,
                    patternId,
                    menuLevel0Form,
                    menuLevel1Form,
                    setPatternSelectorSelected
                  )}
                </StyledDynamicScrollableContainer>
              )}
            </>
          ) : (
            <ScriptPreview
              isEditScript={isEditScript}
              scriptContent={
                isEditScript && codefixEditData && codefixEditData.content
              }
              onScriptClicked={onScriptClicked}
            />
          )}
        </StyledInnerContainer>
        <div>
          <StyledButtonsContainer>
            <StyledButton
              onClick={onSubmitCodefix}
              background={colors.BLUE}
              type="primary"
            >
              SAVE
            </StyledButton>
            <StyledLinkButton onClick={onCancel} type="link">
              CANCEL
            </StyledLinkButton>
          </StyledButtonsContainer>
        </div>
      </StyledForm>
    </StyledContainer>
  );
};

export default Codefix;
