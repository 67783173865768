import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, InputNumber, Select, Checkbox } from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import styled from "styled-components";
import { LeftCircleOutlined, RightCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { colors } from "Shared/colors";
import SelectorWidget from "../Utils/SelectorWidget"

const Container = styled.div` 
display: ${props => props.visible};
`

const StyledInputNumber = styled(InputNumber)` 
width: 100%;
`
const StyledCheckboxSpan = styled.span`
font-size: 0.7rem;
font-weight: 700;
`

const FormValidation = ({ form, codefixEditData, patternId }) => {

    const [errorMsgSelector, setErrorMsgSelector] = useState({
        required: false,
        label: "error message",
        name: "errorMsgSelector",
        tooltip: "later",
        selectorErrors: null,
        visible: true,
        selectorType: "relative"
    });
    const [invalidFieldSelector, setInvalidFieldSelector] = useState({
        required: false,
        label: "invalid field",
        name: "invalidFieldSelector",
        tooltip: "later",
        selectorErrors: null,
        visible: true,
        selectorType: "relative"
    });
    const [requiredFieldsSelector, setRequiredFieldsSelector] = useState({
        required: false,
        label: "required fields",
        name: "requiredFieldsSelector",
        tooltip: "later",
        selectorErrors: null,
        visible: true,
        selectorType: "relative"
    });
    const [submitBtnSelector, setSubmitBtnSelector] = useState({
        required: false,
        label: "submit button",
        name: "submitBtnSelector",
        tooltip: "later",
        selectorErrors: null,
        visible: true,
        selectorType: "relative"
    });

    const waitUntil = {
        required: false,
        label: "timeout",
        name: "waitUntil",
        tooltip: "later",
        visible: true,
    };

    const doNotMoveFocus = {
        required: false,
        label: "disable focus on profiles",
        name: "doNotMoveFocus",
        tooltip: "later",
        visible: true,
        values: [
            "colors",
            "help",
            "keyboard",
            "fontSize",
            "screenReader",
            "blockBlinking",
        ]
    };
    const doNotChangeErrorsStyles = {
        label: "do not change error styles",
        name: "doNotChangeErrorsStyles",
        tooltip: "later",
        visible: true,
    }

    const transferKeysToNames = {
        colors: "Color Contrast",
        help: "Help Layer",
        keyboard: "Keyboard",
        fontSize: "Magnify",
        screenReader: "Screen Reader",
        blockBlinking: "Stop Moving",
    }

    useEffect(() => {
        if (codefixEditData) {
            let doNotMoveFocus = codefixEditData.data.doNotMoveFocus;
            if (doNotMoveFocus && doNotMoveFocus.length) {
                doNotMoveFocus = doNotMoveFocus.split(',');
            }
            form.setFieldsValue({
                errorMsgSelector: codefixEditData.data.errorMsgSelector ?? null,
                invalidFieldSelector: codefixEditData.data.invalidFieldSelector ?? null,
                requiredFieldsSelector: codefixEditData.data.requiredFieldsSelector ?? null,
                waitUntil: codefixEditData.data.waitUntil ? parseInt(codefixEditData.data.waitUntil) : null,
                submitBtnSelector: codefixEditData.data.submitBtnSelector ?? null,
                doNotMoveFocus: doNotMoveFocus ?? [],
                doNotChangeErrorsStyles: codefixEditData.data.doNotChangeErrorsStyles ?? null,
            });
        }
    }, [codefixEditData])

    return (
        <div id="formValidation">
            <Row>
                <Col span={16}>
                    <SelectorWidget
                        setState={setErrorMsgSelector}
                        state={errorMsgSelector}
                        patternId={patternId} />
                </Col>
                <Col span={1}></Col>
                <Col span={7}>
                    <Container>
                        <TooltipLabel required={waitUntil.required} value={waitUntil.label?.toUpperCase()} tooltip={{ text: waitUntil.tooltip }} />
                        <Form.Item
                            name={waitUntil.name}
                            rules={[{ type: 'number', min: 0, max: 99999 }]}
                        >
                            <StyledInputNumber type="number" placeholder="2500 MS" />
                        </Form.Item>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <SelectorWidget
                        setState={setInvalidFieldSelector}
                        state={invalidFieldSelector}
                        patternId={patternId} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <SelectorWidget
                        setState={setRequiredFieldsSelector}
                        state={requiredFieldsSelector}
                        patternId={patternId} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <SelectorWidget
                        setState={setSubmitBtnSelector}
                        state={submitBtnSelector}
                        patternId={patternId} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Container>
                        <TooltipLabel value={doNotMoveFocus.label?.toUpperCase()} tooltip={{ text: doNotMoveFocus.tooltip }} />
                        <Form.Item name={doNotMoveFocus.name}>
                            <Select
                                mode="multiple"
                                showArrow={true}
                                placeholder="CHOOSE ONE OR MORE PROFILE(S)"
                            >
                                {
                                    doNotMoveFocus.values.map((val, i) => <Select.Option name={val} key={val}>{transferKeysToNames[val]}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name={doNotChangeErrorsStyles.name} valuePropName="checked">
                        <Checkbox>
                            <StyledCheckboxSpan>{doNotChangeErrorsStyles.label?.toUpperCase()}</StyledCheckboxSpan> <TooltipLabel tooltip={{ text: doNotChangeErrorsStyles.tooltip }} />
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export const getFormValidationData = (form) => {
    let value = form.getFieldsValue([
        "errorMsgSelector",
        "invalidFieldSelector",
        "requiredFieldsSelector",
        "waitUntil",
        "submitBtnSelector",
        "doNotMoveFocus",
        "doNotChangeErrorsStyles"
    ]);
    console.log(value)
    return value;
}



export default FormValidation;