import React, { useState, useEffect } from 'react';
import { Typography, Switch, Form, Input } from 'antd';
import styled from 'styled-components';
import { sendMessage, recieveMessage } from 'Shared/messages';
import { KEYS } from '../consts';
import { useCodefix } from 'Main/Context/Codefix/CodefixContext'
import { colors } from 'Shared/colors';

const { Text } = Typography;

const StyledRow = styled.div`
    display: grid;
    grid-template-columns: calc(100% - 7rem) 6rem;
    .ant-switch-checked {
        background-color: ${colors.BLUE};
    }
`;

const StyledSwitch = styled(Switch)`
    margin-left: 1rem;
    height: 2rem;
    width: 6rem;
    .ant-switch-handle{
        top: 7px !important;
    }
`;


export const preValidation = (selector, reject) => {
    if (selector === undefined || !selector.trim().length) {
        return reject(['Selector required']);
    }

    if(selector.includes('"')){
        return reject(["Double quotes are not allowed in selectors, please use single quotes only."]);
    }
}

export const absoluteSelectorValidator = (selector, isDotSelectorNotAllowed, allowMultiple) => {
    return new Promise((resolve, reject) => {

        preValidation(selector, reject);

        if(isDotSelectorNotAllowed && selector.trim() === "."){
            return reject(['Selector must be a child of the pattern']);
        }

        if (selector.trim() === ".") {
            return resolve();
        }

        sendMessage({ type: "validate_selector", selector, allowMultiple });
        recieveMessage("validate_selector", (data) => {
            data = data.result;
            let isExist = data.isExist;
            if (isExist) {
                return resolve(data);
            }

            if (data.errors) {
                return reject(data.errors);
            }

            return reject(["Error occur during selector validation"]);
        });
    })
}

export const multipleSelectorValidator = (selector, parentId, name) => {
    return new Promise((resolve, reject) => {
        
        preValidation(selector, reject);

        if (selector.trim() === ".") {
            return resolve();
        }

        sendMessage({ type: `validate_relative_selector_${name}`, selector, parentId });
        recieveMessage(`validate_relative_selector_${name}`, (data) => {
            data = data.result;
            let isExist = data.isExist;
            if (isExist) {
                return resolve(data);
            }

            if (data.errors) {
                return reject(data.errors);
            }

            return reject(["Error occur during selector validation"]);
        });
    })
}

export const selectorValidator = (selector, parentId, isDescription, isDotSelectorNotAllowed, isRelativeToSelector) => {
    return new Promise((resolve, reject) => {

        preValidation(selector, reject);
    
        if(isDotSelectorNotAllowed && selector.trim() === "."){
            return reject(['Selector must be a child of the pattern']);
        }

        if (!isDescription && selector.trim() === ".") {
            return resolve();
        }

        sendMessage({ type: "validate_relative_selector", selector, parentId, isDescription, isRelativeToSelector });
        recieveMessage("validate_relative_selector", (data) => {
            data = data.result;
            let isExist = data.isExist;
            if (isExist) {
                return resolve(data);
            }

            if (data.errors) {
                return reject(data.errors);
            }

            return reject(["Error occur during selector validation"]);
        });
    })
}

const Selector = ({ form, patternId }) => {
    const { setSelector, selectorErrors, setSelectorErros, patternSelectorSelected, setPatternSelectorSelected } = useCodefix();

    useEffect(() => {
        if (patternSelectorSelected) {
            form.setFieldsValue({ [KEYS.SELECTOR]: "." });
            setSelector(".");
            setSelectorErros([]);
        }
    }, [patternSelectorSelected])

    return (
        <StyledRow>
            <div>
                <Form.Item
                    style={{ marginBottom: selectorErrors && selectorErrors.length ? 0 : 20 }}
                    name={KEYS.SELECTOR}
                    hasFeedback
                    validateTrigger={['onBlur']}
                    validateStatus={selectorErrors === null ? null : selectorErrors.length ? "error" : "success"}
                    rules={[
                        {
                            validator: async (rule, selector) => {
                                try {
                                    setSelector(selector);
                                    selectorValidator(selector, patternId).then((result) => {
                                        setSelectorErros([]);
                                        return Promise.resolve();
                                    }).catch((result) => {
                                        setSelectorErros(result);
                                        return Promise.reject(result);
                                    });
                                }
                                catch (err) {
                                    return Promise.reject(err.response && err.response.data && err.response.data.Errors || err);
                                }
                            }
                        }]}
                >
                    <Input disabled={patternSelectorSelected} id="codefix-selector" placeholder="SELECTOR" />
                </Form.Item>
                {selectorErrors && selectorErrors.length ? <Text type="danger">{selectorErrors[0]}</Text> : null}
            </div>
            <div>
                <StyledSwitch checked={patternSelectorSelected} checkedChildren="PATTERN" unCheckedChildren="PATTERN" onChange={(checked) => setPatternSelectorSelected(checked)} />
            </div>
        </StyledRow>
    )
}

export default Selector;