import React from 'react';
import DataTable from './DataTable';
import { getDataTableData } from './DataTable';
import DatePicker, { getDatePickerData } from './DatePicker/DatePicker';
import FormValidation, { getFormValidationData } from './FormValidation/FormValidation';
import MenuWidget, { getMenuData } from './MenuWidget/MenuWidget';
import {MenuWidgetProvider} from '../../../Context/MenuWidget/MenuWidgetContext';

export const handleDynamicWidgets = (form, selectedOption, codefixEditData, patternId, menuLevel0Form, menuLevel1Form, setPatternSelectorSelected) => {
  if (selectedOption) {
    switch (selectedOption.key) {
      case "dataTable":
        return <DataTable form={form} codefixEditData={codefixEditData} />
      case "datePicker":
        return <DatePicker form={form} codefixEditData={codefixEditData} patternId={patternId} />
      case "formValidation":
        return <FormValidation form={form} codefixEditData={codefixEditData} patternId={patternId} />
      case "menu":
        return (
            <MenuWidget form={form} codefixEditData={codefixEditData} patternId={patternId} menuLevel0Form={menuLevel0Form} menuLevel1Form={menuLevel1Form} setPatternSelectorSelected={setPatternSelectorSelected}/> 
        )
      default:
        break;
    }
  }
  return false;
};

export const getWidgetsData = (form, selectedWidget, menuLevel0Form, menuLevel1Form, menuScriptsData, codefixEditData) => {
  return widgetTypes[selectedWidget.key].getData(form, selectedWidget, menuLevel0Form, menuLevel1Form, menuScriptsData, codefixEditData);
}

const widgetTypes = {
  dataTable: {
    getData: (form) => getDataTableData(form)
  },
  datePicker: {
    getData: (form) => getDatePickerData(form)
  },
  formValidation: {
    getData: (form) => {
      const formValidationData = getFormValidationData(form);
      if (!(formValidationData.errorMsgSelector && formValidationData.errorMsgSelector.trim().length) &&
        !(formValidationData.invalidFieldSelector && formValidationData.invalidFieldSelector.trim().length) &&
        !(formValidationData.requiredFieldsSelector && formValidationData.requiredFieldsSelector.trim().length)
      ) {
        throw new Error("Please provide at least 1 selector for 'Error Message', 'Invalid Field' or 'Required Fields'");
      }
      if (formValidationData.doNotMoveFocus && formValidationData.doNotMoveFocus.length) {
        formValidationData.doNotMoveFocus = formValidationData.doNotMoveFocus.join(',');
      }
      return formValidationData;
    }
  },
  menu: {
    getData: (form, selectedWidget, menuLevel0Form, menuLevel1Form, menuScriptsData, codefixEditData) => getMenuData(form, menuLevel0Form, menuLevel1Form, menuScriptsData,codefixEditData)
  }
}