import React, { useContext, createContext } from "react";
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";

const SiteScriptsContext = createContext();

function SiteScriptsProvider({ children }) {
    const [{ data: siteScriptsData, loading: siteScriptsLoading, error: siteScriptsError }, fetchSiteScripts] = useAxios(
        {
            method: "POST",
            url: `/siteScripts/get-all-scripts`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const updateSiteScripts = () => {
        (async () => {
            const url = await getCurrentUrl();
            fetchSiteScripts({
                data: {
                    url: url
                }
            })
        })();
    }

    return (
        <SiteScriptsContext.Provider
            value={{ siteScriptsData, updateSiteScripts }}
        >
            {children}
        </SiteScriptsContext.Provider>
    );
}

function useSiteScripts() {
    const context = useContext(SiteScriptsContext);
    if (context === undefined) {
        throw new Error(`useSiteScripts must be used within a SiteScriptsContext`);
    }
    return context;
}

export { SiteScriptsProvider, useSiteScripts };
