import React, {useState, useEffect} from 'react';
import {Select, Typography, Tag} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import {tooltipsContent} from '../data';
import {FormItem} from '../PatternForm';
import { KEYS, OPTIONS } from '../consts';
import { SITE_SETTINGS_CONSTS } from 'GlobalContext/GlobalContext';
import {isMainOrSiteNavLandmark} from './Type';
import { ensureArray} from 'Shared/utils';
import {isLandmark}  from './Type';

const { Option } = Select;

const hierarchyRuleOnSelected = (selectedValues, options, setTypeDisabled, currentValue) => {
    let rootValue = options.filter((item) => item.value == OPTIONS.HIERARCHY_ROOT)[0];
    let defaultValue = options.filter((item) => item.value == OPTIONS.HIERARCHY_DEFAULT)[0];

    if(selectedValues.includes(OPTIONS.HIERARCHY_DEFAULT)){
        delete defaultValue.disabled;
        rootValue.disabled = "true";
    }
    else if(selectedValues.includes(OPTIONS.HIERARCHY_ROOT)){
        delete rootValue.disabled;
        defaultValue.disabled = "true";
    }
    
    if(selectedValues.includes(OPTIONS.NO_CHILDREN)){
        setTypeDisabled(true);
    }
}

export const Hierarchy = ({form, disabled, setTypeDisabled, constants, isLandmarkType, landmarks, edit, allowGrouping}) => {
    const [error, setErrors] = useState(null);
    const [options, setOptions] = useState([]);
    const [isAutoNoChildren, setAutoNoChildren] = useState(false);
    const { Text } = Typography;

    useEffect(() => {
        if(isLandmarkType && isMainOrSiteNavLandmark(isLandmarkType.selectedOption)){
            let values = ensureArray(form.getFieldValue(KEYS.HIERARCHY));
            values = values && values.filter((pr) => pr !== OPTIONS.NO_CHILDREN);
            form.setFieldsValue({[KEYS.HIERARCHY]: values.concat(OPTIONS.NO_CHILDREN)});
            options.filter((item) => item.value == OPTIONS.NO_CHILDREN)[0].disabled = "true";
            setAutoNoChildren(true);
        }
        else if(isLandmarkType && !isLandmark(landmarks, isLandmarkType.selectedOption) && !edit){
            let values = ensureArray(form.getFieldValue(KEYS.HIERARCHY));
            values = values && values.filter((pr) => pr !== OPTIONS.NO_CHILDREN);
            form.setFieldsValue({[KEYS.HIERARCHY]: values});
            setAutoNoChildren(false);
        }
        else {
            setOptions(options.map((option) => (option.value === OPTIONS.NO_CHILDREN ? {value: option.value} : option)));
            setAutoNoChildren(false);
        }
    }, [isLandmarkType])

    useEffect(() => {
        if(disabled && !edit){
            form.setFieldsValue({ [KEYS.HIERARCHY]: OPTIONS.HIERARCHY_DEFAULT });
        }
        if(edit){
            form.setFieldsValue({ [KEYS.HIERARCHY]: edit.hierarchy });
        }
    }, [disabled, edit])

    useEffect(() => {
        if(constants){
            let data = constants[SITE_SETTINGS_CONSTS.HIERARCHIES].map((option) => { 
                return option.value === OPTIONS.HIERARCHY_ROOT ? { value: option.value, disabled: true} : {value: option.value} 
            })
            if(edit){
                hierarchyRuleOnSelected(edit.hierarchy, data, setTypeDisabled);
            }
            setOptions(data);
        }
    }, [constants, edit])

    const hierarchyRuleOnDeselect = (removedValue, options) => {
        let values = form.getFieldValue(KEYS.HIERARCHY);
        options.forEach(option => option.disabled = false);
        let rootValue = options.filter((item) => item.value == OPTIONS.HIERARCHY_ROOT)[0];
        let defaultValue = options.filter((item) => item.value == OPTIONS.HIERARCHY_DEFAULT)[0];
        
        if(removedValue === OPTIONS.NO_CHILDREN && values.includes(OPTIONS.HIERARCHY_DEFAULT)){
            delete defaultValue.disabled;
            rootValue.disabled = "true";
        }
        else if(removedValue === OPTIONS.NO_CHILDREN && values.includes(OPTIONS.HIERARCHY_ROOT)){
            delete rootValue.disabled;
            defaultValue.disabled = "true";
        }

        if(!values.includes(OPTIONS.NO_CHILDREN)){
            setTypeDisabled(false);
        }
    }
    
    const onBlurValidator = (values) => {
        if(!values || !values.length){
            return "Hierarchy is required"
        }
        else if(values.length === 1 && values[0] === OPTIONS.NO_CHILDREN){
             return "no children must combined with 'root' or 'default'";
        }
        else {
            return;
        }
    }

    const isTagEnabled = (value) => {
        return !disabled && (!isAutoNoChildren || isAutoNoChildren && value !== "NO CHILDREN");
    }

    const isHierarcyDisable = () => {
        return disabled || (edit && edit.group) || allowGrouping; 
    }

    return (
        <div>
            <TooltipLabel required value="HIERARCHY" tooltip={{ text: tooltipsContent.hierarchy }} />
            <FormItem 
                validateTrigger={['onBlur']} 
                style={{marginBottom: error ? 0 : 10}}
                name={KEYS.HIERARCHY}
                rules={[
                    {validator: async (rule, value) => {
                        let result = onBlurValidator(value);
                        if(result){
                            return Promise.reject(result)
                        }
                        return Promise.resolve();
                }}]} 
            >
                <Select
                    disabled={isHierarcyDisable()}
                    onSelect={(currentValue) => hierarchyRuleOnSelected(form.getFieldValue(KEYS.HIERARCHY), options, setTypeDisabled, currentValue)}
                    onDeselect={(removedValue) => hierarchyRuleOnDeselect(removedValue, options)}
                    mode="multiple"
                    tagRender={(props) =>
                        <Tag
                            closable={isTagEnabled(props.value) && !isHierarcyDisable()}
                            onClose={() => {
                                props.onClose();
                                onBlurValidator();
                            }}
                        >
                            {props.value?.toUpperCase()}
                        </Tag>
                    }
                    style={{ width: '100%' }}
                    options={options}
                />
            </FormItem>
            {error && <Text type="danger">{error}</Text>}
        </div>
    )
}
