import React, { useContext, createContext, useState, useEffect } from 'react'

const MenuWidgetContext = createContext();

function MenuWidgetProvider({ children }) {
  const [menuScriptsData, setScripts] = useState(null);
  const [editScriptData, setEditScriptData] = useState([]);
  const [editMenuSelectorArr, setEditMenuSelectorArr] = useState([]);
  const [selectedScriptId, setSelectedScriptId] = useState(null);

  const clearAllStates = () => {
    setScripts(null);
    setSelectedScriptId(null);
    setEditScriptData([]);
    setEditMenuSelectorArr([]);
  }

  return (
    <MenuWidgetContext.Provider 
      value={{ 
        menuScriptsData, 
        setScripts,
        selectedScriptId, 
        setSelectedScriptId,
        editScriptData, 
        setEditScriptData,
        clearAllStates,
        editMenuSelectorArr, 
        setEditMenuSelectorArr
        }}>
        {children}
    </MenuWidgetContext.Provider>
  )
}

function useMenuWidget() {
  const context = useContext(MenuWidgetContext)
  if (context === undefined) {
    throw new Error(`useMenuWidget must be used within a MenuWidgetContext`)
  }
  return context
}

export { MenuWidgetProvider, useMenuWidget }
