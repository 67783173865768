import React from 'react';
import { Modal, List, Spin, Pagination, Avatar } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { apiClient } from 'Shared/apiClient';
import Moment from 'react-moment';
import 'moment-timezone';

const randomColor = require('randomcolor'); // import the script

const NotificationRow = styled.div`
    font-size: 12px;
`

const Notification = styled(Modal)`
    top: 40px;
    .ant-list-items{
        height: 75vh;
        overflow: auto;
        padding-right: 10px;
    }
    h4{
        font-size: 12px;
        font-weight: bold;
    }

    .ant-modal-content{
        border-radius: 10px;
    }
`
const Loader = styled(Spin)`
   display:flex;
   justify-content: center;
`

const paginationCount = 20;

const getInitials = (name) => {
    name = name.split(" ");
    return name[0][0] + name[1][0];
}

const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
}

class NotificationModal extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            url: this.props.url,
            notificationData: [],
            userColor: {},
            loading: false,
            isListenerAttached: false
        }
    }
    
    componentDidMount(){
        (async () => {
            await this.getNotifications({ url: this.state.url, start_index: 0 });
        })();
    }

    async getNotifications(body){
        const {notificationData, isListenerAttached} = this.state;
        this.setState({loading: true});
        let notifications = await apiClient(`/notifications`, { body });
        if (notifications && !!notifications.data && notifications.data.length) {
            notifications = notifications.data;
            if (notifications.length !== notificationData.length || notificationData.length < paginationCount) {
                this.updateUserColor(notifications);
                const data = notificationData.concat(notifications);
                this.setState({notificationData: data}, () => {
                    if (!isListenerAttached) {
                        this.setState({isListenerAttached: true});
                        this.addScrollListener();
                    }
                });
            }
        }
        this.setState({loading: false});
    }

    updateUserColor(notifications){
        const {userColor} = this.state;
        const userNames = notifications.map(n => n.user_name);
        userNames.filter(distinct).forEach(name => {
            if(!userColor[name]){
                const color = randomColor({luminosity: 'dark'});
                const result = userColor;
                result[name] = color;
                this.setState({userColor: result});
            }
        })
    }

    addScrollListener(){
        const el = document.querySelector(".notification-modal .ant-list-items");
        el && el.addEventListener("scroll", async (e) => await this.handleInfiniteOnLoad(e, this.state.notificationData), false);
    }

    onCancel(){
        this.props.setNotificationVisible(false);
    }

    async handleInfiniteOnLoad(evt, data){
        const element = evt.target;
        if (element.scrollTop + element.clientHeight == element.scrollHeight) {
            await this.getNotifications({ url: this.state.url, start_index: data.length});
        }
    }

    resetNewBadge(){
        const {isNotificationVisible, newMessages, setNewMessages} = this.props;
        if(newMessages && !!newMessages.length && isNotificationVisible){
            setNewMessages([]);
        }
    }

    render(){       
        this.resetNewBadge(); 
        return (
        <>
            <Notification
                visible={this.props.isNotificationVisible}
                footer={null}
                onCancel={this.onCancel.bind(this)}
                destroyOnClose={true}
                wrapClassName="notification-modal"
            >
                <div>
                    <h1>Notifications</h1>
                    <List
                            loading={this.state.loading}
                            itemLayout="horizontal"
                            dataSource={this.state.notificationData}
                            renderItem={notification => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                        <Avatar 
                                        size="large" 
                                        style={{
                                            letterSpacing: '2px',
                                            backgroundColor: this.state.userColor[notification.user_name]
                                        }}>
                                            {getInitials(notification.user_name?.toUpperCase())}
                                        </Avatar>
                                        }
                                        title={`${notification.content} 
                                                ${(notification.entity === "codefix" ||  notification.entity === "script") 
                                                ? `on pattern '${notification.pattern_name}'` : ""
                                                }
                                            `
                                        }
                                        description={
                                            <NotificationRow>
                                                <span style={{marginRight: 5}}>
                                                    by {notification.user_name} 
                                                </span>
                                                <span>
                                                    <Moment utc local fromNow>{notification.date}</Moment>
                                                </span>
                                            </NotificationRow>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                </div>
            </Notification>
        </>
        )
    }
}

export default NotificationModal;