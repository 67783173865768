export const IMAGE_STATUS = {
    IGNORE: "IGNORE",
    MISSING_ALT: "MISSING ALT",
    INITIAL_ALT: "INITIAL ALT",
    OCR: "OCR",
    MANUAL: "MANUAL"
}

export const GUID = {
    EMPTY: '00000000-0000-0000-0000-000000000000'
}

export const IDS = {
    IMG_URL: "urs_image_url_search_input"
}