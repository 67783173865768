import React, { useState, useEffect } from 'react'
import { Layout } from 'antd';
import Sidebar from './Sidebar/Sidebar';
import { LoadingOutlined } from "@ant-design/icons";
import { TreeProvider } from '../Context/TreeContext/TreeContext';
import { CodefixProvider } from '../Context/Codefix/CodefixContext'
import { SiteScriptsProvider } from "../Context/SiteScripts/SiteScriptsContext"
import { Router } from "@reach/router";
import CreatePattern from './Patterns/CreatePattern';
import AppHeader from './Header';
import Codefix from './Codefix/Codefix';
import ImagesContainer from './Images/ImagesContainer'
import ExecuteScript from './ExecuteScript/ExecuteScript';
import LanguageScript from './ExecuteScript/LanguageScript/LanguageScript';
import EditPattern from './Patterns/EditPattern';
import EditCodefix from './Codefix/editCodefix';
import { colors } from 'Shared/colors';
import DefaultRoute from 'Main/Views/DefaultRoute';
import NotificationModal from 'Main/Views/NotificationModal/NotificationModal';
import { useGlobal } from 'GlobalContext/GlobalContext';
import { useNotification } from '../Context/Notification/NotificationContext';
import ChangeDetectionModal from 'Main/Views/NotificationModal/ChangeDetectionModal/ChangeDetectionModal';
import { useMediaQuery } from 'react-responsive';
import { TheSpin } from "./Sidebar/History/HistoryList"

const { Content } = Layout;

// create the connection instance

const Main = () => {
    const [sideBarCollaped, setSideBarCollaped] = useState(false);
    const [collapseColor, setCollapseColor] = useState(colors.DARK90);
    const [isNotificationVisible, setNotificationVisible] = useState(false);
    const { url, publishLoading } = useGlobal();
    const { newMessages, sendSocketMessage, changeDetectionModal, setChangeDetectionModal, setNewMessages } = useNotification();

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)'
    });

    useEffect(() => {
        if (isMobile) {
            setSideBarCollaped(true);
        }
    }, [])

    return (
        <Layout>
            <SiteScriptsProvider>
                <AppHeader isMobile={isMobile} setNotificationVisible={setNotificationVisible} setSideBarCollaped={setSideBarCollaped} />
                {publishLoading && <TheSpin tip="Publishing" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                }
                {!publishLoading &&
                    <Layout>
                        {changeDetectionModal && <ChangeDetectionModal setChangeDetectionModal={setChangeDetectionModal} changeDetectionModal={changeDetectionModal} />}
                        {isNotificationVisible && <NotificationModal setNewMessages={setNewMessages} newMessages={newMessages} url={url} isNotificationVisible={isNotificationVisible} setNotificationVisible={setNotificationVisible} />}
                        <Sidebar isMobile={isMobile} sideBarCollaped={sideBarCollaped} setSideBarCollaped={setSideBarCollaped} collapseColor={collapseColor} setCollapseColor={setCollapseColor} />
                        <CodefixProvider isMobile={isMobile} setSideBarCollaped={setSideBarCollaped}>
                            <Content>
                                <Router>
                                    <DefaultRoute isMobile={isMobile} path="/main/default" />
                                    <ImagesContainer setCollapseColor={setCollapseColor} sideBarCollaped={sideBarCollaped} setSideBarCollaped={setSideBarCollaped} path="images" />
                                    <LanguageScript setSideBarCollaped={setSideBarCollaped} sideBarCollaped={sideBarCollaped} setCollapseColor={setCollapseColor} path="languageScript" />
                                    <ExecuteScript sendSocketMessage={sendSocketMessage} setSideBarCollaped={setSideBarCollaped} sideBarCollaped={sideBarCollaped} setCollapseColor={setCollapseColor} path=":scriptType/:hash" />
                                    <ExecuteScript sendSocketMessage={sendSocketMessage} setSideBarCollaped={setSideBarCollaped} sideBarCollaped={sideBarCollaped} setCollapseColor={setCollapseColor} path="main/:patternId/codefix/:scriptType/:hash" />
                                    <ExecuteScript sendSocketMessage={sendSocketMessage} setSideBarCollaped={setSideBarCollaped} sideBarCollaped={sideBarCollaped} setCollapseColor={setCollapseColor} path="main/:patternId/codefix/menu/:scriptType/:hash" />
                                    <Codefix isMobile={isMobile} sendSocketMessage={sendSocketMessage} setSideBarCollaped={setSideBarCollaped} setCollapseColor={setCollapseColor} path="main/:patternId/codefix/*" />
                                    <EditCodefix isMobile={isMobile} sendSocketMessage={sendSocketMessage} setSideBarCollaped={setSideBarCollaped} setCollapseColor={setCollapseColor} path="main/:patternId/edit-codefix/:hash/*" />
                                    <CreatePattern path="/main/patterns" isMobile={isMobile} setSideBarCollaped={setSideBarCollaped} sendSocketMessage={sendSocketMessage} setCollapseColor={setCollapseColor} />
                                    <EditPattern path="/main/edit-pattern/:patternId" isMobile={isMobile} setSideBarCollaped={setSideBarCollaped} sendSocketMessage={sendSocketMessage} setCollapseColor={setCollapseColor} />
                                </Router>
                            </Content>
                        </CodefixProvider>
                    </Layout>
                }
            </SiteScriptsProvider>
        </Layout>
    )
}

export default Main;