import React, { useEffect } from 'react';
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { StyledModal } from 'Scripter/Shared/ModalStyles';
import { useVisibilityContext } from 'Scripter/Context/ElementVisibilityContext';

const PublishModal = () => {

    const { setPublishVisible, isPublishModalVisible, setPublishTooltipVisibility } = useVisibilityContext();

    const [{ data: publishData, loading: publishLoading, error: publishError },
        publishScripts,] = useAxios(
            {
                method: "POST",
                url: `/scripter/publish`,
                headers: {
                    "content-type": "application/json",
                },
            },
            { manual: true }
        );

    const publish = () => {
        (async () => {
            var url = await getCurrentUrl();
            if (url) {

                publishScripts({
                    data: { url: url },
                });

            }
        })();

    };

    useEffect(() => {
        if (publishData == 'success') {
            setPublishVisible(false);
            setPublishTooltipVisibility(true);
            setTimeout(() => {
                setPublishTooltipVisibility(false);
            }, 2000);
        }
    }, [publishData]);


    return (
        <StyledModal
            mask="false"
            title={`Are you sure you want to publish?`}
            visible={isPublishModalVisible}
            onOk={publish}
            onCancel={() => setPublishVisible(false)}
            okText="Publish"
            cancelText="CANCEL"
            publishModal="true"
        >
        </StyledModal>
    )

};

export default PublishModal;