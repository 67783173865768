import React, { useState, useEffect } from "react";
import { Layout, Button, Spin } from "antd";
import MonacoEditor from "react-monaco-editor";
import useAxios from "axios-hooks";
import { colors } from "Shared/colors";
import ExecuteScriptFooter from "./ExecuteScriptFooter";
import ExecuteScriptHeader from "./ExecuteScriptHeader";
import styled from "styled-components";
import { useCodefix } from "Main/Context/Codefix/CodefixContext";
import { defaultEditorValue, monacoEditorOptions } from "./data";
import { getCurrentUrl } from 'Shared/Services/getCurrentUrl';
import { toastError } from "Shared/utils";


// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};
const { Content } = Layout;

const StyledLayout = styled(Layout)`
  display: flex;
  flex-flow: column;
  height: ${props => props.isMenuScript ? '90vh' : 'calc(100vh - 40px)'};
`;
const StyledContent = styled(Content)`
  padding: ${props => props.isMenuScript ? 0 : '1rem 1rem 0 1rem'};
  .margin {
    background-color: #f0f2f5 !important;
  }
`;

export const StyledLoadingContainer = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vh;
`;

export const StyledSpinner = styled(Spin)`
  position: relative;
  left: 50%;
  top: 50%;
`;

export const StyledSaveButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  border: none;
  border-radius: 5px;
  background: ${colors.BLUE} !important;
  &:hover {
    background: ${colors.DARK_BLUE} !important;
  }
  &:focus {
    background: ${colors.DARK_BLUE} !important;
  }
`;
export const StyledDeleteButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  border: none;
  border-radius: 5px;
  margin-left: 0.5rem;
  color:white;
  background: ${colors.RED} !important;
  &:hover {
    color:white;
    background: ${colors.DARK_RED} !important;
  }
  &:focus {
    color:white;
    background: ${colors.DARK_RED} !important;
  }
`;

export const StyledEditButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  border: none;
  border-radius: 5px;
  margin-left: 0.5rem;
  background: ${colors.DARK70} !important;
  &:hover {
    background: ${colors.DARK100} !important;
  }
  &:focus {
    background: ${colors.DARK100} !important;
  }
`;

export const StyledCancelButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  color: ${colors.MID_GREY};
  &:hover {
    color: ${colors.DARK100};
  }
  &:focus {
    color: ${colors.DARK100};
  }
`;

const ExecuteScript = ({
  sideBarCollaped,
  setSideBarCollaped,
  setCollapseColor,
  patternId,
  scriptType,
  hash,
  setMenuScriptEditorValue,
  menuScriptsData,
  sendSocketMessage
}) => {
  const isMenuScript = scriptType === "menuScript";
  !isMenuScript && setCollapseColor("white");

  const { scriptSelector, setScriptSelector } = useCodefix();
  const [runTimes, setRunTimes] = useState(null);
  const [runtimeID, setRuntimeID] = useState(null);
  const [runtime, setRuntime] = useState(null);
  const [apiInterface, setApiInterface] = useState(null);
  const [snippets, setSnippets] = useState(null);
  const [library, setLibrary] = useState(null);
  const [editorValue, setEditorValue] = useState(null);
  const [editor, setEditor] = useState(null);
  const [monaco, setMonaco] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [urlPattern, setUrlPattern] = useState(null);

  //get scripts data from server
  const [{ data: apiData, loading: apiDataLoading, error: apiDataError  }] = useAxios({
    method: "GET",
    url: `/scripts/github`,
    headers: {
      "content-type": "application/json",
    },
  });

  const [{ data: getScriptData, loading: getScriptLoading, error: getScriptError }, getScript,] = useAxios(
    {
      method: "POST",
      url: `/${scriptType}/get-script`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (apiDataError && apiDataError.response && apiDataError.response.data && apiDataError.response.data.Errors) {
      toastError(
        apiDataError.response.data.Errors.map((err) => err)
      );
    }
  }, [apiDataError]);


  useEffect(() => {
    if (isMenuScript) {
      if(menuScriptsData){
        initializeNewScriptData.content = unescape(menuScriptsData);
      }
      initializePageFromUserScript(initializeNewScriptData);
    }

    return () => {
      scriptType === "scripts" && setScriptSelector(null);
    }
  }, []);

  useEffect(() => {
    if (editorValue) {
      setMenuScriptEditorValue && setMenuScriptEditorValue(editorValue);
    }
  }, [editorValue])

  useEffect(() => {
    getScriptData && initializePageFromUserScript(getScriptData);
  }, [getScriptData]);

  useEffect(() => {
    getScriptError && initializePageFromUserScript(initializeNewScriptData);
  }, [getScriptError]);


  useEffect(() => {
    if (runTimes && runtimeID) {
      handleRuntimeChange(runtimeID);
    }
  }, [runTimes, runtimeID]);

  //editor layout fix
  useEffect(() => {
    setTimeout(() => {
      editor && editor.layout();
    }, 500);
  }, [sideBarCollaped, editor]);
  //window auto fix layout on resize
  window.onresize = () => {
    editor && editor.layout();
  };

  //on script data recieve from server build runtimes,snippets and library
  useEffect(() => {
    if (apiData) {
      const parsedSnippet = JSON.parse(apiData.snippet);
      if (scriptType === "scripts") {
        setRunTimes(parsedSnippet.runtimes.categories);
      } else {
        setRunTimes(parsedSnippet.siteScriptsRuntimes.categories);
      }
      setSnippets({
        apis: { categories: parsedSnippet.apis.categories.concat(parsedSnippet.conditions.categories) },
      });
      setLibrary(apiData.scripts);
      if (hash !== "new") {
        (async () => {
          const url = await getCurrentUrl();
          if (url) {
            if (scriptType === "scripts") {
              getScript({ data: { url: url, pattern_id: patternId, hash: hash } })
            }
            else {
              !isMenuScript && getScript({ data: { url: url, name: hash } })
            }
          }
        })();
      } else {
        initializePageFromUserScript(initializeNewScriptData);
      }
    }
  }, [apiData, hash]);

  //build interface - run after snippet built or after runtime change
  useEffect(() => {
    if (snippets && runTimes && runtime) {
      const optionsInterface = {
        interfaces: [],
        items: [],
      };
      Object.entries(snippets).forEach(([key, value]) => {
        key !== "runtimes" &&
          value.categories.forEach((category) => {
            category.items &&
              category.items.forEach((item) => {
                if (
                  item.runtime.indexOf("0") !== -1 ||
                  item.runtime.indexOf(`${runtime.id}`) !== -1
                ) {
                  const splittedContent = item.content.split(".");
                  const name = splittedContent[splittedContent.length - 1];
                  const params = (item.params && item.params.join(",")) || "";
                  const splittedName = name.split("");
                  splittedName.splice(splittedName.length - 1, 0, params);
                  const nameWithParams = splittedName.join("");
                  const rowToInsert = [
                    "/**",
                    item.description,
                    "*/",
                    `${nameWithParams}:${item.return};`,
                  ].join("\n");
                  createInterfaceObject(
                    optionsInterface,
                    splittedContent,
                    rowToInsert
                  );
                }
              });
          });
      });
      const interfaceArr = [];
      if (isMenuScript) {
        optionsInterface["api"] = optionsInterface.options.api;
      }

      buildInterfaceFromObject(optionsInterface, interfaceArr);
      const api = [
        "type u1stJQuery = (jQuerySelector:string , context? : any) => jQueryElement",
        "const uf$ : u1stJQuery",
        "const $ : JQuery",
        "const u1stLogger: Iu1stLogger",
        "type Init = (domElement:Element, $, options:Ioptions ) => any;",
        interfaceArr.join("\n"),
      ];
      scriptType === "siteScripts" && api.push("const options:Ioptions;")
      setApiInterface(api.join("\n"));
    }
  }, [snippets, runtime]);

  //add options.api interface to monaco intellisense
  useEffect(() => {
    monaco &&
      apiInterface &&
      monaco.languages.typescript.typescriptDefaults.setExtraLibs([
        { content: apiInterface },
      ]);
  }, [monaco, apiInterface]);

  const initializePageFromUserScript = (data) => {
    let content = unescape(data.content);
    if (scriptType === "scripts" || isMenuScript) {
      const functionFirstPos = content.indexOf("function");
      content = content.substr(functionFirstPos);
      content = "var init: Init = " + unescape(content);
      setScriptSelector(data.selector);
    } else {
      content = content;
      setUrlPattern(data.url_pattern);
    }
    setEditorValue(content);
    setName(data.name);
    setDescription(data.description);
    setRuntimeID(`${data.runtime}`);
  };

  const initializeNewScriptData = {
    content: defaultEditorValue[scriptType],
    description: "",
    name: "",
    runtime: scriptType === "scripts" ? 1024 : 1,
    selector: scriptSelector,
    urlPattern: urlPattern
  };

  //creates our IntelliSense from interface object
  const buildInterfaceFromObject = (object, interfaceArr) => {
    const names = [];
    object.interfaces.forEach((name) => {
      names.push(name);
      const innerInterfaces = object[name].interfaces.map(
        (innerName) => `${innerName}:I${innerName};`
      );
      interfaceArr.push(
        [`interface I${name}{`, innerInterfaces.join("\n")].join("\n")
      );
      const items = object[name].items.join("\n");
      interfaceArr.push(items);
      interfaceArr.push("}");
    });
    names.forEach((name) => {
      buildInterfaceFromObject(object[name], interfaceArr);
    });
  };

  //dynamically create interface object from given array
  const createInterfaceObject = (thisObj, arr, rowToInsert) => {
    if (arr.length === 1) {
      thisObj.items.push(rowToInsert);
    } else {
      const name = arr[0];
      if (!thisObj[name]) {
        thisObj[name] = {
          interfaces: [],
          items: [],
        };
        thisObj.interfaces.push(name);
      }
      arr.shift();
      createInterfaceObject(thisObj[name], arr, rowToInsert);
    }
  };

  const editorDidMount = (_editor, _monaco) => {
    setEditor(_editor);
    setMonaco(_monaco);
  };

  const handleRuntimeChange = (value) => {
    if (runTimes) {
      const category = runTimes.find((category) =>
        category.items.find((item) => item.id === value)
      );
      const runtimeVal = category && category.items.find((item) => item.id === value);
      setRuntime(runtimeVal);
    }
  };

  const insertSnippet = (
    editorValue,
    editor,
    setEditorValue,
    content,
    defaultValues = null
  ) => {
    let apiToInsert = content;
    const splittedRows = editorValue.split("\n");
    const selection = editor.getSelection();
    const startLine = selection.startLineNumber - 1;
    const endLine = selection.endLineNumber + 1;
    const startColumn = selection.startColumn - 1;
    const endColumn = selection.endColumn - 1;
    if (defaultValues) {
      const splittedContent = content.split("");
      splittedContent.splice(splittedContent.length - 1, 0, defaultValues);
      apiToInsert = splittedContent.join("");
    }
    if (startColumn !== endColumn) {
      splittedRows.insert(startLine, ` if(${apiToInsert}){ `);
      splittedRows.insert(endLine, "}");
    } else {
      const splittedColumn = splittedRows[startLine].split("");
      splittedColumn.insert(startColumn, ` ${apiToInsert} `);
      splittedRows[startLine] = splittedColumn.join("");
    }
    setEditorValue(splittedRows.join("\n"));
    editor.getAction("editor.action.formatDocument").run();
  };

  return (
    <StyledLayout isMenuScript={isMenuScript} className="executeScript">
      <ExecuteScriptHeader
        isMenuScript={isMenuScript}
        scriptType={scriptType}
        library={library}
        snippets={snippets}
        insertSnippet={insertSnippet}
        editorValue={editorValue}
        editor={editor}
        setEditorValue={setEditorValue}
        runTimes={runTimes}
        runtime={runtime}
        handleRuntimeChange={handleRuntimeChange}
      />
      <StyledContent isMenuScript={isMenuScript}>
        <MonacoEditor
          language="typescript"
          value={editorValue}
          options={monacoEditorOptions}
          onChange={setEditorValue}
          theme={"vs"}
          editorDidMount={editorDidMount}
          automaticLayout={true}
        />
      </StyledContent>
      {!isMenuScript && <ExecuteScriptFooter
        urlPattern={urlPattern}
        setUrlPattern={setUrlPattern}
        scriptType={scriptType}
        editorValue={editorValue}
        selector={scriptSelector}
        patternId={patternId}
        runtime={runtime}
        hash={hash}
        setSelector={setScriptSelector}
        title={hash === "new" ? "Save Script" : "Edit Script"}
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        name={name}
        setName={setName}
        description={description}
        setSideBarCollaped={setSideBarCollaped}
        setDescription={setDescription}
        sendSocketMessage={sendSocketMessage}
      />
      }
    </StyledLayout>
  );
};

export default ExecuteScript;
