import React, {useState, useEffect} from 'react'
import {Form, Input, Typography} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import {selectorValidator, absoluteSelectorValidator} from '../Selector/Selector';
import {resetFields} from 'Shared/formService';

const { Text } = Typography;

/*behaviors with type=selector in codefix.yaml file*/ 
const SelectorBehaviors = ({form, codefixEditData, selectedOption, patternId, label, isDescription, setBindToData}) => {
    const [selectorErrors, setErrors] = useState(null);

    useEffect(() => {
        if(selectedOption){
            resetFields(form, ["selectorBehavior"])
        }
    }, [selectedOption])

    useEffect(() => {
        if(codefixEditData){
            let selectorData = codefixEditData.data.selector;
            form.setFieldsValue({
                selectorBehavior: selectorData
            });
        }
    }, [codefixEditData])

    return (
        <>
            <TooltipLabel required value={label ? label : `SELECTOR ${selectedOption.name?.toUpperCase()}`} tooltip={{ text: "fill later" }} />
            <Form.Item 
                style={{ marginBottom: selectorErrors && selectorErrors.length ? 0 : 20 }}
                name="selectorBehavior"
                hasFeedback
                validateTrigger={['onBlur']}
                validateStatus={selectorErrors === null ? null : selectorErrors.length ? "error" : "success"}
                rules={[
                    {
                        validator: async (rule, selector) => {
                            try {
                                if(isDescription){
                                    selectorValidator(selector, patternId, isDescription).then((result) => {
                                        if(result.attributes){
                                            setBindToData(result.attributes);
                                        }
                                        setErrors([]);
                                        return Promise.resolve();
                                    }).catch((result) => {
                                        setErrors(result);
                                        return Promise.reject(result);
                                    });
                                }
                                else {
                                    absoluteSelectorValidator(selector).then((result) => {
                                        setErrors([]);
                                        return Promise.resolve();
                                    }).catch((result) => {
                                        setErrors(result);
                                        return Promise.reject(result);
                                    });
                                }
                            }
                            catch (err) {
                                return Promise.reject(err.response && err.response.data && err.response.data.Errors || err);
                            }
                        }
                }]}
            >
                <Input placeholder="FILL THE SELECTOR" />
            </Form.Item>
            {selectorErrors && selectorErrors.length ? <Text type="danger">{selectorErrors[0]}</Text> : null}
        </>
    )
}

export const getSelectorData = (form) => {
    let value = form.getFieldValue("selectorBehavior");
    return {selector: value};
}

export default SelectorBehaviors;