import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import Codefix from "./Codefix";
import { useCodefix } from "Main/Context/Codefix/CodefixContext";
import { Loading } from "App";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { toastError } from "Shared/utils";

const EditCodefix = ({
  patternId,
  hash,
  setSideBarCollaped,
  setCollapseColor,
  sendSocketMessage,
  isMobile,
  ...props
}) => {
  const { updatePatternTree } = useTree();
  const { clearAllStates, setPatternId } = useCodefix();
  const [scriptHash, setScriptHash] = useState(null);
  const [
    { data: codefixData, loading: loading, error: codefixError },
    fetchGetCodefix,
  ] = useAxios(
    {
      method: "POST",
      url: `/codefixes/get-codefix`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  
  useEffect(() => {
    if (patternId) {
      setPatternId(patternId);
    }
  }, [patternId]);

  const [
    {
      data: updateSelectorData,
      loading: updateSelectorLoading,
      error: updateSelectorError,
    },
    fetchUpdateSelector,
  ] = useAxios(
    {
      method: "PUT",
      url: `/scripts/edit-selector/`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );
  const [
    {
      data: updatedCodefixData,
      loading: updateLoading,
      error: updateCodefixError,
    },
    fetchUpdateCodefix,
  ] = useAxios(
    {
      method: "PUT",
      url: `/codefixes`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [{ data: scriptData, loading: scriptLoading, error: scriptError }, fetchGetScript] = useAxios(
    {
      method: "POST",
      url: `/scripts/get-script`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const handleHashType = () => {
    if (hash.startsWith("script-")) {
      setScriptHash(hash.replace("script-", ""));
    } else {
      (async () => {
        var url = await getCurrentUrl();
        if (url) {
          fetchGetCodefix({
            data: { url: url, pattern_id: patternId, hash: hash },
          });
        }
      })();
    }
  };

  useEffect(() => {
    if (updateSelectorData)
      updatePatternTree();
  }, [updateSelectorData]);


  useEffect(() => {
    handleHashType();
    return () => {
      clearAllStates();
      setScriptHash(null);
    };
  }, []);

  useEffect(() => {
    if (hash) {
      clearAllStates();
      setScriptHash(null);
      handleHashType();
    }
    if(isMobile){
      setSideBarCollaped(true);
    }
  }, [hash, patternId]);

  useEffect(() => {
    if (scriptError) {
      toastError("Unable create or edit scripts, issue will be resolved shortly. Please try again later");
    }
  }, [scriptError]);

  useEffect(() => {
    if (scriptHash) {
      (async () => {
        var url = await getCurrentUrl();
        if (url) {
          fetchGetScript({
            data: { url: url, pattern_id: patternId, hash: scriptHash },
          })
        }
      })();
    }
  }, [scriptHash]);

  const editData = (scriptHash && scriptData) || codefixData;
  return editData ? (
    <Codefix
      fetchUpdateSelector={fetchUpdateSelector}
      isEditScript={scriptHash && scriptData}
      setCollapseColor={setCollapseColor}
      updatedCodefixData={updatedCodefixData}
      fetchUpdateCodefix={fetchUpdateCodefix}
      patternId={patternId}
      codefixEditData={editData}
      codefixHash={hash}
      setSideBarCollaped={setSideBarCollaped}
      sendSocketMessage={sendSocketMessage}
    />
  ) : (
      <Loading />
    );
};

export default EditCodefix;
