import React, {useState, useEffect} from 'react';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import { Select, Tabs, Tag, Typography } from 'antd';
import {FormItem} from '../../PatternForm';
import {tooltipsContent} from '../../data';
import { KEYS } from '../../consts';
import { useGlobal } from 'GlobalContext/GlobalContext';
import DisplayNameForm from './DisplayNameForm';

export const DisplayName = ({disabled, displayForm, form, edit, editRaw, setDisplayNameType, setSubmitDisabled}) => {

    const { siteSettings } = useGlobal();
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        isOpen ? setSubmitDisabled(true) : setSubmitDisabled(false);
    }, [isOpen])

    return (
        <div>
            <TooltipLabel required={!disabled} value="DISPLAY NAME" tooltip={{ text: tooltipsContent.displayName }} />
            <FormItem 
                validateTrigger={['onBlur']} 
                rules={!disabled && !isOpen && [{ required: true, message: 'Display name required' }]} 
                name={KEYS.DISPLAY_NAME}>
                <Select
                    showSearch={false}
                    onFocus={() => setOpen(true)}
                    mode="multiple" 
                    open={isOpen}
                    tagRender={(props) => 
                    <Tag closable={false}>
                        {props.value}
                    </Tag>}
                    placeholder="DISPLAY NAME" 
                    disabled={disabled} 
                    notFoundContent={() => null} 
                    dropdownRender={() => 
                        <DisplayNameForm setDisplayNameType={setDisplayNameType} editRaw={editRaw} setOpen={setOpen} form={form} displayForm={displayForm} siteSettings={siteSettings} />
                    } 
                />
            </FormItem>
        </div>
    )
}
