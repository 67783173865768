import { IMAGE_STATUS } from './consts';

const GroupBy = (object, key) => {
    return object.reduce((newObject, val) => {
        (newObject[val[key]] = newObject[val[key]] || []).push(val);
        return newObject;
    }, {});
};

const TagsFilter = (images, tags) => {
    let newImages = [...images];
    const isIgnoreTagAppear = tags.includes(IMAGE_STATUS.IGNORE);
    if (isIgnoreTagAppear) {
        newImages = newImages.filter(i => i.ignore == true);
    }
    const filteredTags = tags.filter(t => t != IMAGE_STATUS.IGNORE);
    if (filteredTags.length) {
        newImages = newImages.filter(i => tags.includes(i.text.status));
    }
    return newImages;
}

const ImageUrlFilter = (images, url) => {
    if (url != "") {
        return images
            .filter(i => i.image_url == url)
            .map(i => {
                i.collection = [i];
                return i;
            });
    }
    return images;
}

const Group = (images) => {
    const groupByImageUrl = Object.values(GroupBy(images, "image_url"))
        .map((imageCollection) => {
            var image = imageCollection[0];
            image["collection"] = imageCollection;
            return image;
        });
    return groupByImageUrl;
}

export const Filter = (images = [], tags = [], url = "") => {
    let newImages = TagsFilter(images, tags);
    newImages = ImageUrlFilter(newImages, url);
    if (url == "") {
        newImages = Group(newImages);
    }
    return newImages;
}