import { Tree, Input } from "antd";
import styled from "styled-components";
import { colors } from "Shared/colors";
const { Search } = Input;

export const StyledTree = styled(Tree)`
  color: white;
  background: ${(props) => props.backgroundColor};
  width: 100%;
  height: calc(100vh - 13.5rem);
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 20px;
  }

  .ant-tree-treenode {
    width: 100%;
  }

  .ant-tree-node-content-wrapper:hover {
    background: none !important;
  }

  .ant-tree-node-selected {
    background: none !important;
  }

  .ant-tree-node-content-wrapper {
    width: 100%;
  }

  .site-tree-search-value {
    color: #f50;
  }

  .ant-tree-switcher {
    height: 30px;
    line-height: 30px;
    width: 20px;
  }

  .ant-tree-node-content-wrapper {
    padding: 0;
  }
`;

export const StyledSearch = styled(Search)`
  margin: 0.5rem 0;
  input[placeholder]::placeholder,
  input[placeholder]::-webkit-input-placeholder {
    color: gray;
    font-size: 12px;
  }
  button {
    background: ${colors.BLUE};
    color: white!important;
    border:0;
    border-radius:0;
    padding: 0;
    color: grey;
    &:hover {
      background: ${colors.DARK_BLUE} !important;
      color: white;
    }
    &:focus {
      background: ${colors.DARK_BLUE} !important;
      color: white;
    }
  }
  .ant-input-search-button {
    height: 40px;
    width: 40px;
  }
  input {
    height: 40px;
    border-radius: 0;
    font-weight: 600;
  }
  .ant-input-search {
    margin: 0;
    border: 0;
    border-radius: 0;
  }
  .ant-input-search-enter-button {
    padding: 0 0.5rem;
  }
  .ant-input-group-addon {
    border: 0;
    border-radius: 0;
    height: 40px;
    padding: 0;
    margin: 0;
    background: ${colors.BLUE};
    &:hover {
      background: ${colors.DARK_BLUE} !important;
    }
    &:focus {
      background: ${colors.DARK_BLUE} !important;
    }
  }
`;