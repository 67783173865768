export const TYPES = {
    ROLES: "role",
    BEHAVIORS: "behavior",
    WIDGETS: "widget",
    SCRIPT: "script"
}

export const KEYS = {
    SELECTOR: "selector",
    FIXES: "fixes"
}

export const BEHAVIORS_CATEGORIES = {
    ARIA_PROPETRY: "ARIA property",
    ARIA_STATE: "ARIA state",
    UREMEDIATE_HANDLERS: "uRemediate handlers"
}