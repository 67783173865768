import {selectorValidator} from '../Selector/Selector';
import { toastError } from 'Shared/utils';
import {getBehaviorData} from './behaviorService';
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";

export const submitBehavior = (form, fetchCodefix, patternId, hash, selectedBehavior, description) => {
    let selector = form.getFieldValue("selector");
    if(selectedBehavior === undefined){
        toastError(`behavior is required`);
        return;
    }

    (async () => {
        var url = await getCurrentUrl();
        if (url) {
            form.validateFields().then((values) => {
                selectorValidator(selector, patternId).then((result) => {
                    let codefix = {
                        "url": url,
                        "hash": hash,
                        "pattern_id": patternId,
                        "selector": selector,
                        "type": "behavior",
                        "name": selectedBehavior.name || selectedBehavior.fullData.name?.toLowerCase(),
                        "data": getBehaviorData(form, selectedBehavior, description),
                        "devices": [],
                        "browsers": [],
                        "urls": []
                    }
                    fetchCodefix({data: codefix})
                }).catch(errorInfo => {
                    toastError(errorInfo)
                })
            })
        }
      })();
}
