import React, { useContext, createContext, useState, useEffect } from "react";
import { createDictionary, getChidrensIdsByPatternId } from "./TreeDictionary";
import { toastError } from "Shared/utils";
import useAxios from "axios-hooks";
import { sendMessage, recieveMessage } from "Shared/messages";
import { useGlobal } from "GlobalContext/GlobalContext";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import {keyStrokesService} from '../../Views/keyStrokes';

const TreeContext = createContext();

function TreeProvider({ children }) {
  const { siteSettings } = useGlobal();
  const [treeDictionary, setTreeDictionary] = useState([]);
  const [siteID, setSiteID] = useState(null);
  const [highlightedItem, setHighlightedItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(["notOnPage", "broken"]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [query, setQuery] = useState(sessionStorage.getItem('query') ?? '');

  const [
    { data: tree, loading: TreeLoading, error: TreeErrors },
    fetchTree,
  ] = useAxios(
    {
      method: "POST",
      url: `/tree/get-tree`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const updatePatternTree = (searchValue = null) => {
    sessionStorage.setItem('query', searchValue ?? (query ?? ''));
    (async () => {
      var url = await getCurrentUrl();
      if (url) {
        fetchTree({ data: { url, query: searchValue ?? query } }).catch((ex) => {
          console.log(ex.message);
        });
      }
    })();
  };

  useEffect(() => {
    recieveMessage("refresh", () => updatePatternTree(sessionStorage.getItem('query') ?? ''), false);
    keyStrokesService.refresh(() => updatePatternTree(sessionStorage.getItem('query') ?? ''));
  }, []);

  useEffect(() => {
    if (siteSettings) {
      setSiteID(siteSettings.site_id);
    }
  }, [siteSettings]);

  useEffect(() => {
    if (tree && Array.isArray(tree)) {
      let data = createDictionary(tree);
      const uniqueKey = Date.now();
      sendMessage({ type: `patterns_elements_count_${uniqueKey}`, patterns: data }, "*");
      recieveMessage(
        `patterns_elements_count_${uniqueKey}`,
        (data) => {
          if (data.type === `patterns_elements_count_${uniqueKey}`) {
            setTreeDictionary(data.patterns ?? {});
          }
        },
        false
      );
      recieveMessage(
        "codefix_err",
        (errors) => {
          if (errors.type === "codefix_err") {
            toastError(`Error while parsing codefix. Msg: ${errors}`);
          }
        },
        false
      );
    }
  }, [tree]);

  useEffect(() => {
    if (treeDictionary && highlightedItem) {
      setTimeout(() => {
        document.querySelectorAll(".tree-pattern-name span").forEach(el => {
          const parent = el.closest(".tree-pattern-name");
          parent.classList.remove("site-tree-search-value");
          if (el.innerText === highlightedItem.name) {
            el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            parent.classList.add("site-tree-search-value");
          }
        });
        setHighlightedItem(null);
      }, 2000)
    }
  }, [treeDictionary]);

  useEffect(() => {
    if (siteID) {
      updatePatternTree();
    }
  }, [siteID]);

  const getChildrenById = (patternId) => {
    return getChidrensIdsByPatternId(tree, patternId);
  }

  return (
    <TreeContext.Provider
      value={{ expandedKeys, setExpandedKeys, selectedFilterOptions, setSelectedFilterOptions, treeDictionary, TreeLoading, TreeErrors, updatePatternTree, tree, setQuery, query, highlightedItem, setHighlightedItem, selectedItem, setSelectedItem, getChildrenById, siteID }}>
      {children}
    </TreeContext.Provider>
  );
}

function useTree() {
  const context = useContext(TreeContext);
  if (context === undefined) {
    throw new Error(`useTree must be used within a TreeContext`);
  }
  return context;
}

export { TreeProvider, useTree };
