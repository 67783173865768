import React from 'react';
import { Button } from 'antd';
import { navigate } from "@reach/router";
import { colors } from 'Shared/colors';
import styled from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import {keyStrokesService} from 'Main/Views/keyStrokes';

const StyledAddPatternButton = styled(Button)`
  margin-top: 1.9rem;
  padding: 0;
  width: 100%;
  background: ${colors.BLUE};
  color: white;
  border: none;
  border-radius: 0;
  height: 3rem;
  font-weight: bold;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${colors.DARK_BLUE} !important;
    color: white;
  }
  &:focus {
    background: ${colors.DARK_BLUE} !important;
    color: white;
  }
`

const Container = styled.div`
 display: flex;
 align-items: flex-end;
`


const AddPatternButton = () => {

    const onAddNewPattern = () => {
        navigate("/main/patterns");
    }

    keyStrokesService.addPattern(() => {
      navigate("/main/patterns");
    });

    return (
        <Container>
            <StyledAddPatternButton id="addPatternBtn" onClick={onAddNewPattern}>
                <PlusCircleOutlined style={{fontWeight: 700}}/>
                <span>ADD NEW PATTERN</span>
            </StyledAddPatternButton>
        </Container>
    )
}

export default AddPatternButton;