import { OPTIONS,KEYS } from './consts';

const initialValues = {
    [KEYS.PARENT]: 0,
    [KEYS.HIERARCHY]: OPTIONS.HIERARCHY_DEFAULT,
    [KEYS.TYPE]: OPTIONS.SECTION,
    [KEYS.SUPPORTED_DEVICES]: OPTIONS.ALL_DEVICES
}

const tooltipsContent = {
    patternName: "test test test",
    selector: "test test test",
    parent: "test test test",
    type: "test test test",
    specialProperties: "test test test",
    displayName: "test test test",
    supportedDevices: "test test test",
    hierarchy: "test test test"
}

export { 
    tooltipsContent,
    initialValues
};