import { navigate } from "@reach/router";
import useAxios from "axios-hooks";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Menu, Popconfirm } from "antd";
import { colors } from "Shared/colors";
import { apiClient } from "Shared/apiClient";
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { toastSuccess, toastError } from "Shared/utils";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { useNotification } from "Main/Context/Notification/NotificationContext";
import { sendMessage } from "Shared/messages";

const ContextMenu = styled(Menu)`
  background-color: ${colors.DARK100};
  -webkit-box-shadow: 0px 0px 58px -30px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 58px -30px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 58px -30px rgba(255, 255, 255, 1);
  border-radius: 5px;
  button span {
    font-size: 0.7rem;
  }
  button {
    color: white;
  }
  button:hover {
    color: white;
  }
  li {
    padding: 0;
    margin: 0;
  }
  li:hover {
    background-color: ${colors.BLUE};
  }
`;

const StyledDivider = styled(Menu.Divider)`
  background-color: ${colors.DARK80};
  margin: 0.3rem !important;
`;

const visiblityTypeDictionary = {
  pattern: "pattern",
  script: "script",
  group: "group",
  behavior: "codefix",
  role: "codefix",
  widget: "codefix"
}

const TreeMenu = ({ type, dataType, patternId, name, selector, url, isGroup, data, setSelectedItem }) => {
  const { updatePatternTree } = useTree();
  const { changeDetectionModal, setChangeDetectionModal, sendSocketMessage } = useNotification();
  const hideShowText = data.visible ? "HIDE" : "SHOW";
  const [
    {
      data: deletePatternData,
      loading: deletePatternLoading,
      error: deletePatternError,
    },
    deletePatternImpl,
  ] = useAxios(
    {
      method: "DELETE",
      url: `/patterns/`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [
    {
      data: ChangeVisibilityData,
      loading: ChangeVisibilityLoading,
      error: ChangeVisibilityError,
    },
    ChangeVisibilityFetch,
  ] = useAxios(
    {
      method: "POST",
      url: `/tree/change-visibility/${visiblityTypeDictionary[type]}`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const deletePattern = () => {
    (async () => {
      var url = await getCurrentUrl();
      if (url) {
        deletePatternImpl({
          data: {
            id: patternId,
            url: url,
          },
        });
      }
    })();
  };

  useEffect(() => {
    if (deletePatternData) {
      toastSuccess(`Pattern '${name}' was deleted.`);
      sendSocketMessage({ action: "delete", type: "pattern", id: patternId, name: name });
      updatePatternTree();
    }
  }, [deletePatternData]);

  useEffect(() => {
    if (deletePatternError) {
      const errors =
        deletePatternError.response &&
        deletePatternError.response.data &&
        deletePatternError.response.data.Errors;
      toastError(`Cloud not delete pattern. ${errors}`);
    }
  }, [deletePatternError]);

  const [
    {
      data: deleteCodefixData,
      loading: deleteCodefixLoading,
      error: deleteCodefixError,
    },
    deleteCodefix,
  ] = useAxios(
    {
      method: "DELETE",
      url: `/codefixes/`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [
    {
      data: deleteScriptData,
      loading: deleteScriptLoading,
      error: deleteScriptError,
    },
    deleteScript,
  ] = useAxios(
    {
      method: "DELETE",
      url: `/scripts/`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const deleteElement = () => {
    (async () => {
      var url = await getCurrentUrl();
      if (url) {
        let dataObj = {
          data: {
            pattern_id: patternId,
            name: name,
            selector: selector,
            url: url,
          },
        };
        if (type == "script") {
          deleteScript(dataObj);
          sendSocketMessage({ action: "delete", type: "script", id: patternId, hash: data.initialHash, name: name });
        } else {
          deleteCodefix(dataObj);
          sendSocketMessage({ action: "delete", type: "codefix", id: patternId, hash: data.initialHash, name: name });
        }
      }
    })();
  };

  useEffect(() => {
    if (deleteCodefixData) {
      toastSuccess(
        `${type.charAt(0)?.toUpperCase() + type.slice(1)} '${name}' was deleted.`
      );
      updatePatternTree();
    }
  }, [deleteCodefixData]);

  useEffect(() => {
    if (deleteCodefixError) {
      const errors =
        deleteCodefixError.response &&
        deleteCodefixError.response.data &&
        deleteCodefixError.response.data.Errors;
      toastError(`Cloud not delete codefix. ${errors}`);
    }
  }, [deleteCodefixError]);

  useEffect(() => {
    if (deleteScriptData) {
      toastSuccess(`Script '${name}' was deleted.`);
      updatePatternTree();
    }
  }, [deleteScriptData]);

  useEffect(() => {
    if (deleteScriptError) {
      const errors =
        deleteScriptError.response &&
        deleteScriptError.response.data &&
        deleteScriptError.response.data.Errors;
      toastError(`Cloud not delete script. ${errors}`);
    }
  }, [deleteScriptError]);

  const goToUrl = () => {
    sendMessage({ type: "change_url", url: url });
  };

  const disbandGroup = () => {
    (async () => {
      var url = await getCurrentUrl();
      if (url) {
        toastError(`Feature is not implemented yet!`);
      }
    })();
  };

  const hideEntity = () => {
    (async () => {
      var url = await getCurrentUrl();
      if (url) {
         ChangeVisibilityFetch({
          data: {
            url,
            "pattern_id": patternId,
            hash: data.hash
          }
        })
      }
    })();
  };

  useEffect(() => {
    if(ChangeVisibilityData){
      updatePatternTree();
    }
  }, [ChangeVisibilityData])

  if (type == "pattern" && dataType !== "group") {
    return (
      <ContextMenu>
        <Menu.Item>
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setSelectedItem(data);
              navigate(`/main/edit-pattern/${patternId}`);
            }}
          >
            EDIT PATTERN
          </Button>
        </Menu.Item>
        <StyledDivider />
        {!isGroup && <Menu.Item>
          <Popconfirm
            overlayStyle={{ "z-index": "99999999" }}
            placement="right"
            title={<div>Are you sure you want to delete this {type}?</div>}
            onConfirm={() => setChangeDetectionModal({
              executionFunc: deletePattern,
              id: patternId,
              action: "delete",
              name
            })}
            okText="Yes"
            cancelText="No"
          >
            <Button
              onClick={(e) => e.stopPropagation()}
              type="link"
              icon={<CloseCircleOutlined />}
            >
              DELETE
          </Button>
          </Popconfirm>
        </Menu.Item>}
        { !isGroup && <StyledDivider />}
        <Menu.Item>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={hideEntity}
          >
           {hideShowText}
          </Button>
        </Menu.Item>
        <StyledDivider />
        <Menu.Item>
          <Button
            type="link"
            icon={<RightSquareOutlined />}
            onClick={() => goToUrl()}
          >
            GO TO URL
            </Button>
        </Menu.Item>
      </ContextMenu >
    );
  } else if (type == "pattern" && dataType === "group") {
    return (
      <ContextMenu>
        <Menu.Item>
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setSelectedItem(data);
              navigate(`/main/edit-pattern/${patternId}`);
            }}
          >
            EDIT GROUP
          </Button>
        </Menu.Item>
        <StyledDivider />
        <Menu.Item>
          <Button
            type="link"
            icon={<CloseCircleOutlined />}
            onClick={() => disbandGroup()}
          >
            DISBAND
          </Button>
        </Menu.Item>
      </ContextMenu>
    );
  } else {
    return (
      <ContextMenu>
        {!isGroup && <Menu.Item>
          <Popconfirm
            overlayStyle={{ "z-index": "99999999" }}
            placement="right"
            title={<div>Are you sure you want to delete this {type}?</div>}
            onConfirm={() => deleteElement()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              icon={<CloseCircleOutlined />}
              onClick={(e) =>{
                e.stopPropagation()
              }}
            >
              DELETE
          </Button>
          </Popconfirm>
        </Menu.Item>}
        {!isGroup && <StyledDivider />}
        <Menu.Item>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={hideEntity}
          >
            {hideShowText}
          </Button>
        </Menu.Item>
      </ContextMenu>
    );
  }
};

export default TreeMenu;
