import React, { useState, useEffect } from 'react'
import { Form, Radio, Input, Select } from 'antd';
import { resetFields } from 'Shared/formService';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import styled from 'styled-components';
import { colors } from "Shared/colors";
import { useGlobal, SITE_SETTINGS_CONSTS } from 'GlobalContext/GlobalContext';
import { useCodefix } from 'Main/Context/Codefix/CodefixContext';
import SelectorBehaviors from './SelectorBehaviors';
import Languages from './Languages';

const RadioGroup = styled(Radio.Group)`
  width: 100%;
  text-align: center;
  .ant-radio-button-wrapper-checked{
    background: ${colors.BLUE} !important;
  }
`;

const RadioButton = styled(Radio.Button)`
  width: 33%;
  background: black;
  color: white;
  font-size: 12px !important;
  border: none !important;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
`;

const Preview = styled.div`
    width: 100%;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
`;


const TYPES = {
    MANUAL: "manual",
    EXPRESSION: "expression",
    BIND_TO: "bind_to"
}

/*behaviors with type=lang in codefix.yaml file*/
const Description = ({ form, codefixEditData, selectedOption, patternId }) => {
    const { siteSettings } = useGlobal();
    const { description, setDescription } = useCodefix();
    const [activeLang, setActiveLang] = useState(null);
    const [bindToData, setBindToData] = useState([]);

    const initDescription = (type) => {
        const languages = siteSettings && siteSettings[SITE_SETTINGS_CONSTS.LANGUAGES];
        let data = { method: type || TYPES.EXPRESSION };
        languages.forEach(lang => {
            data[lang] = "";
        })
        if (!codefixEditData) {
            setDescription(data);
            setActiveLang(languages[0]);
        }
    }

    useEffect(() => {
        if (siteSettings) {
            initDescription();
        }
    }, [siteSettings])

    useEffect(() => {
        return () => {
            setDescription(null);
            setActiveLang(null);
        }
    }, [])

    useEffect(() => {
        if (codefixEditData) {
            let values = codefixEditData.data;
            var descriptionData = {}
            values && values.values && values.values.forEach((l, index) => {
                if (index === 0) {
                    setActiveLang(l.lang);
                }
                descriptionData[l.lang] = l.text
            })

            descriptionData.method = values.method;
            form.setFieldsValue({
                method: values.method
            });
            descriptionData.id = values.id;
            setDescription(descriptionData);
            if (values.method === TYPES.BIND_TO) {
                if (!values.sourceAttr) {
                    values.sourceAttr = "text"
                    setBindToData(["text"]);
                }
                else {
                    setBindToData([values.sourceAttr, "text"]);
                }

                form.setFieldsValue({
                    bindToAttribute: values.sourceAttr,
                    selectorBehavior: values.selector
                });
            }
        }
    }, [codefixEditData])

    const constructFields = () => {
        const languages = siteSettings && siteSettings[SITE_SETTINGS_CONSTS.LANGUAGES];
        if (languages && languages.length && description) {
            switch (description.method) {
                case TYPES.EXPRESSION:
                    return expression(languages);
                case TYPES.MANUAL:
                    return manual(languages);
                case TYPES.BIND_TO:
                    return bindTo(languages);
                default:
                    break;
            }
        }
    }

    const updateDescriptionOnChange = (value, method) => {
        if (activeLang && description) {
            setDescription({
                ...description,
                method,
                [activeLang?.toLowerCase()]: value
            })
        }
    }

    const manual = (languages) => {
        return (
            <>
                <Wrapper>
                    <Languages form={form} languages={languages} setActiveLang={setActiveLang} activeLang={activeLang} />
                    <Form.Item
                        name={TYPES.MANUAL}
                        initialValue={activeLang && description && description[activeLang?.toLowerCase()]}
                        rules={[{ required: true, message: 'Field cannot be empty' }]}
                    >
                        <div>
                            <TooltipLabel required value="MANUAL" tooltip={{ text: "fill later" }} />
                            <Input
                                name={TYPES.MANUAL}
                                onChange={(e) => updateDescriptionOnChange(e.target.value, TYPES.MANUAL)}
                                value={activeLang && description && description[activeLang?.toLowerCase()]}
                                placeholder="ENTER TEXT"
                            />
                        </div>
                    </Form.Item>
                </Wrapper>
            </>
        )
    }

    const expression = (languages) => {
        return (
            <>
                <Wrapper>
                    <Languages form={form} languages={languages} setActiveLang={setActiveLang} activeLang={activeLang} />
                    <Form.Item
                        name={TYPES.EXPRESSION}
                        initialValue={activeLang && description && description[activeLang?.toLowerCase()]}
                        rules={[{ required: true, message: 'Field cannot be empty' }]}
                    >
                        <div>
                            <TooltipLabel required value="EXPRESSION | TEXT" tooltip={{ text: "fill later" }} />
                            <Input
                                name={TYPES.EXPRESSION}
                                onChange={(e) => updateDescriptionOnChange(e.target.value, TYPES.EXPRESSION)}
                                value={activeLang && description && description[activeLang?.toLowerCase()]}
                                placeholder="EXPRESSION | TEXT"
                            />
                        </div>
                    </Form.Item>
                </Wrapper>
                {/*preview && <div>
                        <TooltipLabel value="PREVIEW" tooltip={{ text: "fill later" }} />
                        <Preview>{preview}</Preview>
                </div>*/}
            </>
        )
    }

    const bindTo = (languages) => {
        return (
            <>
                <SelectorBehaviors setBindToData={setBindToData} isDescription={true} label="BINDED ELEMENT SELECTOR" form={form} codefixEditData={codefixEditData} selectedOption={selectedOption} patternId={patternId} />
                <div>
                    <TooltipLabel required value="BIND TO ATTRIBUTE | TEXT" tooltip={{ text: "fill later" }} />
                    <Form.Item name="bindToAttribute">
                        <Select
                            placeholder="BIND TO ATTRIBUTE | TEXT"
                            disabled={!bindToData.length}
                            options={bindToData.map(attr => { return { value: attr?.toUpperCase() } })} />
                    </Form.Item>
                </div>
                <Wrapper>
                    <Languages form={form} languages={languages} setActiveLang={setActiveLang} activeLang={activeLang} />
                    <div>
                        <TooltipLabel value="TEMPLATE" tooltip={{ text: "fill later" }} />
                        <Input
                            name={TYPES.BIND_TO}
                            onChange={(e) => updateDescriptionOnChange(e.target.value, TYPES.BIND_TO)}
                            value={activeLang && description && description[activeLang?.toLowerCase()]}
                            placeholder="TEMPLATE"
                        />
                    </div>
                </Wrapper>
                {/*preview && <div>
                        <TooltipLabel value="PREVIEW" tooltip={{ text: "fill later" }} />
                        <Preview>{preview}</Preview>
                </div>*/}
            </>
        )
    }

    return (
        <>
            <TooltipLabel required value="METHOD" tooltip={{ text: "fill later" }} />
            <Form.Item name="method">
                <RadioGroup size="small" onChange={(e) => {
                    setDescription({ ...description, method: e.target.value })
                    initDescription(e.target.value)
                }}
                    defaultValue={TYPES.EXPRESSION} buttonStyle="solid"
                >
                    <RadioButton value={TYPES.BIND_TO}>BIND TO</RadioButton>
                    <RadioButton value={TYPES.EXPRESSION}>EXPRESSION</RadioButton>
                    <RadioButton value={TYPES.MANUAL}>MANUAL</RadioButton>
                </RadioGroup>
            </Form.Item>
            {constructFields()}
        </>
    )
}

export const getDescriptionData = (form, descriptionData) => {

    let result = {
        method: descriptionData.method,
        id: descriptionData.id,
        values: []
    }

    const langs = Object.keys(descriptionData).filter(d => d !== "method" && d !== "id");
    langs.forEach((langCode) => {
        if (langCode !== "method") {
            result.values.push({
                lang: langCode,
                text: descriptionData[langCode]
            });
        }
    });

    if (descriptionData.method === TYPES.BIND_TO) {
        result.selector = form.getFieldValue("selectorBehavior");
        let attribute = form.getFieldValue("bindToAttribute");
        if (attribute && attribute.toLowerCase() !== "text") {
            result.sourceAttr = attribute?.toLowerCase();
        }
    }

    return result;
}

export default Description;