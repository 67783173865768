import React, { useState, useEffect } from 'react';
import { Input, Form, Tabs, Button, Tag, Typography } from 'antd';
import { KEYS, OPTIONS } from '../../consts';
import { sendMessage, recieveMessage } from 'Shared/messages';
import { SITE_SETTINGS_CONSTS } from 'GlobalContext/GlobalContext';
import  {LangInput, SaveButton, SelectorContainer, ScrollContainer, StyledForm, StyledTabs} from './styles';

const { TabPane } = Tabs;
const { Text } = Typography;

const DisplayNameForm = ({form, displayForm, setOpen, siteSettings, editRaw, setDisplayNameType}) => {

    const [selectorErrors, setSelectorErrors] = useState(null);
    const [languagesErrors, setlanguagesErrors] = useState(null);

    useEffect(() => {
        return () => {
            displayForm.resetFields();
        }
    }, [])

    const saveSelector = () => {
        let selector = displayForm.getFieldValue(KEYS.DISPLAY_NAME_SELECTOR);
        if(selector === undefined || selector.trim().length === 0){
            setSelectorErrors(["Selector required"]);
            return;
        }

        sendMessage({type: "validate_selector", selector});
        recieveMessage("validate_selector", (data) => { 
            let isExist = data.result ? data.result.isExist : null;
            let errors = data.result ? data.result.errors : null;
            if(isExist){
                form.setFieldsValue({ [KEYS.DISPLAY_NAME]: selector });   
                setSelectorErrors(true);
                setOpen(false);                          
            }
            else if(errors){
                setSelectorErrors(errors);
            }
        });
    }

    const saveLanguages = (e) => {
        let languages = Object.keys(displayForm.getFieldsValue()).filter((item) => item !== KEYS.DISPLAY_NAME_SELECTOR);
        let hasValue = languages.filter((l) => {
            let value = displayForm.getFieldValue(l);
            return value && value.trim().length;
        });
        if(!hasValue.length){
            setlanguagesErrors(["At least one language must be filled"])
            return;
        }
        form.setFieldsValue({ [KEYS.DISPLAY_NAME]: hasValue });
        setlanguagesErrors(null);
        setOpen(false);
    }

    const count =  siteSettings && siteSettings[SITE_SETTINGS_CONSTS.LANGUAGES].length;
    
    return (
        <div>
            <StyledForm form={displayForm}>
                <StyledTabs count={count} defaultActiveKey="1">
                    <TabPane tab={<span>LANGUAGES</span>} key="1">
                        <div>
                            <ScrollContainer count={count}>
                                {              
                                    siteSettings && siteSettings[SITE_SETTINGS_CONSTS.LANGUAGES].map((item, index) => (
                                        <div key={index}>
                                            {
                                                index === 0
                                                ?
                                                    <div>
                                                        {languagesErrors && languagesErrors.length && <Text type="danger" style={{marginBottom: 2, marginLeft: '3.3rem'}}>{languagesErrors[0]}</Text>}
                                                        <Form.Item hasFeedback validateStatus={languagesErrors == null ? null : !languagesErrors.length ? "success" : "error"} label={item?.toUpperCase()} name={item}>
                                                            <LangInput isLongLangCode={item.length > 2} />
                                                        </Form.Item>
                                                    </div>
                                                :
                                                    <Form.Item label={item?.toUpperCase()} name={item}>
                                                        <LangInput isLongLangCode={item.length > 2} />
                                                    </Form.Item>
                                            }
                                        </div>
                                    ))
                                }
                            </ScrollContainer>
                            <div style={{textAlign: 'end', marginTop: '0.5rem'}}>
                                <Button onClick={() => setOpen(false)}>CANCEL</Button>
                                <SaveButton id="saveLanguages" onClick={(e) => {saveLanguages(e); setDisplayNameType(KEYS.LANGUAGES);}}>SAVE</SaveButton>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={<span>SELECTOR</span>} key="2">
                        <div>
                            <SelectorContainer count={count}>
                                {selectorErrors && selectorErrors.length && <Text type="danger" style={{marginBottom: 2}}>{selectorErrors[0]}</Text>}
                                <Form.Item style={{marginBottom: 5}} hasFeedback validateStatus={selectorErrors == null ? null : !selectorErrors.length ? "success" : "error"} name={KEYS.DISPLAY_NAME_SELECTOR}>
                                    <Input placeholder="SELECTOR" />
                                </Form.Item>
                            </SelectorContainer>
                            <div style={{textAlign: 'end'}}> 
                                <Button onClick={() => setOpen(false)}>CANCEL</Button>
                                <SaveButton id="saveSelector" onClick={() => {saveSelector(); setDisplayNameType(KEYS.DISPLAY_NAME_SELECTOR);}}>SAVE</SaveButton>
                            </div>
                        </div>
                    </TabPane>
                </StyledTabs>
            </StyledForm>
        </div>
    )
}

export default DisplayNameForm;