import React from "react";
import { Typography, Popover, Button } from "antd";
import styled from "styled-components";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colors } from "Shared/colors";
import { toastInfo, toastSuccess } from "Shared/utils";

const { Text } = Typography;

const StyledButton = styled(Button)`
border: 0px;
width:25px !important;
:focus{
    color:#fff
}
`;


const StyledText = styled(Text)`
width:25px;
 div[role='button']{
     color:${colors.BLUE};
 }
`;

const StyledPopupContainer = styled.div`
 display:flex;
`;

const PageUrl = ({ url, icon, forceVisivle, setForceVisible }) => {
    return (
        <Popover
            onVisibleChange={(visible) => setForceVisible({ ...forceVisivle, pageUrl: visible })}
            title={<div>Page url</div>}
            content={<PageUrlPopOver url={url} />}
            placement="topRight"
            mouseLeaveDelay="1" >
            <StyledButton onClick={() => window.open(url, "_blank")} size={"large"} ghost={true} icon={icon} />
        </Popover >
    );
}

const PageUrlPopOver = ({ url }) => {
    return (
        <StyledPopupContainer>
            <Text style={{ flex: 1 }}>{url}</Text>
            <StyledText copyable={{
                text: url,
                onCopy: () => toastInfo(`Page url: ${url} copied to clipboard`, 2)
            }} />
        </ StyledPopupContainer>
    );
}


export default PageUrl;