import React, {useState, useEffect} from 'react';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import { Select, Tag } from 'antd';
import {tooltipsContent, specialPropertiesOptions} from '../data';
import {FormItem} from '../PatternForm';
import { KEYS, OPTIONS } from '../consts';
import {isMainOrSiteNavLandmark} from './Type';
import { useGlobal, SITE_SETTINGS_CONSTS } from 'GlobalContext/GlobalContext';

const { Option } = Select;

export const SpecialProperties = ({form, edit, isBodySelector, isLandmarkType, setDisplayNameDisabled, constants}) => {
    const { siteSettings } = useGlobal();
    const [isDynamicDisabled, setDynamicDisabled] = useState(false); 
    const [options, setOptions] = useState([]);

    const shouldAltNavBeDisabled = () => {
        const type = form.getFieldValue(KEYS.TYPE);
        return type === OPTIONS.SITE_NAVIGATION || type === OPTIONS.MAIN;
    }

    useEffect(() => {
        let properties = form.getFieldsValue()[KEYS.SPECIAL_PROPERTIES] || [];
        properties = properties && properties.filter((pr) => pr !== OPTIONS.ALTERNETIVE_NAVIGATION);
        if(edit && edit[KEYS.SPECIAL_PROPERTIES].includes(OPTIONS.ALTERNETIVE_NAVIGATION)){
            edit[KEYS.SPECIAL_PROPERTIES] = [];
            setDisplayNameDisabled(false);
        }
        else if(isLandmarkType.state && isLandmarkType.selectedOption !== OPTIONS.SECTION){
            form.setFieldsValue({ [KEYS.SPECIAL_PROPERTIES]:[...properties,  OPTIONS.ALTERNETIVE_NAVIGATION] });
            setDisplayNameDisabled(false);
        }
        else if(!edit || edit[KEYS.SPECIAL_PROPERTIES].length === 0){
            form.setFieldsValue({ [KEYS.SPECIAL_PROPERTIES]: properties });
            setDisplayNameDisabled(true);
        }
        else {
            setDisplayNameDisabled(true);
        }
    }, [isLandmarkType, edit])

    useEffect(() => {
        if(constants){
            let data = constants[SITE_SETTINGS_CONSTS.PROPERTIES].map((option) => { 
                if(option.value === OPTIONS.IMPORTANT){
                    return { value: option.value, disabled: true};
                }
                else if(option.value === OPTIONS.FLASHING || option.value === OPTIONS.ALTERNETIVE_NAVIGATION || option.value === OPTIONS.MOVING){
                    return { value: option.value, bodySelectorDisabled: true};
                }
                return {value: option.value} 
            })
            setOptions(data);
        }
    }, [constants])

    const specialPropsOnSelected = (value) => {

        if(value === OPTIONS.DYNAMIC_DEPRECATED){
            setDynamicDisabled(OPTIONS.DYNAMIC);
        }
        else if(value === OPTIONS.DYNAMIC){
            setDynamicDisabled(OPTIONS.DYNAMIC_DEPRECATED);
        }
        else if(value === OPTIONS.ALTERNETIVE_NAVIGATION){
            setDisplayNameDisabled(false);
        }
    }

    const specialPropsOnDeSelect = (value) => {
        if(value === OPTIONS.DYNAMIC_DEPRECATED || value === OPTIONS.DYNAMIC){
            setDynamicDisabled(false);
        }
        else if(value === OPTIONS.ALTERNETIVE_NAVIGATION){
            setDisplayNameDisabled(true);
        }
    }

    return (
        <div>
            <TooltipLabel value="SPECIAL PROPERTIES" tooltip={{ text: tooltipsContent.specialProperties }} />
            <FormItem name={KEYS.SPECIAL_PROPERTIES}>
                <Select
                    onSelect={(value) => specialPropsOnSelected(value)}
                    onDeselect={(value) => specialPropsOnDeSelect(value)}
                    placeholder="SPECIAL PROPERTIES"
                    mode="multiple"
                    tagRender={(props) => 
                    <Tag closable={!isMainOrSiteNavLandmark(isLandmarkType.selectedOption) && !shouldAltNavBeDisabled()} onClose={() => props.onClose()}>
                        {props.value}
                    </Tag>}
                    style={{ width: '100%' }}
                >
                    {
                        options.map((option, key) => {
                            const isDynamicDeprecatedDisabled = siteSettings && !siteSettings[SITE_SETTINGS_CONSTS.DEPRECATED_DYNAMIC] && option.value === OPTIONS.DYNAMIC_DEPRECATED;
                            const isDisabled = isBodySelector && option.bodySelectorDisabled || option.disabled;
                            const isAltNavigationDisabled = 
                            option.value === OPTIONS.ALTERNETIVE_NAVIGATION && (!isLandmarkType.state || 
                                isMainOrSiteNavLandmark(isLandmarkType.selectedOption) ||
                                shouldAltNavBeDisabled());
                            return <Option key={key} disabled={isDynamicDisabled && isDynamicDisabled === option.value  || isDisabled || isAltNavigationDisabled || isDynamicDeprecatedDisabled} value={option.value}>{option.value}</Option>
                        })
                    }
                </Select>
            </FormItem>
        </div>
    )
}
