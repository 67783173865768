import React, { useState } from 'react'
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import Navigation from './Navigation/Navigation';

const { Header } = Layout;

const StyledHeader = styled(Header)`
    height:40px;
    background: black;
    color: white;
    z-index: 1;
    line-height: 2.2rem;
    padding: 0;

    .ant-menu-submenu{
        padding: 0px !important;
    }
`

const AppHeader = ({ setSideBarCollaped, setNotificationVisible, isMobile }) => {
    return (
        <StyledHeader>
            <Navigation isMobile={isMobile}  setNotificationVisible={setNotificationVisible} setSideBarCollaped={setSideBarCollaped} />
        </StyledHeader>
    )
}

export default AppHeader;