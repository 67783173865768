import React, { useEffect, useState } from 'react'
import { Layout } from 'antd';
import { PRODUCT } from "./consts"
import ScripterPreview from "./Preview/ScripterPreview"
import ScripterHeader from './Header/ScripterHeader';
import ScripterSider from './Sider/ScripterSider';
import ScripterContent from './Content/ScripterContent';
import { Storage } from 'Shared/Services/storage';
import styled from 'styled-components';
import { useSwitch } from 'Scripter/Context/SwitchContext';
import { promiseMessage } from 'Shared/messages';

const MinimizeWrapper = styled.div`
${props => props.minimized ?
        `
    header{
        box-shadow: 0px 12px 11px -3px rgb(0 0 0 / 35%);
        border-radius: 5px;
    }
    main, .ant-layout-has-sider{
        opacity: 0;
    }
       `
        :

        `
    header{
        box-shadow: 0px 5px 11px 4px rgb(0 0 0 / 15%);
        border-radius: 0px;
    }
    main, .ant-layout-has-sider{
        opacity: 1;
    }
    `
    };
`;

const Scripter = () => {

    const { ideVisible, setIdeVisibility, isMinimized } = useSwitch();
    const [product, setProduct] = useState(Storage.GetItem("product"));

    useEffect(() => {
        if (!product) {
            (async () => {
                const result = await promiseMessage({ type: "get-product-name" });
                setProduct(result);
            })()
        }
    }, []);

    useEffect(() => {
        product && setIdeVisibility(product && product == PRODUCT.IDE);
    }, [product]);

    return (
        <>
            {
                !ideVisible
                    ?
                    <MinimizeWrapper minimized={isMinimized}>
                        <ScripterPreview />
                    </MinimizeWrapper>
                    :
                    <>
                        <MinimizeWrapper minimized={isMinimized}>
                            <ScripterHeader />
                            <Layout>
                                <ScripterSider />
                                <ScripterContent />
                            </Layout>
                        </MinimizeWrapper>
                    </>
            }
        </>
    );
}

export default Scripter;