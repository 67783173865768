import React, { useState, useEffect } from "react";
import { Layout, Button, Spin } from "antd";
import MonacoEditor from "react-monaco-editor";
import useAxios from "axios-hooks";
import { colors } from "Shared/colors";
import styled from "styled-components";
import { monacoEditorOptions } from "../data";
import { getCurrentUrl } from 'Shared/Services/getCurrentUrl';
import { navigate } from "@reach/router";
import { toastError, toastSuccess } from "Shared/utils";

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  display: flex;
  flex-flow: column;
  height: calc(100vh - 40px);
`;
const StyledContent = styled(Content)`
  padding: 1rem 1rem 0 1rem;
  .margin {
    background-color: #f0f2f5 !important;
  }
`;

export const StyledLoadingContainer = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vh;
`;

export const StyledHeader = styled.div`
height: 50px;
background: ${colors.DARK90}!important;
.ant-menu{
    background: ${colors.DARK90}!important;
    height: 50px;
}
.ant-select-selector {
    border-radius: 5px!important;
}
`;


export const StyledFooter = styled.div`
display: grid;
grid-template-columns:2fr 1fr 9fr;
padding: 1rem;
margin-left: 4rem;
`;

export const StyledSpinner = styled(Spin)`
  position: relative;
  left: 50%;
  top: 50%;
`;

const StyledUpdateButton = styled(Button)`
  font-weight: 600;
  height: 2.5rem;
  border: none;
  border-radius: 0;
  background: ${colors.BLUE} !important;
  &:hover {
    background: ${colors.DARK90} !important;
  }
`;

const StyledCancelButton = styled(Button)`
  font-weight: 600;
  height: 2.5rem;
  color: ${colors.MID_GREY};
  &:hover {
    color: ${colors.DARK100};
  }
`;

const LanguageScript = ({
  sideBarCollaped,
  setSideBarCollaped,
  setCollapseColor,
}) => {
  setCollapseColor("white");

  const [editorValue, setEditorValue] = useState(null);
  const [editor, setEditor] = useState(null);
  const [monaco, setMonaco] = useState(null);

  const [{ data: getData }, fetchGetData] = useAxios(
    {
      method: "POST",
      url: `/languageScript`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [{ data: updatedData, loading: updateDataLoading, error: updateDataError }, fetchUpdateData] = useAxios(
    {
      method: "PUT",
      url: `/languageScript`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );



  //editor layout fix
  useEffect(() => {
    setTimeout(() => {
      editor && editor.layout();
    }, 500);
  }, [sideBarCollaped, editor]);
  //window auto fix layout on resize
  window.onresize = () => {
    editor && editor.layout();
  };


  useEffect(() => {
    (async () => {
      const url = await getCurrentUrl();
      if (url) {
        fetchGetData({ data: { url } })
      }
    })();
  }, []);


  useEffect(() => {
    if (getData) {
      setEditorValue(getData.content);
    }
  }, [getData]);

  useEffect(() => {
    if (updatedData) {
      setSideBarCollaped(false);
      toastSuccess(
        `Language script updated successfully`
      );
      navigate("/main/default");
    }
  }, [updatedData]);

  useEffect(() => {
    if (updateDataError) {
      toastError(updateDataError.response.data.Errors);
    }
  }, [updateDataError]);

  const editorDidMount = (_editor, _monaco) => {
    setEditor(_editor);
    setMonaco(_monaco);
  };



  return (
    <StyledLayout>
      <StyledHeader></StyledHeader>
      <StyledContent>
        <MonacoEditor
          language="typescript"
          value={editorValue}
          options={monacoEditorOptions}
          onChange={setEditorValue}
          theme={"vs"}
          editorDidMount={editorDidMount}
          automaticLayout={true}
        />
      </StyledContent>
      <StyledFooter>
        <StyledUpdateButton
          onClick={() => {
            (async () => {
              const url = await getCurrentUrl();
              if (url) {
                fetchUpdateData({
                  data: {
                    url,
                    content: editorValue
                  }
                })
              }
            })();
          }}
          type="primary"
        >
          Update
          </StyledUpdateButton>
        <StyledCancelButton
          onClick={() => {
            setSideBarCollaped(false);
            navigate("/main/default");
          }}
          type="link"
        >
          Cancel
        </StyledCancelButton>
      </StyledFooter>
    </StyledLayout>
  );
};

export default LanguageScript;
