import React, { useState } from "react";
import { Input, Select, Row, Col, Typography, Switch } from "antd";
import { TooltipLabel } from "Shared/Components/TooltipLabel";
import { tooltipsContent } from "../data";
import { sendMessage, recieveMessage } from "Shared/messages";
import { FormItem } from "../PatternForm";
import { KEYS, OPTIONS } from "../consts";
import { apiClient } from "Shared/apiClient";
import { SITE_SETTINGS_CONSTS } from "GlobalContext/GlobalContext";
import { colors } from "Shared/colors";
import {preValidation} from 'Main/Views/Codefix/Selector/Selector';

const { Text } = Typography;

const SELECTOR_IN_USE_MESSAGE = "Selector is already in use by another pattern";

export const selectorValidator = (
  selector,
  ignoreInitialEditSelector,
  form,
  selectorsInUse,
  patternId,
  allowGrouping
) => {
  return new Promise((resolve, reject) => {
   
    preValidation(selector, reject);

    if (selector == "body") {
      return reject(["body selector should only define has {BODY}"]);
    }
    
    const isPatternTypeRoot = form
      .getFieldValue(KEYS.HIERARCHY)
      .includes(OPTIONS.HIERARCHY_ROOT);

    const parent = form ? form.getFieldValue(KEYS.PARENT) : null;

    sendMessage({
      type: "selector_details",
      selector,
      parent,
      selectorsInUse,
      patternId,
    });
    recieveMessage("selector_details", (data) => {
      data = data.result;
      let isExist = data.isExist;
      let isAlreadyMapped = data.isAlreadyMapped;
      let parentPatterns = data.parentPatterns;
      let children = data.children;
      if (data.errors) {
        return reject(data.errors);
      }

      if (!isExist) {
        return reject(["Selector not match any element in the DOM"]);
      } else if (parentPatterns.length > 1 && !isPatternTypeRoot) {
        return reject([
          "Selector repeate multiple times on the page with different parents patterns",
        ]);
      } else if (!ignoreInitialEditSelector && isAlreadyMapped && !allowGrouping) {
        return reject([SELECTOR_IN_USE_MESSAGE]);
      }

      return resolve({ parentPatterns, children });
    });
  });
};

export const Selector = ({
  form,
  currentSelector,
  setCurrentSelector,
  siteSettings,
  edit,
  setAllowGrouping,
  allowGrouping,
}) => {
  const [selectorErrors, setSelectorErrors] = useState(null);
  const [showGroup, setShowGroup] = useState(false);

  const switchGroupping = (val) => {
    const index = selectorErrors.indexOf(SELECTOR_IN_USE_MESSAGE);
    if (index > -1) {
      selectorErrors.splice(index, 1);
    }
    if (selectorErrors.length == 0) {
      setSelectorErrors(selectorErrors);
    }
    setAllowGrouping(val);
  };

  return (
    <Row>
      <Col xs={{span: 18}} md={{span: showGroup ? 16 : 20}}>
        <TooltipLabel
          required
          value="SELECTOR"
          tooltip={{ text: tooltipsContent.selector }}
        />
        <FormItem
          hasFeedback
          validateTrigger={["onBlur"]}
          validateStatus={
            selectorErrors === null
              ? null
              : selectorErrors.length
              ? "error"
              : "success"
          }
          rules={[
            {
              validator: async (rule, selector) => {
                setCurrentSelector(selector);
                try {
                  let getSelectorsFromServer = await apiClient(
                    "/patterns/get-selectors-in-use",
                    {
                      body: {
                        site_id: siteSettings[SITE_SETTINGS_CONSTS.SITE_ID],
                      },
                    }
                  );
                  let selectorsInUse =
                    getSelectorsFromServer && getSelectorsFromServer.data;
                  let ignoreInitialEditSelector =
                    edit && selector == edit[KEYS.SELECTOR];
                  let patternId = edit ? edit.id : null;
                  selectorValidator(
                    selector,
                    ignoreInitialEditSelector,
                    form,
                    selectorsInUse,
                    patternId,
                    allowGrouping
                  )
                    .then((result) => {
                      setSelectorErrors([]);
                      setShowGroup(false);
                      return Promise.resolve();
                    })
                    .catch((result) => {
                      if (result && result.includes(SELECTOR_IN_USE_MESSAGE)) {
                        setShowGroup(true);
                      } else {
                        setShowGroup(false);
                      }
                      setSelectorErrors(result);
                      return Promise.reject(result);
                    });
                } catch (err) {
                  return Promise.reject(
                    (err.response &&
                      err.response.data &&
                      err.response.data.Errors) ||
                      err
                  );
                }
              },
            },
          ]}
          style={{ marginBottom: 0 }}
          name={KEYS.SELECTOR}
        >
          <Input id="selector" placeholder="SELECTOR" disabled={edit && edit.group} />
        </FormItem>
        {selectorErrors && selectorErrors.length ? (
          <Text type="danger">{selectorErrors[0]}</Text>
        ) : null}
      </Col>
      {showGroup ? (
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: ".5rem",
          }}
          span={5}
        >
          <Switch
            style={{
              width: "100px",
              backgroundColor: allowGrouping ? colors.BLUE : colors.GRAY,
            }}
            checkedChildren="GROUP"
            unCheckedChildren="NO GROUP"
            checked={allowGrouping}
            onChange={(e) => switchGroupping(e)}
          />
        </Col>
      ) : null}
      <Col xs={{span: 5}} md={{span: 3}} offset={showGroup ? 0 : 1} >
        <TooltipLabel
          value="PARENT"
          tooltip={{ text: tooltipsContent.parent }}
        />
        <FormItem name={KEYS.PARENT} rules={[{ required: true }]}>
          <Select
            onSelect={() => {
              form.validateFields([KEYS.SELECTOR]);
            }}
            disabled={currentSelector === "{BODY}" || edit && edit.group}
          >
            {Array.from(Array(10).keys()).map((value, key) => (
              <Select.Option key={key} disabled={value > 5} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Col>
    </Row>
  );
};
