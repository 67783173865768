import React, { useEffect, useState } from "react";
import { Form, Button, Tag } from "antd";
import { colors } from "Shared/colors";
import { arrayCompare } from "Shared/utils";
import styled from "styled-components";
import {
  Hierarchy,
  Type,
  Selector,
  Devices,
  PatternName,
  SpecialProperties,
  DisplayName,
  isLandmark,
} from "./Fields";
import { initialValues } from "./data";
import { KEYS, OPTIONS } from "./consts";
import { useGlobal, SITE_SETTINGS_CONSTS } from "GlobalContext/GlobalContext";
import { navigate } from "@reach/router";
import {useNotification} from 'Main/Context/Notification/NotificationContext';
const { Item } = Form;

const StyledButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 9fr;
`;

export const FormItem = styled(Item)`
  .ant-form-item-children-icon {
    margin-top: 0 !important;
    top: 20% !important;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  display: grid;
  grid-template-rows: calc(89vh - 4rem) 2.2rem;
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  border: none;
  border-radius: 5px;
  color: white;
  background: ${colors.BLUE};
  &:hover {
    background: ${colors.DARK_BLUE};
  }
  &:focus {
    background: ${colors.DARK_BLUE};
  }
`;

export const StyledLinkButton = styled(Button)`
  font-weight: 600;
  height: 2.2rem;
  color: ${colors.MID_GREY};
  &:hover {
    color: ${colors.DARK100};
  }
  &:focus {
    color: ${colors.DARK100};
  }
`;

export const TagRender = (props) => {
  const { value, closable, onClose } = props;

  return (
    <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      {value}
    </Tag>
  );
};

const PatternForm = ({
  onSubmit,
  form,
  edit,
  editRaw,
  displayForm,
  submitLoading,
  setAllowGrouping,
  allowGrouping,
  patternId
}) => {
  const { constants, siteSettings } = useGlobal();
  const formRef = React.createRef();
  const [isTypeDisabled, setTypeDisabled] = useState(false);
  const [currentSelector, setCurrentSelector] = useState(null);
  const [isBodySelector, setIsBodySelector] = useState(false);
  const [displayNameType, setDisplayNameType] = useState(null);
  const [isDisplayNameDisabled, setDisplayNameDisabled] = useState(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const landmarks = constants && constants[SITE_SETTINGS_CONSTS.LANDMARKS];
  const {changeDetectionModal, setChangeDetectionModal} = useNotification();
  const [isLandmarkType, setLandmarkType] = useState({
    state: true,
    selectedOption: OPTIONS.SECTION,
  });

  const clearAll = () => {
    setTypeDisabled(false);
    setCurrentSelector(null);
    setIsBodySelector(false);
    setDisplayNameType(null);
    setDisplayNameDisabled(true);
    setLandmarkType({
      state: true,
      selectedOption: OPTIONS.SECTION,
    });
  };

  useEffect(() => {
    return () => {
      clearAll();
    };
  }, []);

  useEffect(() => {
    if (edit) {
      setCurrentSelector(edit[KEYS.SELECTOR]);
      edit[KEYS.DISPLAY_NAME_SELECTOR] &&
        setDisplayNameType([KEYS.DISPLAY_NAME_SELECTOR]);
      edit[KEYS.DISPLAY_NAME] && setDisplayNameType(KEYS.LANGUAGES);
      let type = edit[KEYS.TYPE];
    }
  }, [edit]);

  useEffect(() => {
    if (currentSelector === "{BODY}") {
      if (!edit) {
        form.setFieldsValue({ [KEYS.PARENT]: 0 });
        form.setFieldsValue({ [KEYS.SPECIAL_PROPERTIES]: [] });
      }
      setDisplayNameDisabled(true);
      setIsBodySelector(true);
    } else {
      setIsBodySelector(false);
    }
  }, [currentSelector, edit]);

  const submitPattern = (event) => {
    if(edit){
      const values = form.getFieldsValue();
      if(edit.display_names !== values.display_names || 
          !arrayCompare(edit.hierarchy, values.hierarchy) ||
          !arrayCompare(edit.supported_devices, values.supported_devices) ||
          !arrayCompare(edit.special_Properties, values.special_Properties) ||
          edit.parent_from_selector !== values.parent_from_selector ||
          edit.selector !== values.selector ||
          edit.type !== values.type)
        {
          setChangeDetectionModal({
            executionFunc: onSubmit,
            id: patternId,
            name: form.getFieldValue("patternName"),
            action: "edit",
            params: {event, displayNameType}
          });
        }
        else {
          onSubmit(event, displayNameType);
        }
    }
    else {
      onSubmit(event, displayNameType);
    }
  }

  return (
    <StyledForm
      initialValues={initialValues}
      labelCol={{ span: 24 }}
      ref={formRef}
      form={form}
      name="control-hooks"
    >
      <div>
        <PatternName edit={edit} siteSettings={siteSettings} form={form} />
        {(!edit || edit[KEYS.TYPE] !== OPTIONS.GROUP) && (
          <Selector
            edit={edit}
            siteSettings={siteSettings}
            currentSelector={currentSelector}
            setCurrentSelector={setCurrentSelector}
            form={form}
            setAllowGrouping={setAllowGrouping}
            allowGrouping={allowGrouping}
          />
        )}
        <Hierarchy
          edit={edit}
          landmarks={landmarks}
          isLandmarkType={isLandmarkType}
          constants={constants}
          disabled={isBodySelector}
          setTypeDisabled={setTypeDisabled}
          form={form}
          allowGrouping={allowGrouping}
        />
        <Type
          landmarks={landmarks}
          components={constants && constants[SITE_SETTINGS_CONSTS.COMPONENTS]}
          disabled={
            isBodySelector ||
            isTypeDisabled ||
            (edit && edit[KEYS.TYPE] === OPTIONS.GROUP)
          }
          setLandmarkType={setLandmarkType}
        />
        {(!edit || edit[KEYS.TYPE] !== OPTIONS.GROUP) && (
          <SpecialProperties
            edit={edit}
            isLandmarkType={isLandmarkType}
            form={form}
            constants={constants}
            isBodySelector={isBodySelector}
            isLandmarkType={isLandmarkType}
            setDisplayNameDisabled={setDisplayNameDisabled}
          />
        )}
        {(!edit || edit[KEYS.TYPE] !== OPTIONS.GROUP) && (
          <DisplayName
            setSubmitDisabled={setSubmitDisabled}
            setDisplayNameType={setDisplayNameType}
            editRaw={editRaw}
            edit={edit}
            displayForm={displayForm}
            form={form}
            disabled={isDisplayNameDisabled}
          />
        )}
        {(!edit || edit[KEYS.TYPE] !== OPTIONS.GROUP) && (
          <Devices
            edit={edit}
            constants={constants}
            form={form}
            isBodySelector={isBodySelector}
          />
        )}
      </div>
      <StyledButtonsContainer>
        <StyledButton
          disabled={isSubmitDisabled}
          loading={submitLoading}
          id="nextBtn"
          onClick={(event) => submitPattern(event)}
          background={colors.BLUE}
          type="primary"
          htmlType="submit"
        >
          SAVE & PROCEED
        </StyledButton>
        <StyledLinkButton
          id="cancelBtn"
          onClick={() => navigate("/main/default")}
          background={colors.BUTTON_GRAY}
          type="link"
          htmlType="submit"
        >
          CANCEL
        </StyledLinkButton>
      </StyledButtonsContainer>
    </StyledForm>
  );
};

export default PatternForm;
