import React, {useState, useEffect} from 'react';
import MenuItem from './Item';
import {useMenuWidget} from 'Main/Context/MenuWidget/MenuWidgetContext';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

const MenuItems = ({name, patternId, currentMenuSelector, form, codefixEditData, editSecondLevelMenuItems }) => {
    const [items, setItems] = useState([{key: uuidv4()}]);
    const {menuScriptsData, setScripts} = useMenuWidget();

    useEffect(() => {
        if(codefixEditData){
            const firstLevel = codefixEditData.data.firstLevel;
            const menuItems = firstLevel.filter(f => f.type === "menuWidgetItem");
            addBulkItems(menuItems.length, menuItems);
        }
    }, [codefixEditData]);

    useEffect(() => {
        if(editSecondLevelMenuItems){
            addBulkItems(editSecondLevelMenuItems.length, editSecondLevelMenuItems);
        }
    }, [editSecondLevelMenuItems]);

    const addItem = () => {
        setItems([...items, {key: uuidv4()}])
    }

    const addBulkItems = (length, data) => {
        let menuItems = [];
        for(let i = 0; i < length; i++){
            let menuItemData = data[i].values;
            menuItems.push({key: uuidv4(), editData: menuItemData});
        }
        setItems(menuItems)
    }

    const removeItem = (key) => {
        const updatedItems = items.filter(item => item.key !== key);
        setItems(updatedItems);
    }

    return (
        <>
            {
                items.map((item, index, self) => (
                    <MenuItem form={form} currentMenuSelector={currentMenuSelector} patternId={patternId} level={name} item={item} items={self} index={index} removeItem={removeItem} addItem={addItem} />
                ))
            }
        </>
    )
}



export default MenuItems;