import { Dropdown } from 'antd';
import React, { useEffect, useRef } from "react";
import { sendMessage, promiseMessage } from "Shared/messages";
import { PRODUCT, ADDRESS, MESSAGES, KEYS } from 'Scripter/Views/consts'
import { StyledDropdown } from "./Dropdown";
import { ArrowIcon } from "Scripter/Shared/Styles";
import { MenuButton } from "Scripter/Shared/MenuStyles";
import { useSwitch } from "Scripter/Context/SwitchContext";



const SwitchMenu = () => {

    const menuBtn = useRef(null);

    const { setActiveAddress, setActiveProd, setIsDropdownVisible, isDropdownVisible, changeProduct, activeProd, changeAddress } = useSwitch();

    useEffect(() => {
        (async () => {
            let msg = await promiseMessage({ type: MESSAGES.TOOLBAR_LOADED });
            updateEnv(msg.product, msg.address);
        })();
    }, []);

    const updateEnv = (prod, address) => {
        prod = prod ?? PRODUCT.FRONTEND;
        address = address ?? ADDRESS.PROD;

        changeProduct(prod);
        changeAddress(address);

        const addressdKey = (address == ADDRESS.LOCALHOST) ? KEYS.LOCAL_HOST : KEYS.PRODUCTION;
        setActiveAddress(addressdKey);
        setActiveProd(prod);

        const isMinimized = prod == PRODUCT.FRONTEND;
        sendMessage({ type: "minimize", data: isMinimized });

    };

    return (<>
        <Dropdown trigger={['click']} overlay={StyledDropdown()} onVisibleChange={(visible) => setIsDropdownVisible(visible)} placement="bottomCenter">
            <MenuButton ref={menuBtn} ismenuvisible={String(isDropdownVisible)}>
                {activeProd == PRODUCT.IDE ? activeProd.toUpperCase() : activeProd}
            </MenuButton>
        </Dropdown>
        <ArrowIcon onClick={() => { menuBtn.current.click() }} ismenuvisible={String(isDropdownVisible)} />
    </>);
};


export { SwitchMenu, MenuButton };