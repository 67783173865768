import React from "react";
import { Popconfirm } from "antd";
import IgnoreMessage from "./IgnoreMessage";
import styled from "styled-components";


const StyledContainer = styled.div`
  display :flex;
`;

const StyledSpan = styled.span`
    flex:1;
`;

const ImagesGalleryHeader = ({ loadedCount, allImagesCount, checkedItems, setCheckedItems, updateImages }) => {
    return (
        <StyledContainer>
            <StyledSpan>{loadedCount} / {allImagesCount} images.</StyledSpan>
            {checkedItems.length > 0 && <IgnoreMessage checkedItems={checkedItems} updateImages={updateImages} setCheckedItems={setCheckedItems} />}
        </StyledContainer>
    );
}

export default ImagesGalleryHeader;