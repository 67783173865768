import React from "react";
import { Popconfirm } from "antd";
import styled from "styled-components";


const IgnoreMessageContainer = styled.span`
    flex:1;
    text-align: end;
    margin-right: 15px;
`;

const StyledSpan = styled.span`

    text-decoration-line: underline;
    font-weight: 500;
    :hover{
        cursor: pointer;
    }
`;

const IgnoreMessage = ({ checkedItems, setCheckedItems, updateImages }) => {

    const handleIgnore = () => {
        const itemsToUpdate = [...checkedItems];
        itemsToUpdate.forEach(i => {
            i.ignore = !i.ignore;
            i.checked = false;
        });
        updateImages(itemsToUpdate);
        setCheckedItems([]);
    }

    return (
        <IgnoreMessageContainer checkedItems={checkedItems}>
            {checkedItems.length} {checkedItems.length > 1 ? "images" : "image"} selected, <Popconfirm
                title={`Are you sure you want to ${checkedItems[0]?.ignore ? 'unignore' : 'ignore'} ${checkedItems.length > 1 ? "these images" : "this images"}?`}
                onConfirm={handleIgnore}
                okText="Yes"
                cancelText="No"
            >
                <StyledSpan>{checkedItems[0]?.ignore ? 'unignore' : 'ignore'} {checkedItems.length > 1 ? "them" : "it"}?</StyledSpan>
            </Popconfirm>
        </IgnoreMessageContainer>
    );
}

export default IgnoreMessage;