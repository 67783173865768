import React, { useContext, createContext, useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { useAuth } from "Auth/AuthContext";

const GlobalContext = createContext();

export const SITE_SETTINGS_CONSTS = {
  LANGUAGES: "languages",
  SITE_ID: "site_id",
  DEPRECATED_DYNAMIC: "deprecated_dynamic",
  LANDMARKS: "landmarks",
  COMPONENTS: "components",
  HIERARCHIES: "hierarchies",
  PROPERTIES: "properties",
  DEVICES: "devices",
  BEHAVIORS: "behaviors",
  ROLES: "roles",
  WIDGETS: "widgets",
};

const isMultipleProperties = (constant) => {
  return (
    constant === SITE_SETTINGS_CONSTS.BEHAVIORS ||
    constant === SITE_SETTINGS_CONSTS.WIDGETS
  );
};

//var siteSettingsRetries = 0;

function GlobalProvider({ children }) {
  const [url, setUrl] = useState(null);
  const [user, setUser] = useState({ name: "", surname: "" });
  const [constants, setConstants] = useState(null);
  const [userInterval, setUserInterval] = useState(null);
  const [siteID, setSiteID] = useState(null);
  const [scripterApp, setScripterApp] = useState(undefined);

  const { isLoggedIn, logout } = useAuth();
  const [
    { data: siteSettings, loading: loading, error: errorsSiteSettings },
    fetchSiteSettings,
  ] = useAxios(
    {
      method: "POST",
      url: `/site/get-site-settings`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [
    { data: constantsRaw, loading: loadingConstants, error: errorsConstants },
    fetchConstants,
  ] = useAxios(
    {
      method: "GET",
      url: `/global/constants`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [
    { data: userData, loading: userLoading, error: userError },
    fetchUser,
  ] = useAxios(
    {
      method: "GET",
      url: `/account/user`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [
    { data: publishData, loading: publishLoading, error: publishErrors },
    publishSite,
  ] = useAxios(
    {
      method: "POST",
      url: `/site/publish`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        var url = await getCurrentUrl();
        fetchConstants();
        if (url) {
          fetchSiteSettings({ data: { url: url } });
          setUrl(url);
        }

        fetchUser();

        let interval = setInterval(() => {
          fetchUser();
        }, 1000 * 60 * 5)
        setUserInterval(interval);
      }
    })();
  }, [isLoggedIn]);

  useEffect(() => {
    if (userError) {
      if (userInterval) {
        clearInterval(userInterval);
      }
      logout();
    }
    if (userData) {
      setUser({ name: userData.name, surname: userData.surname });
    }
  }, [userData, userError]);


  useEffect(() => {
    if (siteSettings) {
      setSiteID(siteSettings.site_id)
    }
  }, [siteSettings]);

  useEffect(() => {
    setScripterApp(!!localStorage.getItem("scripter"))
  }, [])


  useEffect(() => {
    if (scripterApp) {
      localStorage.setItem("scripter", scripterApp)
    } else {
      localStorage.removeItem("scripter")
    }
  }, [scripterApp])

  useEffect(() => {
    if (constantsRaw) {
      let formatedConstants = {};
      Object.keys(constantsRaw).forEach((constant) => {
        let arrData = [];
        if (isMultipleProperties(constant)) {
          arrData = constantsRaw[constant].map((option) => {
            return { ...option, value: option.name?.toUpperCase() };
          });
        } else {
          arrData = constantsRaw[constant].map((option) => {
            return { value: option?.toUpperCase() };
          });
        }

        formatedConstants[constant] = arrData;
      });
      setConstants(formatedConstants);
    }
  }, [constantsRaw]);

  return (
    <GlobalContext.Provider value={{ scripterApp, setScripterApp, url, siteSettings, constants, user, siteID, fetchSiteSettings, errorsSiteSettings, publishData, publishLoading, publishErrors, publishSite }}>
      {children}
    </GlobalContext.Provider>
  );
}

function useGlobal() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error(`useGlobal must be used within a GlobalProvider`);
  }
  return context;
}

export { GlobalProvider, useGlobal };
