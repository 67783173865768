import React, { useEffect, useState } from "react";
import { Row, Form, Col } from "antd";
import useAxios from "axios-hooks";
import PatternForm from "./PatternForm/PatternForm";
import { useGlobal } from "GlobalContext/GlobalContext";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { useCodefix } from "Main/Context/Codefix/CodefixContext";
import { Spin, Space } from "antd";
import { KEYS } from "./PatternForm/consts";
import { StyledContainer } from "./CreatePattern";
import { navigate } from "@reach/router";
import { submitPattern } from "./PatternService";
import { toastError, toastSuccess } from "Shared/utils";
import { colors } from "Shared/colors";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";

const EditPattern = ({ patternId, setCollapseColor, isMobile, setSideBarCollaped, sendSocketMessage }) => {
  setCollapseColor(colors.DARK90);
  const { siteSettings } = useGlobal();
  const { updatePatternTree } = useTree();
  const [form] = Form.useForm();
  const { setPatternProperties,setPatternId, patternRaw, patternLoading, PatternErrors } = useCodefix();
  const [patternData, setPatternData] = useState(null);
  const [displayForm] = Form.useForm();
  const [displayData, setDisplayData] = useState({});
  const [allowGrouping, setAllowGrouping] = useState(false);


  const [
    { data: updatedDataResult, loading: updateLoading, error: updateError },
    updatePattern,
  ] = useAxios(
    {
      method: "PUT",
      url: `/patterns`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (patternId) {
      setPatternId(patternId);
    }
  }, [patternId]);

  useEffect(() => {
    return () => {
      setPatternData(null);
    }
  }, [])

  useEffect(() => {
    if (patternRaw) {
      initPattern();
    }
  }, [patternRaw]);

  useEffect(() => {
    let errors = null;

    if (PatternErrors) {
      errors =
        PatternErrors &&
        PatternErrors.response &&
        PatternErrors.response.data &&
        PatternErrors.response.data.Errors;
    }

    if (updateError) {
      errors =
        updateError &&
        updateError.response &&
        updateError.response.data &&
        updateError.response.data.Errors;
    }

    errors && toastError(errors);
  }, [PatternErrors, updateError]);

  useEffect(() => {
    if (updatedDataResult) {
      const values = form.getFieldsValue();
      toastSuccess(`Pattern was saved successfully`);
      setPatternProperties(values);
      navigate(`/main/${patternId}/codefix`);
      updatePatternTree();
      sendSocketMessage({action: "edit", type: "pattern", id: patternId, name: values.patternName});
    }
  }, [updatedDataResult]);

  const initPattern = () => {
    let data = {
      [KEYS.PATTERN_NAME]: patternRaw.name,
      [KEYS.SELECTOR]: patternRaw.selector,
      [KEYS.PARENT]: patternRaw[KEYS.PARENT],
      [KEYS.HIERARCHY]: patternRaw.hierarchy.map((h) => h?.toUpperCase()),
      [KEYS.TYPE]: patternRaw.type && patternRaw.type?.toUpperCase(),
      [KEYS.SUPPORTED_DEVICES]: patternRaw.supported_devices.map((d) =>
        d?.toUpperCase()
      ),
      [KEYS.SPECIAL_PROPERTIES]: patternRaw.special_properties.map((p) =>
        p?.toUpperCase()
      ),
      [KEYS.DISPLAY_NAME]:
        patternRaw[KEYS.DISPLAY_NAME_SELECTOR] ||
        (patternRaw[KEYS.DISPLAY_NAME] &&
          patternRaw[KEYS.DISPLAY_NAME].map((d) => d.lang_code)),
      id: patternId,
    };

    !data[KEYS.DISPLAY_NAME] && delete data[KEYS.DISPLAY_NAME];
    
    form.setFieldsValue(data);
    data.group = patternRaw.group;
    setPatternData(data);

    let displayValues = {};
    let ids = {};
    if (patternRaw[KEYS.DISPLAY_NAME] && patternRaw[KEYS.DISPLAY_NAME].length) {
      patternRaw[KEYS.DISPLAY_NAME].forEach((d) => {
        displayValues[d.lang_code] = d.text;
        ids.patternId = d.pattern_id;
        ids.textId = d.text_id;
      });
      displayForm.setFieldsValue(displayValues);
      displayValues = {...displayValues, ...ids};
    }

    if (patternRaw[KEYS.DISPLAY_NAME_SELECTOR]) {
      displayValues[KEYS.DISPLAY_NAME_SELECTOR] = patternRaw[KEYS.DISPLAY_NAME_SELECTOR];
      displayForm.setFieldsValue(displayValues);
    }

    setDisplayData(displayValues);
  };

  return (
    <div id="edit-pattern">
      <StyledContainer>
        {patternLoading && (
          <Row style={{ height: "70%" }} align="middle" justify="center">
            <Col>
              <Space size="middle">
                <Spin size="large" tip="Loading..." />
              </Space>
            </Col>
          </Row>
        )}
        {patternData && !patternLoading && (
          <PatternForm
            submitLoading={updateLoading}
            displayForm={displayForm}
            edit={patternData}
            editRaw={patternRaw}
            form={form}
            onSubmit={(event, displayNameType) =>
              submitPattern(
                event,
                displayForm,
                form,
                updatePattern,
                displayNameType,
                patternData,
                displayData,
                allowGrouping
              )
            }
            setAllowGrouping={setAllowGrouping}
            allowGrouping={allowGrouping}
            patternId={patternId}
          />
        )}
      </StyledContainer>
    </div>
  );
};

export default EditPattern;
