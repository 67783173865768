import React, { useState, useEffect } from 'react'
import { Form, Input, Typography } from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import { useCodefix } from 'Main/Context/Codefix/CodefixContext';
import { selectorValidator } from '../Selector/Selector';

const { Text } = Typography;
const STATE_TRUE = "state_true";
const STATE_FALSE = "state_false";

/*behaviors with type=state in codefix.yaml file*/
const StateBehaviors = ({ form, codefixEditData, selectedOption, patternId }) => {
    const { selector, selectorErrors } = useCodefix();
    const [newErrors, setNewErrors] = useState(null);
    const values = selectedOption && data[selectedOption.key];

    useEffect(() => {
        if (codefixEditData) {
            form.setFieldsValue({
                [STATE_TRUE]: codefixEditData.data[STATE_TRUE],
                [STATE_FALSE]: codefixEditData.data[STATE_FALSE]
            });
        }
    }, [codefixEditData])

    useEffect(() => {
        if (selector && !codefixEditData) {
            form.setFieldsValue({
                [STATE_TRUE]: selector
            });
        }
    }, [selector])

    return (
        <>
            <div>
                <TooltipLabel required value={`${values[0]?.toUpperCase()} SELECTOR`} tooltip={{ text: "fill later" }} />
                <Form.Item name={STATE_TRUE}>
                    <Input disabled="true" />
                </Form.Item>
            </div>
            <div>
                <TooltipLabel required value={`${values[1]?.toUpperCase()} SELECTOR`} tooltip={{ text: "fill later" }} />
                <Form.Item
                    style={{ marginBottom: newErrors && newErrors.length ? 0 : 20 }}
                    name={STATE_FALSE}
                    hasFeedback
                    validateTrigger={['onBlur']}
                    validateStatus={newErrors === null ? null : newErrors.length ? "error" : "success"}
                    rules={[
                        {
                            validator: async (rule, selector) => {
                                try {
                                    await selectorValidator(selector, patternId)
                                        .then(() => {
                                            const fieldsValues = form.getFieldsValue();
                                            const stateTrueSelector = fieldsValues[STATE_TRUE];
                                            const stateFalseSelector = fieldsValues[STATE_FALSE];
                                            if (stateTrueSelector === stateFalseSelector) {
                                                var result = `Selector collision, ${values[0]?.toUpperCase()} SELECTOR cannot be the same as ${values[1]?.toUpperCase()} SELECTOR`;
                                                setNewErrors(result);
                                                return Promise.reject(result);
                                            }
                                            else {
                                                setNewErrors([]);
                                                return Promise.resolve();
                                            }
                                        }).catch((result) => {
                                            setNewErrors(result);
                                            return Promise.reject(result);
                                        });
                                }
                                catch (err) {
                                    return Promise.reject(err.response && err.response.data && err.response.data.Errors || err);
                                }
                            }
                        }
                    ]}
                >
                    <Input placeholder="FILL THE SELECTOR" />
                </Form.Item>
                {/* {newErrors && newErrors.length ? <Text type="danger">{newErrors[0]}</Text> : null} */}
            </div>
        </>
    )
}

const data = {
    selected: ["selected", "unselected"],
    disabled: ["disabled", "enabled"],
    expanded: ["expanded", "collapsed"],
    checked: ["checked", "unchecked"]
}

export const getStateData = (form) => {
    let value = form.getFieldsValue([STATE_TRUE, STATE_FALSE]);
    return value;
}

export default StateBehaviors;