import React, {useState, useEffect} from 'react';
import { Select, Form, Checkbox, Modal, Button  } from 'antd';
import {useCodefix} from 'Main/Context/Codefix/CodefixContext';
import ExecuteScript from 'Main/Views/ExecuteScript/ExecuteScript';
import styled from "styled-components";
import {useMenuWidget} from 'Main/Context/MenuWidget/MenuWidgetContext';
import {
    DownCircleOutlined,
    UpCircleOutlined
} from "@ant-design/icons";
import { colors } from "Shared/colors";
import { sendMessage } from "Shared/messages";

const ScriptModal = styled(Modal)`
    top: 0px;
    overflow: hidden;
    .ant-modal-body{
        padding: 0;
        height:100vh;
        background: #f0f2f5;
    }
`
const ButtonsContainer = styled.div`
    padding-left: 3.9rem;
    padding-top: 1rem;
`
const SaveButton = styled(Button)`
    font-weight: 600;
    height: 2.5rem;
    border: none;
    border-radius: 0;
    background: #111 !important;
    color: white;
    width: 7rem;
`

const CancelButton = styled(Button)`
    background: none !important;
    border: none !important;
`

const MinimizeButton = styled(Button)`
  background: ${colors.DARK100}!important;
  color: white !important;
  border: 0 !important;
  top: 5px;
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline;
  position: absolute;
  right: 10px;
`;

const MenuScript = ({patternId}) => {
    const { menuScriptModal, setMenuScriptModal } = useCodefix();
    const [menuScriptEditorValue, setMenuScriptEditorValue] = useState(null);
    const {menuScriptsData, setScripts, selectedScriptId, setSelectedScriptId, clearAllStates} = useMenuWidget();
    const [isMinimized, setMinimized] = useState(false);

    useEffect(() => {
        if(selectedScriptId && menuScriptEditorValue){
            let script = menuScriptEditorValue.replace("var init: Init = ", "");
            setScripts({...menuScriptsData, [selectedScriptId]: script})
        }
    }, [menuScriptEditorValue, selectedScriptId])

    const onSave = () => {
        setMenuScriptModal(false);
    }

    const toggleSize = () => {
        let newToggleState = !isMinimized;
        setMinimized(newToggleState);
        sendMessage({ type: "minimize", data: newToggleState }, "*");
    };

    const onCancel = () => {
        //setSelectedScriptId(null);
        // delete menuScriptsData[selectedScriptId];
        // setScripts(menuScriptsData);
        setMenuScriptModal(false);
    }

    return (
        <>
            <ScriptModal
                width="100%"
                height="100%"
                visible={menuScriptModal}
                footer={null}
                closable={false}
            >
                <div>
                    <MinimizeButton 
                        onClick={toggleSize}
                        icon={isMinimized ? <DownCircleOutlined /> : <UpCircleOutlined />}
                    ></MinimizeButton>
                    <div id="scriptEditor">
                        <ExecuteScript menuScriptsData={menuScriptsData && menuScriptsData[selectedScriptId]} setMenuScriptData={setScripts} setMenuScriptEditorValue={setMenuScriptEditorValue} scriptType="menuScript" patternId={patternId} />
                    </div>
                    <MenuScriptModalFooter onSave={onSave} onCancel={onCancel} menuScriptEditorValue={menuScriptEditorValue}/>
                </div>
            </ScriptModal>
        </>
    )
}

const MenuScriptModalFooter = ({onCancel, onSave}) => {
    return (
        <ButtonsContainer>
            <SaveButton onClick={onSave}>SAVE</SaveButton>
            <CancelButton onClick={onCancel}>CANCEL</CancelButton>
        </ButtonsContainer>
    )
}


export default MenuScript;