import React, { useState, useEffect } from 'react'
import { Form, Select, Input } from 'antd';
import { resetFields } from 'Shared/formService';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';

const SINGLE = "single";

/*behaviors with type=single in codefix.yaml file*/
const SingleBehaviors = ({ form, codefixEditData, selectedOption }) => {

    useEffect(() => {
        if (selectedOption) {
            resetFields(form, [SINGLE])
        }
    }, [selectedOption])

    useEffect(() => {
        if (codefixEditData) {
            let data = codefixEditData.data?.value ?? "";
            data = Array.isArray(data) ? data.map(v => v?.toUpperCase()) : data?.toUpperCase();
            form.setFieldsValue({
                [SINGLE]: data
            });
        }
    }, [codefixEditData])

    let option = selectedOption && data[selectedOption.key];

    return (
        option
            ?
            <ComboboxField selectedOption={selectedOption} option={option} />
            :
            <>
                <TooltipLabel required value="VALUE" tooltip={{ text: "fill later" }} />
                <Form.Item name={SINGLE} validateTrigger={['onBlur']} rules={[{ required: true, message: `required field` }]}>
                    <Input placeholder={`FILL ${selectedOption && selectedOption.name?.toUpperCase()}`} />
                </Form.Item>
            </>
    )
}

const ComboboxField = ({ option, selectedOption }) => {
    const isMultipleChoices = selectedOption.key === "removeAttribute";
    return (
        <>
            <TooltipLabel required value="VALUE" tooltip={{ text: "fill later" }} />
            <Form.Item name={SINGLE} validateTrigger={['onBlur']} rules={[{ required: true, message: `required field` }]}>
                {
                    isMultipleChoices
                        ?
                        <Select
                            placeholder={`CHOOSE ${selectedOption && selectedOption.name?.toUpperCase()}`}
                            mode="multiple"
                            options={option}
                        />
                        :
                        <Select
                            placeholder={`CHOOSE ${selectedOption && selectedOption.name?.toUpperCase()}`}
                            options={option}
                        />
                }
            </Form.Item>
        </>
    )
}

const data = {
    live: [{ value: "POLITE" }, { value: "ASSERTIVE" }],
    autocomplete: [{ value: "INLINE" }, { value: "LIST" }, { value: "BOTH" }],
    tagReplacement: [{ value: "H1" }, { value: "H2" }, { value: "H3" }, { value: "H4" }, { value: "H5" }, { value: "H6" }, { value: "LABEL" }, { value: "DIV" }, { value: "SPAN" }, { value: "STRONG" }],
    headingLevel: [{ value: "1" }, { value: "2" }, { value: "3" }, { value: "4" }, { value: "5" }, { value: "6" }],
    removeAttribute: [
        { value: "ROLE" }, 
        { value: "TITLE" }, 
        { value: "ARIA-LABEL" }, 
        { value: "ARIA-LIVE" }, 
        { value: "ARIA-ATOMIC" }, 
        { value: "ARIA-HIDDEN" }, 
        { value: "ARIA-DESCRIBEDBY" }, 
        { value: "ARIA-LABELLEDBY" }, 
        { value: "ARIA-HASPOPUP" }, 
        { value: "ARIA-EXPANDED" },
        { value: "ARIA-SELECTED" },
        { value: "ARIA-CONTROLS" },
        { value: "ARIA-CHECKED" }
    ],
    ariaHidden: [{ value: "TRUE" }, { value: "FALSE" }],
}

export const getSingleData = (form) => {
    let value = form.getFieldValue(SINGLE);
    if (value && Array.isArray(value)) {
        value = value.map(v => v?.toLowerCase());
    }
    else {
        value = value && value?.toLowerCase();
    }
    return { value };
}

export default SingleBehaviors;