import React, {useState, useEffect} from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import {useGlobal, SITE_SETTINGS_CONSTS} from 'GlobalContext/GlobalContext';
import { TYPES, KEYS } from './consts';
import { colors } from 'Shared/colors';
import { useCodefix } from 'Main/Context/Codefix/CodefixContext';
import {resetFields} from 'Shared/formService';

const StyledButtonsTypes = styled(Button)`
    background: ${props => props.isActive ? colors.BLUE : colors.DARK90} !important;
    color: white !important;
    flex: 1;
    margin: 0;
    cursor: pointer;
    opacity:  ${props => props.disabled ? 0.5 : 1} !important;
    border: 0;
    border-radius: 0;
`;

const ActionButtons = ({form, activeCodefixType, onTypeClicked, isBodyPattern, codefixEditData}) => {
    const {constants} = useGlobal();
    const { setSelectedOption, isSelectorValid } = useCodefix();

    const onClick = (type, options) => {
        setSelectedOption(null);
        resetFields(form, [KEYS.FIXES]);
        onTypeClicked(type, options);
    }

    return (
        !codefixEditData 
        ?
            <div style={{display: 'flex', marginBottom: '1rem'}}>
                <StyledButtonsTypes disabled={isBodyPattern || !isSelectorValid} isActive={activeCodefixType === TYPES.ROLES ? true : false} onClick={() => onClick(TYPES.ROLES, constants[SITE_SETTINGS_CONSTS.ROLES])}>{TYPES.ROLES.toUpperCase()}</StyledButtonsTypes>
                <StyledButtonsTypes disabled={!isSelectorValid} isActive={activeCodefixType === TYPES.BEHAVIORS ? true : false} onClick={() => onClick(TYPES.BEHAVIORS, constants[SITE_SETTINGS_CONSTS.BEHAVIORS])}>{TYPES.BEHAVIORS.toUpperCase()}</StyledButtonsTypes>
                <StyledButtonsTypes disabled={isBodyPattern || !isSelectorValid}  isActive={activeCodefixType === TYPES.WIDGETS ? true : false} onClick={() => onClick(TYPES.WIDGETS, constants[SITE_SETTINGS_CONSTS.WIDGETS])}>{TYPES.WIDGETS.toUpperCase()}</StyledButtonsTypes>
                <StyledButtonsTypes disabled={isBodyPattern || !isSelectorValid}  isActive={activeCodefixType === TYPES.SCRIPT ? true : false} onClick={() => onClick(TYPES.SCRIPT, constants[SITE_SETTINGS_CONSTS.SCRIPT])}>{TYPES.SCRIPT.toUpperCase()}</StyledButtonsTypes>
            </div>
        :
            null
    )
}

export default ActionButtons;