import React, { useContext, createContext, useState } from "react";
import { IDE_ACTIONS, MESSAGES, PRODUCT, STORAGE_NAMES } from "Scripter/Views/consts";
import { sendMessage } from "Shared/messages";
import { Storage } from "Shared/Services/storage";

const SwitchContext = createContext();

function SwitchProvider({ children }) {
    const [isMinimized, setMinimized] = useState(false);
    const [ideVisible, setIdeVisibility] = useState(false);
    // const [selectedProd, setProduct] = useState(PRODUCT.TOOLBAR);
    const [activeAddress, setActiveAddress] = useState(null);
    const [activeProd, setActiveProd] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [ideAction, setIdeAction] = useState(IDE_ACTIONS.PREVIEW);

    const changeProduct = (prod) => {
        const prodName = prod?.toLowerCase();
        setActiveProd(prod);
        setIsDropdownVisible(false);
        (prodName == PRODUCT.TOOLBAR || prodName == PRODUCT.FRONTEND) ? setIdeVisibility(false) : setIdeVisibility(true);
        Storage.SetItem(STORAGE_NAMES.PRODUCT, prodName);
        sendMessage({ type: MESSAGES.PRODUCT, data: prodName, isIdeVisible: prodName == PRODUCT.IDE, isMenuVisible: prodName == PRODUCT.FRONTEND});

        const isMinimized = prodName == PRODUCT.FRONTEND ? true : false;
        sendMessage({ type: "minimize", data: isMinimized });
        setMinimized(isMinimized);
    };

    const changeAddress = (address) => {
        setIsDropdownVisible(false);
        Storage.SetItem(STORAGE_NAMES.ADDRESS, address);
        sendMessage({ type: MESSAGES.ADDRESS, data: address });
    };

    const toggleSize = () => {
        if (activeProd != PRODUCT.FRONTEND) {
            setMinimized(!isMinimized);
            sendMessage({ type: "minimize", data: !isMinimized });
        }
    };

    return (
        <SwitchContext.Provider
            value={
                {
                    isMinimized,
                    setMinimized,
                    ideVisible,
                    setIdeVisibility,
                    // selectedProd,
                    // setProduct,
                    activeAddress,
                    setActiveAddress,
                    activeProd,
                    setActiveProd,
                    isDropdownVisible,
                    setIsDropdownVisible,
                    ideAction,
                    setIdeAction,
                    changeProduct,
                    changeAddress,
                    toggleSize
                }
            }>
            {children}
        </SwitchContext.Provider>
    );
}

function useSwitch() {
    const context = useContext(SwitchContext);
    if (context === undefined) {
        throw new Error(`useSwitch must be used within a TreeContext`);
    }
    return context;
}

export { SwitchProvider, useSwitch };
