import React, { useState, useEffect } from 'react'
import { Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { TYPES } from '../consts';
import { OPTIONS } from '../../Patterns/PatternForm/consts';

const { Option, OptGroup } = Select;

const constructOptions = (activeCodefixType, searchedOptions, patternProperties, selector) => {
    switch (activeCodefixType) {
        case TYPES.ROLES:
            return searchedOptions.map((option, i) => <Option key={option.value + i} value={option.value}>{option.value}</Option>)
        case TYPES.BEHAVIORS:
            return constructBehaviorsOptions(searchedOptions, patternProperties, selector);
        case TYPES.WIDGETS:
            return searchedOptions.map((option, i) => {
                const isDisabled = IsDisabledOptionForWidgetMenu(option, patternProperties, selector);
                return (
                    <Option disabled={isDisabled} key={option.key} value={option.value}>{option.value}</Option>
                )
            })
        default:
            return null;
    }
}

const IsDisabledOptionForWidgetMenu = (option, patternProperties, selector) => {
    let isDisabled = false;
    switch (option.key) {
        case "menu":
            isDisabled = patternProperties && patternProperties.type !== OPTIONS.SECTION && patternProperties.type !== OPTIONS.SITE_NAVIGATION;
            break;
        case "formValidation":
            isDisabled = selector !== ".";
            break;
        default:
            break;
    }
    return isDisabled;
}

const constructBehaviorsOptions = (currentOptionsList, patternProperties, selector) => {
    let categories = {};

    currentOptionsList.forEach((option) => {
        if (!categories[option.category]) {
            categories[option.category] = [option];
        }
        else {
            categories[option.category].push(option);
        }
    });

    const entries = Object.entries(categories);
    return (
        entries.map((cat) => {
            return (
                <OptGroup key={cat[0]} label={cat[0]?.toUpperCase()} style={{color: "#666"}}>
                    {
                        cat[1].map((option, index) => {
                            option = handleOnlyLogic(option, patternProperties, selector);
                            const profiles = option.profiles && option.profiles.join(" ");
                            return (
                                <Option disabled={option.disabled} name={option.name} type={option.type} key={option.key} fullData={option} category={cat[0]} value={`${option.value} ${option.description} ${profiles}`}>
                                    <div style={{ display: 'flex' }} >
                                        <span style={{ flex: 1, fontWeight: 700 }}>{option.value}</span>
                                        <Tooltip color="black" title={<TooltipContent description={option.description} link={option.link} />}>
                                            <span style={{ textAlign: 'end' }}><QuestionCircleOutlined /></span>
                                        </Tooltip>
                                    </div>
                                    <span style={{ color: '#666', fontSize: 12 }}>{option.profiles && option.profiles.join(" | ")}</span>
                                </Option>
                            )
                        })
                    }
                </OptGroup>
            )
        })
    )
}

const handleOnlyLogic = (option, patternProperties, selector) => {
    if (option.only && option.only.length > 0) {
        option.disabled = true;
    }

    if (patternProperties && option.only && !!option.only.length) {
        const isDynamic = patternProperties.special_Properties.includes(OPTIONS.DYNAMIC) || patternProperties.special_Properties.includes(OPTIONS.DYNAMIC_DEPRECATED);
        const isSection = selector === ".";
        const isLazyLoading = patternProperties.special_Properties.includes(OPTIONS.LAZY_LOADING);
        const isDialog = patternProperties.type === OPTIONS.DIALOG;
        let isDisabled = true;

        for (let i = 0; i < option.only.length; i++) {
            let only = option.only[i];
            if ((only.includes("dynamic") && isDynamic) ||
                (only.includes("section") && isSection) ||
                (only.includes("lazy") && isLazyLoading) ||
                (only.includes("dialog") && isDialog)) {
                isDisabled = false;
            }
            else {
                isDisabled = true;
                break;
            }
        }
        option.disabled = isDisabled;
    }
    return option;
}

const TooltipContent = ({ description, link }) => {
    return (
        <div>
            <p>{description}</p>
            {link && <a style={{ color: "#3d91b3", cursor: 'pointer', textDecoration: 'underline' }} href={link} target="_blank">Learn more in Wiki</a>}
        </div>
    )
}

export {
    constructOptions,
    constructBehaviorsOptions,
    TooltipContent
}