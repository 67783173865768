import React, {useEffect, useState} from 'react';
import { Modal, Button, Collapse  } from 'antd';
import {DownCircleOutlined, UpCircleOutlined} from '@ant-design/icons';
import styled from "styled-components";
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import Moment from 'react-moment';
import 'moment-timezone';
import {colors} from 'Shared/colors';
import {useTree} from 'Main/Context/TreeContext/TreeContext';
import {ellipsis} from 'Shared/utils';

const { Panel } = Collapse;

const ModalStyle = styled(Modal)`
    .ant-modal-body{
        height: 75vh !important;
    }

    .ant-modal-content{
        border-radius: 10px;
    }
`

const Container = styled.div`
    display: grid;
    grid-template-rows: 0.3fr 300px 35px 60px;
    background: white;
`

const HeadingLevel1 = styled.h1`
    font-size: 18px;
    color: black;
`

const StyledWarning = styled.p`
    font-size: 14px;
    text-align: center;
    color: RED;
`

const StyledCollapse = styled(Collapse)`
    border: none !important;
    background: white;
    .ant-collapse-header{
        background: white;
        color: #424242;
        border: 1px solid #bfbfbf;
        border-radius: 6px;
        padding: 8px 31px !important;
        font-size: 12px !important;
    }

    :not(.ant-collapse-item-active) .ant-collapse-header{
        border-radius: 6px !important;
    }

    .ant-collapse-item-active .ant-collapse-header{
        background: ${colors.BLUE} !important;
        color: white !important;
        border-radius: 10px 10px 0px 0px !important;
    }

    .ant-collapse-item{
        border: none !important;
        margin: 10px !important;
    }

    .ant-collapse-content{
        border: 1px solid #bfbfbf !important;
        border-top: none !important; 
        border-radius: 0px 0px 6px 6px !important;
    }

    .ant-collapse-content-box{
        max-height: 130px;
        overflow-x: auto;
    }
`

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: center;
`

const StyledOkButton = styled(Button)`
    margin: 10px;
    color: white !important;
    background: ${colors.BLUE} !important;
    border-radius: 5px;
`

const StyledCancelButton = styled(Button)`
    margin: 10px;
    color: black;
    border: none  !important;
    box-shadow: none;
`
const Seperator = styled.hr`
    opacity: 0.2;
`

const ChangeDetectionModal = ({changeDetectionModal, setChangeDetectionModal}) => {
        const [dictionaryChangesData, setDictionaryChangeData] = useState(null);
        const {getChildrenById} = useTree();

        const [{ data: changesData, loading: loading, error: changesError }, fetchGetChanges] = useAxios(
            {
                method: "POST",
                url: `/journal`,
                headers: {
                    "content-type": "application/json",
                },
            },
            { manual: true }
        );

        useEffect(() => {
            (async () => {
                const url = await getCurrentUrl();
                const children = getChildrenById(changeDetectionModal.id);
                fetchGetChanges({data: {url, id: changeDetectionModal.id, children}});
            })();
        }, []);

        useEffect(() => {
            if(changesData && changesData.length > 0){
                let data = {};
                changesData.forEach(item => {
                    if(data[item.user_name]){
                        data[item.user_name].push(item);
                    }
                    else{
                    data[item.user_name] = [item];
                    }
                });
                setDictionaryChangeData(data);
            }
            else if(changesData && changesData.length === 0){
                execute();
            }
        }, [changesData])

        const execute = () => {
            if(changeDetectionModal.action === "delete"){
                changeDetectionModal.executionFunc();
            }
            else if(changeDetectionModal.action === "edit"){
                const displayNameType = changeDetectionModal.params.displayNameType;
                const event = changeDetectionModal.params.event;
                changeDetectionModal.executionFunc(event, displayNameType);
            }
            setChangeDetectionModal(null);
        }

        return (
        <>
            {
                changesData &&
                <ModalStyle
                    width={'60%'}
                    visible={changeDetectionModal}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => setChangeDetectionModal(null)}
                    wrapClassName="change-detection-modal"
                >
                    <Container>
                        <div>
                            <HeadingLevel1>
                                <p style={{margin: 0}}>
                                    YOU'RE ABOUT TO {changeDetectionModal.action?.toUpperCase()} PATTERN
                                </p>
                                <p style={{margin: 0, color: '#333', fontWeight: 200, fontSize: 14}}>
                                    {changeDetectionModal.name}
                                </p>
                            </HeadingLevel1>
                            
                            <Seperator style={{opacity: 0.2}}/>
                        </div>
                        <div style={{
                            maxHeight: 290,
                            overflowX: 'auto'
                        }}>
                            <StyledCollapse 
                                accordion 
                                expandIconPosition="right"
                                expandIcon={propName => 
                                    propName.isActive
                                    ?
                                        <UpCircleOutlined style={{fontSize: 16, color: 'white'}} />
                                    :
                                        <DownCircleOutlined style={{fontSize: 16, color: '#333'}}/>
                                }
                            >
                            {
                                dictionaryChangesData && Object.entries(dictionaryChangesData).map(([userName,items], i) => {
                                        return (
                                                <Panel key={`${userName}-${i}`} header={`VIEW ${userName?.toUpperCase()} CHANGES`}>
                                                    {
                                                        items && items.map((item, index) => {
                                                            const isCodefix = item.entity === "Codefix";
                                                            const isScript = item.entity === "Script";
                                                            const data = item.data && JSON.parse(item.data);
                                                            const displayCodefixPatternName = isCodefix ? `in pattern '${ellipsis(item.pattern_name, 20)}'`: "";
                                                            const name = isScript && data ? ellipsis(data.Name, 40) : ellipsis(item.name, 40);
                                                            return (
                                                                <div style={{display: 'flex'}}>
                                                                    <span 
                                                                        style={{flex: 1,margin: 5, fontWeight: 700}}
                                                                    >
                                                                        {item.action} {item.entity} '{name}' {displayCodefixPatternName}</span>
                                                                    <span style={{margin: 5, color: 'rgb(158 158 158)'}}>
                                                                        <Moment utc local fromNow>{item.date}</Moment>
                                                                    </span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Panel> 
                                        )
                                    })
                            }
                            </StyledCollapse>
                        </div>
                        <StyledWarning><Seperator />THIS MIGHT AFFECT CHANGES OTHERS DID</StyledWarning>
                        <ButtonsContainer>
                            <StyledOkButton onClick={execute}>{changeDetectionModal.action?.toUpperCase()}</StyledOkButton>
                            <StyledCancelButton onClick={() => setChangeDetectionModal(null)}>CANCEL</StyledCancelButton>
                        </ButtonsContainer>
                    </Container>
                </ModalStyle>
            }
            
        </>
        )
}

export default ChangeDetectionModal;