import React, { useState, useEffect } from 'react'
import { Form, Input, Typography } from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import { multipleSelectorValidator, absoluteSelectorValidator } from '../../Selector/Selector';
import { resetFields } from 'Shared/formService';
import styled from "styled-components";

const { Text } = Typography;

const Container = styled.div` 
display: ${props => props.visible};
`

/*behaviors with type=selector in codefix.yaml file*/
const SelectorWidget = ({ patternId, state, setState }) => {

    const selectorValidatorType = {
        absolute: absoluteSelectorValidator,
        relative: multipleSelectorValidator
    }

    return (
        state ? <Container visible={state.visible ? "block" : "none"}>
            <TooltipLabel required={state.required} value={`${state.label?.toUpperCase()} SELECTOR`} tooltip={{ text: state.tooltip }} />
            <Form.Item
                name={state.name}
                hasFeedback
                validateTrigger={['onBlur']}
                validateStatus={state.selectorErrors === null ? null : state.selectorErrors.length ? "error" : "success"}
                rules={[
                    {
                        validator: async (rule, selector) => {
                            if (state.required || (selector && selector.length)) {
                                if (state.name === 'calendar') {
                                    selector = selector.replace(';', ',');
                                }
                                try {
                                    await selectorValidatorType[state.selectorType](selector, patternId, state.label);
                                    setState({ ...state, selectorErrors: [] });
                                }
                                catch (err) {
                                    setState({ ...state, selectorErrors: err });
                                    throw new Error(err);
                                }
                            }
                            else {
                                setState({ ...state, selectorErrors: null });
                            }
                        }
                    }]}
            >
                <Input placeholder="FILL THE SELECTOR" />
            </Form.Item>
        </Container> : null
    )
}


export default SelectorWidget;


