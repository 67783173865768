import React, {useState, useEffect} from 'react';
import { Tabs, Switch, Select, Form } from 'antd';
import MenuItems from './MenuItems/Items';
import MenuSelector from './MenuSelector';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import styled from "styled-components";
import SecondLevelContent from './SecondLevel';
import { colors } from 'Shared/colors';
import {extractFirstLevelData, extractSecondLevelData} from './MenuService';
import MenuScript from './menuScript';
import {useMenuWidget} from 'Main/Context/MenuWidget/MenuWidgetContext';
import {useCodefix} from 'Main/Context/Codefix/CodefixContext';
import {KEYS} from './consts';
import {extractDirection} from './MenuService';

const { TabPane } = Tabs;

const Container = styled.div`
    max-height: 295px;
    overflow-y: auto;
    padding-right: 10px;
    ::-webkit-scrollbar {
        width: 0.5rem;
      }
  
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
  
      ::-webkit-scrollbar-thumb {
        background-color: #5977AD !important;
        outline: 1px solid slategrey;
        border-radius: 20px;
      }
`;

const StyledTabs = styled(Tabs)`
    .ant-tabs-nav .ant-tabs-tab-active {
        background: ${colors.BLUE};
        color: white;
        border-color: ${colors.BLUE};
    }

    .ant-tabs-tab-remove svg {
        color: ${colors.BLUE} !important;
        background: white;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        padding: 2px;
        margin-left: 5px;
    }

    .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
        background: black;
        color: white;
        .ant-switch-inner {
            color: black !important;
        }
        .ant-switch-handle::before{
            background: black !important;
        }
        .ant-tabs-tab-remove svg {
            color: black !important;
        }
    }

    .ant-tabs-tab{
        border-radius: 5px !important;
        border: none !important;
        padding: 16px !important;
        font-size: .9rem;
        font-weight: bold;
        letter-spacing: 0.5px;
        height: 1.6rem;

        > div{
            display: flex;
            height: 2.1rem;
            span:nth-child(1){
                align-self: center;
                margin-right: 10px;
            }
        }


        .ant-switch{
            width: 100px;
            border-radius: 5px;
            border: 0 !important; 

            *{
                color: ${colors.BLUE} !important;
                font-size: .6rem;
                font-weight: 600;
            }

            .ant-switch-handle{
                height: 12px;
                width: 12px;
                top: 5px;
            }
    
            .ant-switch-inner{
                margin: 0 1rem;
            }
    
            .ant-switch-handle::before{
                background: ${colors.BLUE};
            }
        }
    }

    .ant-tabs-nav-add{
        border-radius: 5px !important;
        background: black !important;
        color: white !important;
        
        .ant-tabs-tab-remove svg{
            width: 15px;
            height: 15px;
        }
    }
`;

const FirstLevel = ({form, patternId, codefixEditData}) => {
    return (
        <Form form={form}>
            <MenuItems form={form} codefixEditData={codefixEditData} name="firstLevel" patternId={patternId}/>
        </Form>
    )
}

const MenuWidget = ({codefixEditData, patternId, menuLevel0Form, menuLevel1Form, setPatternSelectorSelected, form}) => {
    const initialPanes = [{ title: 'LEVEL 0', content: <FirstLevel codefixEditData={codefixEditData} form={menuLevel0Form} patternId={patternId} />, key: 'firstLevel', closable: false }];
    const [activeKey, setActiveKey] = useState(initialPanes[0].key); 
    const [panes, setPanes] = useState(initialPanes);
    const {menuScriptsData, setScripts, selectedScriptId, clearAllStates} = useMenuWidget();
    const { menuScriptModal } = useCodefix();

    useEffect(() => {
        if(codefixEditData){
            const firstLevel = codefixEditData.data.firstLevel;
            const secondLevel = codefixEditData.data.secondLevel;
            const direction = extractDirection(codefixEditData.data);
            form.setFieldsValue({[`${KEYS.DIRECTION}_firstLevel`]: direction.firstLevel});
            
            if(secondLevel.length > 0){
                addTab();
                form.setFieldsValue({[`${KEYS.DIRECTION}_secondLevel`]: direction.secondLevel});
            }
        }
    }, [codefixEditData])

    useEffect(() => {
        return () => {
            clearAllStates();
            menuLevel0Form.resetFields();
            menuLevel1Form.resetFields();
        }
    }, [])

    useEffect(() => {
        setPatternSelectorSelected(true);
        return () => {
            setPatternSelectorSelected(false);
        }
    }, [])

    const onChange = activeKey => {
        setActiveKey(activeKey);
    };

    const onEdit = (targetKey, action) => {
        action === "add" && addTab();
        action === "remove" && removeTab();
    };

    const addTab = () => {
        if(panes.length === 1){
            setPanes([...panes, {title: 'LEVEL 1', content: <SecondLevelContent codefixEditData={codefixEditData} form={menuLevel1Form} patternId={patternId}/>, key: 'secondLevel', closable: true}])
        }
    }

    const removeTab = () => {
        if(panes.length > 1){
            setPanes(initialPanes);
        }
    }

    return (
        <>
            {
                menuScriptModal && <MenuScript patternId={patternId}/>
            }
            <StyledTabs
                type="editable-card"
                onChange={onChange}
                activeKey={activeKey}
                onEdit={onEdit}
                hideAdd={panes.length > 1}
            >
                {panes.map((pane, index) => (
                    <TabPane tab={<TabSwitcher pane={pane} />} key={pane.key} closable={pane.closable}>
                        <Container>
                            {pane.content}
                        </Container>
                    </TabPane>
                ))}
            </StyledTabs>
        </>
    );
}

const TabSwitcher = ({pane}) => {
    return (
        <div>
            <span>{pane.title} </span>
            <Form.Item valuePropName="checked" name={`${KEYS.DIRECTION}_${pane.key}`}> 
                <Switch style={{background: 'white', color: 'black'}} checkedChildren="VERTICAL" unCheckedChildren="HORIZONTAL" defaultChecked />
            </Form.Item>
        </div>
    )
}

export const getMenuData = (form, menuLevel0Form, menuLevel1Form, menuScriptsData, codefixEditData) => {
    const firstLevel = extractFirstLevelData(menuLevel0Form.getFieldsValue(), menuScriptsData, form);
    let secondLevel = extractSecondLevelData(menuLevel1Form.getFieldsValue(), menuScriptsData, form);
    if(!secondLevel.length){
        if(codefixEditData && codefixEditData.data.secondLevel.length > 0){
            secondLevel = codefixEditData.data.secondLevel
        }
    }
    return {firstLevel, secondLevel};
};

export default MenuWidget;