import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { Select, Alert, Form } from "antd";
import useAxios from "axios-hooks";
import styled from "styled-components";
import PatternForm from "./PatternForm/PatternForm";
import { useGlobal } from "GlobalContext/GlobalContext";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { useCodefix } from "Main/Context/Codefix/CodefixContext";
import { submitPattern } from "./PatternService";
import { toastError, toastSuccess } from "Shared/utils";
import { colors } from "Shared/colors";

const { Option } = Select;

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 87vh;
  margin: 3rem 1rem 1rem 1rem;
`;

const CreatePattern = ({setCollapseColor, isMobile, setSideBarCollaped, sendSocketMessage}) => {
    setCollapseColor(colors.DARK90);
    const { siteSettings } = useGlobal();
    const { updatePatternTree, setHighlightedItem, setSelectedItem } = useTree();
    const { setPatternProperties } = useCodefix();
    const [displayForm] = Form.useForm();
    const [form] = Form.useForm();
    const [allowGrouping, setAllowGrouping] = useState(false);
    
    const [{ data: createPatternData, loading, error: createPatternErrors }, fetchCreatePattern] = useAxios(
        {
          method: 'POST',
          url: `/patterns`,
          headers: {
            'content-type': 'application/json',
          }
        },
        { manual: true }
    )

    useEffect(() => {
        if(isMobile){
            setSideBarCollaped(true);
        }
    }, [])

    useEffect(() => {
        if(createPatternErrors){
            const errors = createPatternErrors.response && createPatternErrors.response.data && createPatternErrors.response.data.Errors;
            toastError( `Error while trying to create pattern. Msg: ${errors}`);
        }
    }, [])

  useEffect(() => {
    if (createPatternErrors) {
      const errors =
        createPatternErrors.response &&
        createPatternErrors.response.data &&
        createPatternErrors.response.data.Errors;
      toastError(`Error while trying to create pattern. Msg: ${errors}`);
    }

    if (createPatternData) {
      const values = form.getFieldsValue();
      toastSuccess(`Pattern was saved successfully`);
      setPatternProperties(values);
      navigate(`/main/${createPatternData.id}/codefix`);
      updatePatternTree();
      setSelectedItem({name: values.patternName});
      setHighlightedItem({key: createPatternData.id, name: values.patternName, mark: true});
      sendSocketMessage({action: "create", type: "pattern", id:  createPatternData.id, name: values.patternName}); 
    }
  }, [createPatternErrors, createPatternData]);

  return (
    <div id="create-pattern">
      <StyledContainer>
        <PatternForm
          submitLoading={loading}
          displayForm={displayForm}
          form={form}
          onSubmit={(event, displayNameType) =>
            submitPattern(
              event,
              displayForm,
              form,
              fetchCreatePattern,
              displayNameType,
              null,
              null,
              allowGrouping
            )
          }
          setAllowGrouping={setAllowGrouping}
          allowGrouping={allowGrouping}
        />
      </StyledContainer>
    </div>
  )
}

export default CreatePattern;