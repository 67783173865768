import React from 'react';
import {selectorValidator} from '../Selector/Selector';
import {KEYS} from '../consts';
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";

export const submitRole = (form, fetchCodefix, patternId, hash, setSelectorErros) => {
  let role = form.getFieldValue(KEYS.FIXES);
  let selector = form.getFieldValue(KEYS.SELECTOR);

  (async () => {
    var url = await getCurrentUrl();
    if (url) {
      selectorValidator(selector, patternId)
        .then(() => {
          form.validateFields([KEYS.FIXES]).then(() => {
            let codefix = {
              url: url,
              hash: hash,
              pattern_id: patternId,
              selector: selector,
              type: "role",
              name: role?.toLowerCase(),
              data: null,
              devices: [],
              browsers: [],
              urls: [],
            };
            fetchCodefix({ data: codefix });
          });
        })
        .catch((errorInfo) => setSelectorErros(errorInfo));
    }
  })();
};
