import React from "react";
import { Typography, Popover, Button } from "antd";
import styled from "styled-components";
import { toastSuccess } from "Shared/utils";
import { CopyToClipboard } from 'react-copy-to-clipboard';
const { Text } = Typography;
const StyledButton = styled(Button)`
border: 0px;
width:25px !important;
:focus{
    color:#fff
}
`;
const PopoverAndCopyToClipboard = ({ text, icon, type }) => {
    return (
        <Popover content={<><Text>{type}: </Text><Text strong>{text}</Text></>} placement="topLeft">
            <CopyToClipboard text={text}
                onCopy={() => {
                    toastSuccess(`${type}: ${text} copied to clipboard`, 2);
                    window.open(text, "_blank");
                }}>
                <StyledButton size={"large"} ghost={true} icon={icon} />
            </CopyToClipboard>
        </Popover>
    );
}

export default PopoverAndCopyToClipboard;