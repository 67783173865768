import React from "react";
import { uuid } from "Shared/utils";
import TreeItemTitle from "Main/Views/Sidebar/PatternsTree/TreeItem/TreeItemTitle"

const addSelectorsToTree = (elements, query, patternId, isBroken) => {
  let data = [];
  elements.forEach((element) => {
    data.push({
      title: (
        <TreeItemTitle
          patternId={element.id}
          hash={element.hash}
          url={null}
          name={element.name}
          count={element.count}
          visible={element.visible}
          selector={element.selector}
          data={element}
          type={element.type}
          marked={query ? element.marked : false}
          isBroken={isBroken}
        />
      ),
      key: `${patternId}-${element.hash}`,
      name: element.name,
      fullData: element,
      count: element.count || 0,
      type: element.type,
    });
  });
  return data;
};

export const treeData = (patterns, query, isParentVisible = true) => {
  let data = [];
  for (let [id, pattern] of Object.entries(patterns)) {
    const isBroken = (!isParentVisible && pattern.count > 0);
    let treeNode = {
      title: (
        <TreeItemTitle
          patternId={pattern.id}
          hash={null}
          url={pattern.url}
          type="pattern"
          data={pattern}
          name={pattern.name}
          count={pattern.count}
          marked={query ? pattern.marked : false}
          isBroken={isBroken}
          visible={pattern.visible}
        />
      ),
      key: pattern.id,
      name: pattern.name,
      type: "pattern",
      fullData: pattern,
      count: pattern.count,
      isBroken: isBroken,
      visible: pattern.visible
    };

    if (Object.keys(pattern.patterns).length > 0) {
      let result = treeData(pattern.patterns, query, isParentVisible ? pattern.count : !isBroken);
      if (treeNode.children) {
        treeNode.children = treeNode.children.concat(result);
      } else {
        treeNode.children = result;
      }
    }

    if (pattern.elements.length > 0) {
      var result = addSelectorsToTree(pattern.elements, query, pattern.id, isBroken);
      if (treeNode.children) {
        treeNode.children = treeNode.children.concat(result);
      } else {
        treeNode.children = result;
      }
    }

    data.push(treeNode);
  }

  return data;
};

export default TreeItemTitle;
