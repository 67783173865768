import React, { useState, useEffect } from 'react';
import { Layout } from "antd";
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import { sendScripterMessage, recieveMessage } from "Shared/messages";
import PreviewHeader from "./Header/PreviewHeader";
import PreviewContent from "./Main/PreviewContent";
import { validatorsInfo } from "./validatorsInfo";
import { Router } from "@reach/router";
import ErrorDetailsContainer from './Main/ErrorDetails/ErrorDetailsContainer';
import { PreviewProvider } from 'Scripter/Context/PreviewContext';
import { Storage } from 'Shared/Services/storage';
import { SCRIPTER_PREVIEW_MODE } from '../consts';


const ScripterPreview = () => {
    const [validators, setValidators] = useState(null);
    const [{ data: scriptsData, loading: scriptsLoading, error: scriptsError }, fetchScripts,] = useAxios(
        {
            method: "POST",
            url: `/scripter/scripts`,
            headers: {
                "content-type": "application/json",
            }
        },
        { manual: true }
    );

    const [{ data: localizationData, loading: localizationLoading, error: localizationError }, fetchLocalization,] = useAxios(
        {
            method: "POST",
            url: `/scripter/lang`,
            headers: {
                "content-type": "application/json",
            }
        },
        { manual: true }
    );

    useEffect(() => {
        if (localizationData) {
            sendScripterMessage({ type: "fix_scripter_preview", scripts: localizationData });
            recieveMessage(`preview_ready`, (data) => {
                setValidators(normalizeValidatorsData(data.result));
            });
        }
    }, [localizationData]);

    const normalizeValidatorsData = (data) => {
        return groupByErrorsPriority(data.ElementErrors.concat(data.DocumentErrors));
    }

    const groupByErrorsPriority = (items) => {
        const key = "user_impact_text";
        const reducer = (accumulator, currentValue) => {
            try {
                (accumulator[validatorsInfo[currentValue.Code][key]] = accumulator[validatorsInfo[currentValue.Code][key]] || []).push(currentValue)
            } catch (error) {
                //console.error("Error grouping validators error:", error);
            }
            finally {
                return accumulator;
            }
        };
        const grouped = items.reduce(reducer, {});
        Object.keys(grouped).forEach(key => grouped[key].sort((a, b) => (b.Elements?.length ?? 0) - (a.Elements?.length ?? 0)));
        return grouped;
    };

    useEffect(() => {
        if (scriptsData) {
            sendScripterMessage({ type: "init_scripter_preview", scripts: scriptsData, isPreviewMode: Storage.GetItem(SCRIPTER_PREVIEW_MODE)});
            recieveMessage(`init_scripter_preview`, (data) => {
                (async () => {
                    var url = await getCurrentUrl();
                    if (url) {
                        fetchLocalization({
                            data: { url: url, lang: data.lang },
                        });
                    }
                })();
            });
        }
    }, [scriptsData]);

    useEffect(() => {
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                fetchScripts({
                    data: { url: url },
                });
            }
        })();
    }, []);

    return (
        <Layout style={{ background: "transparent" }}>
            <PreviewHeader />
            <PreviewProvider>
                <Router>
                    <PreviewContent validatorsErrors={validators} path="/main/default" />
                    <ErrorDetailsContainer validatorsErrors={validators} path="error" />
                </Router>
            </PreviewProvider >
        </Layout >
    )
}

export default ScripterPreview;