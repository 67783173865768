
const FILTER_TYPES = {
    NOT_ON_PAGE: "notOnPage",
    HIDDEN: "hidden",
    BROKEN: "broken"
}

const patternItemDefnition = (value, elementsFilterFunc) => {
    return {
        count: value.count,
        elements: elementsFilterFunc ? elementsFilterFunc() : value.elements,
        group: value.group,
        id: value.id,
        name: value.name,
        parent: value.parent,
        parent_from_selector: value.parent_from_selector,
        patterns: {},
        selector: value.selector,
        type: value.type,
        url: value.url,
        visible: value.visible
    }
  }

const ShowOnlyVisibleAndNotBrokenTreeDictionaryElements = (i_treeDictionary, o_filteredTreeDictionary) => {
    Object.entries(i_treeDictionary).forEach(([key, value]) => {
      if (value.count > 0) {
        const filterElements =  () => value.elements.filter(el => el.count > 0);
        const patternItem = patternItemDefnition(value, filterElements);
        o_filteredTreeDictionary[value.id] = patternItem;
        ShowOnlyVisibleAndNotBrokenTreeDictionaryElements(value.patterns, patternItem.patterns, patternItem.id);
      }
    });
  }

  const ShowOnlyVisibleAndNotVisibleTreeDictionary = (i_treeDictionary, o_filteredTreeDictionary) => {
    Object.entries(i_treeDictionary).forEach(([key, value]) => {
      if (!value.isBroken) {
        const patternItem = patternItemDefnition(value);
        o_filteredTreeDictionary[value.id] = patternItem;
        ShowOnlyVisibleAndNotVisibleTreeDictionary(value.patterns, patternItem.patterns, patternItem.id);
      }
    });
  }

  const ShowOnlyVisibleAndBroken = (i_treeDictionary, o_filteredTreeDictionary) => {
    Object.entries(i_treeDictionary).forEach(([key, value]) => {
      if (value.isBrokenPath || value.count > 0) {
        const filterElements = () =>  value.isBroken ? value.elements : value.elements.filter(el => el.count > 0);
        const patternItem = patternItemDefnition(value, filterElements);
        o_filteredTreeDictionary[value.id] = patternItem;
        ShowOnlyVisibleAndBroken(value.patterns, patternItem.patterns, patternItem.id);
      }
    });
  }

  const ShowPreviewHidden = (i_treeDictionary, o_filteredTreeDictionary, type) => {

    Object.entries(i_treeDictionary).forEach(([key, value]) => {
      if (!value.visible || value.hasPreviewHiddenChild) {
        const patternItem = patternItemDefnition(value);
        o_filteredTreeDictionary[value.id] = patternItem;
        ShowPreviewHidden(value.patterns, patternItem.patterns, type);
      }
    });
  }

export {
    ShowOnlyVisibleAndNotBrokenTreeDictionaryElements,
    ShowOnlyVisibleAndNotVisibleTreeDictionary,
    ShowOnlyVisibleAndBroken,
    ShowPreviewHidden,
    FILTER_TYPES
};