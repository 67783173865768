import React, {useState, useEffect} from 'react'
import {Form, Input, Typography} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import {selectorValidator, absoluteSelectorValidator} from '../../Selector/Selector';
import {resetFields} from 'Shared/formService';

const { Text } = Typography;

const MenuSelector = ({form, selectedOption, patternId, label, name, selectorErrors, setErrors, isAbsolutSelector, currentMenuSelector, menuSelector, setMenuSelector}) => {

    useEffect(() => {
        if(selectedOption){
            resetFields(form, ["selectorBehavior"])
        }
    }, [selectedOption])

    const isDuplicateMenuSelector = (value) => {
        if(menuSelector && setMenuSelector){
            const keys = Object.keys(menuSelector);
            let isValid = true;
            if(keys.length == 0){
                setMenuSelector({[name]: value});
                return true;
            }
            keys.forEach((k) => {
                if(k !== name && menuSelector[k] === value){
                    isValid = false;
                }
            });
            isValid && setMenuSelector({...menuSelector, [name]: value});
            return isValid;
        }
        return true;
    }

    return (
        <>
            <TooltipLabel required value={label} tooltip={{ text: "fill later" }} />
            <Form.Item
                style={{ marginBottom: '2px' }}
                name={name}
                hasFeedback
                validateTrigger={['onBlur']}
                validateStatus={selectorErrors === null ? null : selectorErrors.length ? "error" : "success"}
                rules={[
                {
                        validator: async (rule, selector) => {
                            try {
                                const isDotSelectorNotAllowed = true;
                                if(isAbsolutSelector){
                                    const allowMultiple = true;
                                    absoluteSelectorValidator(selector, isDotSelectorNotAllowed, allowMultiple).then((result) => {
                                        let isValid = isDuplicateMenuSelector(selector);
                                        if(!isValid){
                                            let error = ["Menu selector exist in other menu"];
                                            setErrors(error);
                                            return Promise.reject(error);
                                        }
                                        setErrors([]);
                                        return Promise.resolve();
                                    }).catch((result) => {
                                        setErrors(result);
                                        return Promise.reject(result);
                                    });
                                }
                                else {
                                    const isRelativeToSelector = currentMenuSelector && form.getFieldValue(currentMenuSelector);
                                    selectorValidator(selector, patternId, null, isDotSelectorNotAllowed, isRelativeToSelector).then((result) => {
                                        let isValid = isDuplicateMenuSelector(selector);
                                        if(!isValid){
                                            let error = ["Menu selector exist in other menu"];
                                            setErrors(error);
                                            return Promise.reject(error);
                                        }
                                        setErrors([]);
                                        return Promise.resolve();
                                    }).catch((result) => {
                                        setErrors(result);
                                        return Promise.reject(result);
                                    });
                                }
                            }
                            catch (err) {
                                return Promise.reject(err.response && err.response.data && err.response.data.Errors || err);
                            }
                        }
                }]}
            >
                <Input placeholder="FILL THE SELECTOR" />
            </Form.Item>
            {selectorErrors && selectorErrors.length ? <Text type="danger">{selectorErrors[0]}</Text> : null}
        </>
    )
}

export default MenuSelector;