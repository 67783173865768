import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Switch } from "antd";

const StyledSwitch = styled(Switch)`
  background: linear-gradient(to right, #360095, #4e0e7e);

  .ant-switch-inner {
    padding: 0 0.25rem;
  }
`;

const ToggleSwitch = ({
  checked,
  onClick,
  checkedChildren,
  unCheckedChildren,
}) => (
  <StyledSwitch
    checkedChildren={checkedChildren}
    unCheckedChildren={unCheckedChildren}
    checked={checked}
    onClick={onClick}
  />
);

export default ToggleSwitch;
