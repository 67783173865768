import React, { useEffect } from "react";
import { useSwitch } from "Scripter/Context/SwitchContext";
import { StyledMenu, MenuItem } from "Scripter/Shared/MenuStyles";
import { Storage } from "Shared/Services/storage";
import { IDE_ACTIONS, STORAGE_NAMES } from "../consts";

const IdeDropdown = () => {

    const { ideAction, setIdeAction } = useSwitch();

    useEffect(() => {
        !ideAction && setIdeAction(IDE_ACTIONS.PREVIEW);
    }, [])

    const updateIDEAction = (action) => {
        Storage.SetItem(STORAGE_NAMES.IDE_ACTION, action);
        setIdeAction(action)
    }

    return (<StyledMenu mode="vertical">
        <MenuItem activeItem={ideAction == IDE_ACTIONS.PREVIEW || !ideAction}>
            <a target="_blank" rel="noopener noreferrer" onClick={() => updateIDEAction(IDE_ACTIONS.PREVIEW)}>
                {IDE_ACTIONS.PREVIEW}
            </a>
        </MenuItem>
        <MenuItem activeItem={ideAction == IDE_ACTIONS.PUBLISH}>
            <a target="_blank" rel="noopener noreferrer" key={IDE_ACTIONS.PUBLISH} onClick={() => updateIDEAction(IDE_ACTIONS.PUBLISH)}>
                {IDE_ACTIONS.PUBLISH}
            </a>
        </MenuItem>
    </StyledMenu>
    );
}

export default IdeDropdown;