import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Popconfirm } from "antd";
import { colors } from "Shared/colors";
import styled from "styled-components";
import IgnoreCheckbox from "./IgnoreCheckbox";
import PopoverAndCopyToClipboard from "./PopoverAndCopyToClipboard";
import PageUrl from "./PageUrl";
import Selector from "./Selector";
import { LinkOutlined, FileTextOutlined, UnlockOutlined, LockOutlined, EditOutlined } from '@ant-design/icons';
import { IMAGE_STATUS, IDS } from "../consts";

const Continer = styled.div`
  position:relative;
  background-color: white;
  height: 25vh;
  width: 100%;
  z-index: 4;
  overflow:hidden;
  justify-self: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #e6e6e6;
`;

const Overlay = styled.div`
  ${props => props.isCollection && 'cursor: pointer;'}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity:  ${props => props.isChecked || props.forceVisivle.selector || props.forceVisivle.pageUrl ? '1' : '0'};
    background-color: rgba(17,17,17,0.85);
    transition: 0.5s;
    :hover {
      opacity: 1;
    };
    display: grid;
    grid-template-columns: repeat(7, calc(100% / 7));
    grid-template-rows: repeat(5, calc(100% / 5));
    grid-template-areas: 
      "checkbox . . . . . copytoclipboard"
      ". . . . . . ."
      ". . . . . . ."
      "tag tag . . . . ."
      "text text text text text text editText";
`;

const Image = styled.img`
  z-index: 1;
  max-width: 90%;
  max-height: 90%;
`;
const CheckboxContainer = styled.div`
  ${props => props.isCollection && 'display:none;'}
  opacity:0.8;
  grid-area: checkbox; 
  left: 9px;
  position: absolute;
  align-self: center;
`

const CopytoclipboardContainer = styled.div`
position: absolute;
top: 0px;
right: 5px;
grid-area: copytoclipboard; 
justify-self: end;
align-self: center;
display:${props => props.isCollection ? 'none' : 'flex'};
`

const TagContainer = styled.div`
grid-area: tag;
border-radius: 0px 5px 5px 0px;
text-align: center;
background: ${props => props.ignore ? colors.LIGHT_MID_GREY : colors.BLUE};
align-self: center;
color: white;
font-weight: 500;
`
const StyledEditButton = styled(Button)`
grid-area: editText;
align-self: center;
text-align: center;
border:0px;
`
const StyledInput = styled(Input)`
grid-area: text;
background-color: transparent !important;
border: 0px !important;
color: white !important;
:focus{
  box-shadow: none !important;
}
`;

const ImageObject = ({ image, updateImages, checkedItems, setCheckedItems, setUrlFilters }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [forceVisivle, setForceVisible] = useState({ selector: false, pageUrl: false });
  const inputRef = useRef(null);
  const isCollection = image.collection?.length > 1;

  const HandleInlineTextChange = (newText) => {
    if (isCollection) {
      const newImages = [...image.collection];
      newImages.forEach(i => {
        i.text.text = newText;
        i.text.status = IMAGE_STATUS.MANUAL;
      })
      updateImages(newImages);
    }
    else {
      if (newText != image.text.text) {
        const newImage = {
          ...image,
          text: {
            ...image.text,
            language_code: image.language_code ?? "",
            text: newText,
            status: IMAGE_STATUS.MANUAL
          }
        };
        updateImages([newImage]);
      }
    }
    setIsEditable(false);
    setTimeout(() => {
      setIsEditing(false)
    }, 500);
  }
  useEffect(() => {
    if (isEditable) {
      inputRef.current.focus();
    }
  }, [isEditable]);



  const HandleCollectionOverlayClick = () => {
    if (isCollection && !isEditing) {
      setUrlFilters(image.image_url);
    }
  }
  return (
    <Continer id={image.id}>
      <Overlay onClick={HandleCollectionOverlayClick} isCollection={isCollection} forceVisivle={forceVisivle} isChecked={image.checked}>
        <CheckboxContainer isCollection={isCollection}>
          <IgnoreCheckbox setCheckedItems={setCheckedItems} checkedItems={checkedItems} image={image} />
        </CheckboxContainer >
        <CopytoclipboardContainer isCollection={isCollection}>
          <Selector updateImages={updateImages} image={image} forceVisivle={forceVisivle} setForceVisible={setForceVisible} />
          <PageUrl url={image.page_url} icon={<LinkOutlined />} forceVisivle={forceVisivle} setForceVisible={setForceVisible} />
        </CopytoclipboardContainer>
        <TagContainer ignore={!isCollection && image.ignore}>{image.collection?.length == 1 ? IMAGE_STATUS[image.text.status] : `${image.collection?.length} IMAGES`}</TagContainer>
        <Popconfirm
          disabled={!isCollection}
          placement="topRight"
          okText="Yes"
          cancelText="No"
          onCancel={(e) => e.stopPropagation()}
          onConfirm={(e) => {
            e.stopPropagation();
            setIsEditing(true);
            setIsEditable(!isEditable);
          }}
          title={`Are you sure you want to edit ${image.collection?.length} images?`}>
          <StyledEditButton
            onClick={
              (e) => {
                e.stopPropagation();
                if (!isCollection) {
                  setIsEditing(true);
                  setIsEditable(!isEditable);
                }
              }}
            size={"large"}
            ghost={true}
            icon={!isEditable && <EditOutlined
              style={{
                color: colors.LIGHT_BLUE
              }} />
            }
          />
        </Popconfirm>
        <StyledInput
          ref={inputRef}
          disabled={!isEditable}
          autoComplete="off"
          onClick={(e) => e.stopPropagation()}
          onInput={() => setIsEditing(true)}
          onBlur={(e) => HandleInlineTextChange(e.target.value)}
          defaultValue={image.text.text}
        />
      </Overlay>
      <Image src={image.image_url} />
    </Continer>
  );
}

export default ImageObject;