import React, { useState, useEffect, useRef, useCallback } from "react";
import { Spin } from "antd";
import useAxios from "axios-hooks";
import styled from "styled-components";
import { getCurrentUrl } from 'Shared/Services/getCurrentUrl';
import { toastError, toastSuccess } from "Shared/utils";
import ImageObject from "./Image/ImageObject";
import { LoadingOutlined } from '@ant-design/icons';
import ImagesGalleryHeader from './ImagesGalleryHeader';

const ImagesContiner = styled.div`
    padding-top:10px;
    padding-right:10px;
    display: grid;
    grid-template-columns: ${props => props.sideBarCollaped ? '1fr 1fr 1fr' : '1fr 1fr'};
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 10px;
    max-height: 82vh ;
    overflow-y: scroll;
    overflow-x: auto;
    align-items: center;
`;

const StyledDiv = styled.div`
${props => props.isCollection && `
position: relative;
display: block;
box-shadow: 0 0 50px rgba(0,0,0,0.1);
border: solid 1px #e6e6e6;
height:90%;
width:100%;
z-index: 3;
&::before,
&::after{
    border: solid 1px #e6e6e6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    content: '';
  }
  &::before{
    transform: translate(4px, -4px);
    z-index: 2;
  }
  &::after{
    transform:  translate(8px, -8px);
    z-index: 1;
  }
`}
`;

const ImagesGallery = ({ images, fullImagesData, setFullImagesData, filteredImagesCount, sideBarCollaped, setImages, setStartFromImage, setUrlFilters }) => {

    const [{ data: updatedImages, loading: updateLoading, error: updateError }, fetchUpdateImages] = useAxios(
        {
            method: "PUT",
            url: `/image`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        setCheckedItems([]);
    }, [fullImagesData]);

    useEffect(() => {
        if (updateError) {
            const newImages = [...images];
            newImages.forEach(i => {
                i.loading = false;
            });
            setImages(newImages);
            toastError(`image update failed`);
        }
    }, [updateError])

    useEffect(() => {
        if (updatedImages && updatedImages.length) {
            const newImages = [...images];
            const allImages = [...fullImagesData];
            updatedImages.forEach(i => {
                i.loading = false;
                const objIdxForAllImages = allImages.findIndex(obj => obj.id == i.id);
                allImages[objIdxForAllImages] = i;

                const objIdxForNewImages = newImages.findIndex(obj => obj.id == i.id);
                if (objIdxForNewImages != -1) {
                    i.collection = images[objIdxForNewImages].collection;
                    i.collection.forEach(j => j.loading = false);
                    newImages[objIdxForNewImages] = i;
                }
            });
            setImages(newImages);
            setFullImagesData(allImages);
            toastSuccess(`${updatedImages.length} ${updatedImages.length > 1 ? "images" : "image"} updated successfully`)
        }
    }, [updatedImages, setImages]);

    const updateImages = (imageArrToUpdate) => {
        const newImages = [...images];
        const toUpdate = [...imageArrToUpdate];
        toUpdate.forEach(i => {
            i.loading = true;
            const objIdxForNewImages = newImages.findIndex(obj => obj.id == i.id);
            newImages[objIdxForNewImages] = { ...i };
            delete i.collection;
        });
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                fetchUpdateImages({ data: { url, images: imageArrToUpdate } });
                setImages(newImages);
            }
        })();
    }

    const observer = useRef()
    const lastImageRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && (!images.length || images.length >= 30)) {
                setStartFromImage(images.length);
            }
        })
        node && observer.current.observe(node);
    }, [images])

    return (
        <>
            <ImagesGalleryHeader loadedCount={images.length} allImagesCount={filteredImagesCount} checkedItems={checkedItems} updateImages={updateImages} setCheckedItems={setCheckedItems} />
            <ImagesContiner checkedItems={checkedItems} sideBarCollaped={sideBarCollaped}>
                {images?.map((image, idx) => image.loading ?
                    <Spin tip="Updating Image" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    :
                    <div ref={images.length === idx + 1 ? lastImageRef : null} id={image.id} key={`_${image.id}`}>
                        <StyledDiv isCollection={image.collection?.length > 1}>
                            <ImageObject setUrlFilters={setUrlFilters} key={image.id} image={image} updateImages={updateImages} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                        </StyledDiv>
                    </div>
                )}
            </ImagesContiner>
        </>
    );
}

export default ImagesGallery;