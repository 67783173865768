import React, { useEffect } from "react";
import { Popconfirm, Button, Popover, Spin } from "antd";
import useAxios from "axios-hooks";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import styled from "styled-components";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useGlobal } from "GlobalContext/GlobalContext";
import { toastError, toastSuccess } from "Shared/utils";
import Moment from "react-moment";
import { colors } from "Shared/colors";
import { useNotification } from 'Main/Context/Notification/NotificationContext';

const List = styled.ul`
  height: calc(100vh - 13.5rem);
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  height: 87vh;
  padding: 0.5rem;
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 40px auto 20px;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Type = styled.div`
  border-radius: 5px 15px;
  background-image: linear-gradient(135deg,#8aa6df 0%,#274c7d 100%);
  height: 30px;
  width: 38px;
  text-align: center;
  line-height: 30px;
  font-size: 10px;
  font-weight: 300;
}
`;

const Description = styled.div`
  font-size: 0.8rem;
  height: 18px;
  line-height: 18px;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0.3rem;
  font-weight: 600;
  time {
    color: ${colors.LIGHT_MID_GREY};
    font-weight: 200;
  }
`;

const PopoverContent = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  display: grid;
  grid-template-rows: 24px;
  grid-gap: 5px;
`;

export const TheSpin = styled(Spin)`
  display: grid;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 89vh;
  justify-items: center;
  span {
    font-size: 5rem!important;
    color: ${colors.BLUE}!important;
  }
`;

const HistoryList = ({ shouldRefreshHistory, setShouldRefreshHistory }) => {
  const {
    updatePatternTree,
    treeDictionary,
    setHighlightedItem,
    selectedItem,
  } = useTree();
  const { sendSocketMessage } = useNotification();

  const { url, fetchSiteSettings, siteSettings } = useGlobal();

  const [
    { data: historyLogList, loading: historyLoading, error: historyLogErrors },
    getHistoryList,
  ] = useAxios(
    {
      method: "POST",
      url: `/site/history`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [
    { data: rollbackData, loading: rollbackLoading, error: rollbackErrors },
    rollbackVersion,
  ] = useAxios(
    {
      method: "POST",
      url: `/site/rollback`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (shouldRefreshHistory) {
      (async () => {
        var url = await getCurrentUrl();
        if (url) {
          getHistoryList({ data: { url } });
        }
      })();
    }
    setShouldRefreshHistory(false);
  }, [shouldRefreshHistory]);

  useEffect(() => {
    if (rollbackData) {
      toastSuccess("Rollback successful!");
      fetchSiteSettings({ data: { url: url, rollback: true } });
      updatePatternTree();
      sendSocketMessage({ action: "rollback", type: "rollback" });
    }
    if (rollbackErrors) {
      const errors =
        rollbackErrors.response &&
        rollbackErrors.response.data &&
        rollbackErrors.response.data.Errors;
      toastError("Rollback failed! " + errors[0]);
    }
  }, [rollbackData]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      {historyLoading && <TheSpin indicator={antIcon} />}
      {!historyLoading && (
        <List>
          {historyLogList &&
            historyLogList.map((item) => (
              <ListItem>
                <Type>{item.version}</Type>
                <Description>
                  <Popover
                    placement="right"
                    content={
                      <PopoverContent>
                        {item.description &&
                          item.description
                            .split(";")
                            .map((desc) => <div>{desc}</div>)}
                      </PopoverContent>
                    }
                    trigger="hover"
                  >
                    {item.modified_by}{" "}
                  </Popover>
                  <Moment utc local fromNow>
                    {item.date}
                  </Moment>
                </Description>
                <Popconfirm
                  title={<span>Rollback '{item.version}' version?</span>}
                  onConfirm={() =>
                    rollbackVersion({ data: { url, version: item.version } })
                  }
                >
                  {
                    siteSettings && siteSettings.version !== item.version &&
                    <Button
                      title="REVERT"
                      style={{ color: "white" }}
                      type="link"
                      icon={<CloudUploadOutlined />}
                    />
                  }
                </Popconfirm>
              </ListItem>
            ))}
        </List>
      )}
    </>
  );
};

export default HistoryList;
