import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { apiClient } from 'Shared/apiClient';
import useAxios from "axios-hooks";
import { colors } from "Shared/colors";
import styled from "styled-components";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
import ImagesHeader from "./ImagesHeader";
import ImagesGallery from "./ImagesGallery";
import { Empty } from 'antd';
import { Filter } from "./Filters";
import { Loading } from "../../../App"
import { toastError } from "Shared/utils";

const { Header, Content } = Layout;

const StyledLayout = styled(Layout)`
  display: flex;
  flex-flow: column;
  height: ${(props) => (props.isMenuScript ? "90vh" : "calc(100vh - 40px)")};
`;
const StyledHeader = styled(Header)`
  height: 50px;
  padding-left: 50px;
  padding-right: 15px;
  background: ${colors.DARK90}!important;
  .ant-menu {
    background: ${colors.DARK90}!important;
    height: 50px;
  }
`;
const StyledContent = styled(Content)`
  margin:5px;
  padding: '1rem 1rem 0 1rem'};
  background-color: ${colors.LIGHT_BLUE};
  .margin {
    background-color: #f0f2f5 !important;
  }
  height:100%;
  overflow: hidden;
`;

const NoData = styled(Empty)`
position: absolute;
width: 100%;
top: 40%;
`;




const ImagesContainer = ({
  setCollapseColor,
  setSideBarCollaped,
  sideBarCollaped,
}) => {
  setCollapseColor("white");
  const [fullImagesData, setFullImagesData] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [visibleImages, setVisibleImages] = useState([]);
  const [startFromImage, setStartFromImage] = useState(0);
  const [urlFilter, setUrlFilters] = useState("");
  const [tagFilter, setTagFilters] = useState([]);

  const [{ data: dataFromServer, loading: dataFromServerLoading, error: dataFromServerError }, fetchDataFromServer] = useAxios(
    {
      method: "POST",
      url: `/image`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );


  useEffect(() => {
    if (fullImagesData?.length) {
      const images = Filter(fullImagesData, tagFilter, urlFilter);
      setFilteredImages(images);
    }
  }, [fullImagesData]);

  useEffect(() => {
    if (dataFromServerError) {
      toastError(dataFromServerError.response.data.Errors[0]);
    }
  }, [dataFromServerError]);

  useEffect(() => {
    if (dataFromServer) {
      const handleNullText = dataFromServer.map(img => {
        if (!img.text || img.text.status == "SMALL IMAGE") {
          img.text = {
            language_code: "",
            status: "MISSING_ALT",
            text: ""
          }
        }
        return img;
      })
      setFullImagesData(handleNullText);
    }
  }, [dataFromServer]);

  useEffect(() => {
    setVisibleImages([...filteredImages].splice(0, startFromImage + 30));
  }, [filteredImages, startFromImage])

  useEffect(() => {
    setSideBarCollaped(true);
    (async () => {
      var url = await getCurrentUrl();
      if (url) {
        fetchDataFromServer({ data: { url } });
      }
    })();
  }, []);

  return (
    <StyledLayout>
      <StyledHeader>
        <ImagesHeader urlFilter={urlFilter} setUrlFilters={setUrlFilters} fullImages={fullImagesData} setImages={setFilteredImages} tagFilter={tagFilter} setTagFilters={setTagFilters} />
      </StyledHeader>
      <StyledContent>
        {
          dataFromServerLoading ?
            <Loading msg="Loading" />
            :
            visibleImages && !!visibleImages.length
              ?
              <ImagesGallery
                setUrlFilters={setUrlFilters}
                images={visibleImages}
                filteredImagesCount={filteredImages.length}
                fullImagesData={fullImagesData}
                setFullImagesData={setFullImagesData}
                setImages={setFilteredImages}
                setStartFromImage={setStartFromImage}
                sideBarCollaped={sideBarCollaped}
              />
              :
              <NoData
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No Images</span>}>
              </NoData>
        }
      </StyledContent>
    </StyledLayout>
  );
};

export default ImagesContainer;
