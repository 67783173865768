import React from 'react'
import { Layout } from 'antd';
import { colors } from 'Shared/colors';
import styled from "styled-components";
import TabControl from "./TabControl/TabControl"
import ScopeModal from './ScopeModal';
import { useScripter } from 'Scripter/Context/ScripterContext';
import UrlChangeModal from './UrlChangeModal';
import ReportModal from './ReportModal';
import PublishModal from './PublishModal';
import { useVisibilityContext } from 'Scripter/Context/ElementVisibilityContext';


const { Content } = Layout;
const StyledContent = styled(Content)`
  background: ${colors.DARK90};
`;

const ScripterContent = () => {
  const {scopeModalProps, urlChangeProps } = useScripter();
  const {isPublishModalVisible, reportModalVisibility} = useVisibilityContext();

  return (
    <StyledContent>
      <TabControl />
      {scopeModalProps?.visible && <ScopeModal/>}
      {reportModalVisibility && <ReportModal/>}
      {urlChangeProps?.visible && <UrlChangeModal/>}
      {isPublishModalVisible && <PublishModal/>}
    </StyledContent>
  )
}

export default ScripterContent;