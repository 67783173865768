import React, {useState, useEffect} from 'react';
import { Form } from 'antd';
import styled from "styled-components";
import MenuConfiguration from './MenuConfiguration';
import {colors} from 'Shared/colors';
import {PlusCircleOutlined, CloseCircleOutlined, RightOutlined, DownOutlined} from '@ant-design/icons';
import {uuid} from 'Shared/utils';

const StyledIcons = styled.div`
    svg{
        width: 18px !important;
        height: 18px !important;
    }
`;

const StyleAccordion = styled.ul`   
    border: 1px solid rgba(0,0,0,.125) !important;
    border-bottom: none !important;
    padding: 0;
`;

const StyleAccordionItem = styled.li`
    display: flex;
    border: 1px solid #f0f0f0;
    padding: 8px;
    background-color: rgb(220 220 220 / 3%);
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    cursor: pointer;
    font-size: 12px !important;
`;

const StyledAccordionContent = styled.div`
    background: white;
    padding: 10px;
    display: ${props => props.visible ? "block" : "none"};
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
`;


const SecondLevelContent = ({patternId, form, codefixEditData}) => {
    const firstMenuId = uuid();
    const [menus, setMenus] = useState([{key: firstMenuId}]);
    const [activeKey, setActiveKey] = useState(firstMenuId);
    const [allowAddButton, setMenuAllowAddButton] = useState(false);
    const [menuSelector, setMenuSelector] = useState({});

    useEffect(() => {
        if(codefixEditData){
            const secondLevel = codefixEditData.data.secondLevel;
            if(!!secondLevel.length){
                const menus = secondLevel.filter(f => f.type === "menuWidgetMenu");
                addBulkMenus(menus);
                setMenuAllowAddButton(true);
            } 
        }
    }, [codefixEditData])

    const addBulkMenus = (data) => {
        let menus = [];
        for(let i = 0; i < data.length; i++){
            let menuData = data[i].values;
            menus.push({key: uuid(), editData: menuData});
        }
        setMenus(menus);
    }

    const onAdd = (e) => {
        let id = uuid();
        setMenus([...menus, {key: id}]);
        setActiveKey(id);
    }

    const onRemove = (key) => {
        let updatedMenus = menus.filter((m) => m.key !== key);
        setMenus(updatedMenus);
        setActiveKey(null);
    }

    const onToggle = (key) => {
        setActiveKey(activeKey === key ? null : key);
    }

    const length = menus.length;

    return (
        <>
            <Form form={form}>
                <StyleAccordion>
                    {
                        menus.map((menu, index) => (
                            <div>
                                <StyleAccordionItem>
                                    <div role="button" onClick={() => onToggle(menu.key)} style={{flex: 1}}>
                                        {
                                            activeKey === menu.key
                                            ?
                                                <DownOutlined style={{color: '#b5b5b5'}}/>
                                            :
                                                <RightOutlined style={{color: '#b5b5b5'}}/>
                                        }
                                        <span style={{marginLeft: 5, fontWeight: 700}}>MENU {index + 1}</span>
                                    </div>
                                    {
                                        allowAddButton && 
                                        <StyledIcons>
                                        {
                                            length > 1 && index < length - 1
                                            ?
                                            <CloseCircleOutlined onClick={() => onRemove(menu.key)} style={{color: 'rgb(247, 61, 61)'}}/>
                                            :
                                            <PlusCircleOutlined onClick={(e) => onAdd(e)} style={{color: colors.BUTTON_BLUE}}/>
                                        }
                                        </StyledIcons>
                                    }
                                </StyleAccordionItem>  
                                <StyledAccordionContent visible={activeKey === menu.key}>
                                    <MenuConfiguration 
                                        codefixEditData={codefixEditData} 
                                        form={form} 
                                        setMenuAllowAddButton={setMenuAllowAddButton} 
                                        index={index} 
                                        uniqueName={`${index}_${menu.key}`} 
                                        patternId={patternId} 
                                        menu={menu}
                                        menuSelector={menuSelector} 
                                        setMenuSelector={setMenuSelector}
                                    />
                                </StyledAccordionContent>
                            </div>
                        ))
                    }
                </StyleAccordion>
            </Form>
        </>
    )
}



export default SecondLevelContent;