import React, { useEffect, useState } from "react";
import { Modal, Popconfirm, Input, Form, Button } from "antd";
import useAxios from "axios-hooks";
import "./ExecuteScript.css";
import {
  StyledCancelButton,
  StyledLoadingContainer,
  StyledSpinner,
  StyledSaveButton,
  StyledEditButton,
  StyledDeleteButton
} from "./ExecuteScript";
import { toastError, toastSuccess } from "Shared/utils";
import { navigate } from "@reach/router";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { useSiteScripts } from "Main/Context/SiteScripts/SiteScriptsContext";
import styled from "styled-components";
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";

const StyledButtonsModalContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 9fr;
`;
const StyledButtonsFooterContainer = styled.div`
display: grid;
grid-template-columns:${(props) =>
    props.hash === "new" ? "2fr 1fr 9fr;" : (props.scriptType === "scripts" ? " 2fr 2fr 1fr 7fr;" : " 2fr 2fr 2fr 1fr 5fr;")}
padding: 1rem;
margin-left: 4rem;
`;

const ExecuteScriptFooter = ({
  title,
  modalVisibility,
  setModalVisibility,
  name,
  setSideBarCollaped,
  setName,
  description,
  setDescription,
  patternId,
  selector,
  runtime,
  editorValue,
  hash,
  setSelector,
  scriptType,
  urlPattern,
  setUrlPattern,
  sendSocketMessage
}) => {

  const [{ data: savedData, loading: saveScriptLoading, error: saveScriptError }, saveNewScript,] = useAxios(
    {
      method: "POST",
      url: `/${scriptType}`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [{ data: updatedData, loading: updateScriptLoading, error: updateScriptError, }, updateScript,] = useAxios(
    {
      method: "PUT",
      url: `/${scriptType}`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  const [{ data: deleteData, loading: deleteScriptLoading, error: deleteScriptError, }, deleteScript,] = useAxios(
    {
      method: "DELETE",
      url: `/siteScripts`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (deleteData) {
      setSideBarCollaped(false);
      setDeleteModalVisble(false);
      toastSuccess(
        `script with name: ${hash} removed successfully`
      );
      updateSiteScripts();
      navigate("/main/default");
    }
  }, [deleteData]);

  useEffect(() => {
    if (deleteScriptError) {
      toastError(deleteScriptError.response.data.Errors);
    }
  }, [deleteScriptError]);

  const [deleteModalVisible, setDeleteModalVisble] = useState(false);
  useEffect(() => {
    const data = savedData || updatedData;
    if (data) {
      setSelector(null);
      setSideBarCollaped(false);
      setModalVisibility(false);
      toastSuccess(
        `script with name: ${name} saved successfully`
      );
      if (scriptType === "scripts") {
        updatePatternTree();
        sendSocketMessage({action: savedData ? "create" : "edit", type: "script", id: patternId, hash: data.hash, name}); 
        navigate(`/main/${patternId}/edit-codefix/script-${data.hash}`);
      }
      else {
        updateSiteScripts();
        navigate("/main/default");
      }
    }
  }, [savedData, updatedData]);

  useEffect(() => {
    const error = saveScriptError || updateScriptError;
    if (error) {
      toastError(error.response.data.Errors);
    }
  }, [saveScriptError, updateScriptError]);


  const [form] = Form.useForm();
  const { updatePatternTree } = useTree();
  const { updateSiteScripts } = useSiteScripts();

  const { TextArea } = Input;

  const handleDeleteModalOk = () => {
    (async () => {
      var url = await getCurrentUrl();
      deleteScript({
        data: {
          name,
          url
        }
      })
    })()
  }

  const handleModalCancel = () => {
    setModalVisibility(false);
  };
  const saveToServer = (method) => {
    let value = editorValue;
    if (scriptType === "scripts") {
      const functionPos = editorValue.indexOf("function");
      value = editorValue.substr(functionPos);
    }

    (async () => {
      var url = await getCurrentUrl();
      if (url) {
        if (value.scriptType == "scripts") {
          var content = escape(value);
        } else {
          var content = value;
        }
        const data = {
          data: {
            url: url,
            name: name,
            description: description ? description : "",
            content: (scriptType === "siteScripts" ? value.trim() : escape(value).trim()),
            runtime: runtime.id,
          }
        }
        if (scriptType === "scripts") {
          data.data.hash = hash;
          data.data.pattern_id = patternId;
          data.data.selector = selector;
        } else {
          data.data.old_name = hash;
          data.data.url_pattern = urlPattern;
        }
        method === "POST" ? saveNewScript(data) : updateScript(data);
      }
    })();
  };
  const handleModalOk = () => {
    // save to server
    form.validateFields().then(() => {
      if (hash === "new") {
        saveToServer("POST");
      } else {
        handleModalCancel();
      }
    });
  };
  if (saveScriptLoading || updateScriptLoading) {
    return (
      <StyledLoadingContainer>
        <StyledSpinner tip="Loading..." size="large" />
      </StyledLoadingContainer>
    );
  }

  const initialValues = scriptType === "script" ?
    {
      name,
      description,
    } : {
      name,
      urlPattern,
      description,
    };

  return (
    <React.Fragment>
      <StyledButtonsFooterContainer hash={hash}>
        {hash !== "new" && (
          <StyledSaveButton
            onClick={() => {
              saveToServer("PUT");
            }}
            type="primary"
          >
            Save
          </StyledSaveButton>
        )}
        <StyledEditButton
          onClick={() => {
            setModalVisibility(true);
          }}
          type="primary"
        >
          {hash === "new" ? "Save" : "Edit info"}
        </StyledEditButton>
        {hash !== "new" && scriptType === "siteScripts" && (
          <Popconfirm
            className="DeletePopConfirm"
            title="Are you sure you want to delete this script?"
            onConfirm={handleDeleteModalOk}
            okText="Yes"
            cancelText="No"
          >
            <StyledDeleteButton>
              Delete
            </StyledDeleteButton>
          </Popconfirm>
        )}
        <StyledCancelButton
          onClick={() => {
            setSideBarCollaped(false);
            setSelector(null);
            if (scriptType === "scripts") {
              if (hash === "new") {
                navigate(`/main/${patternId}/codefix`);
              } else {
                navigate(`/main/${patternId}/edit-codefix/script-${hash}`);
              }
            } else {
              navigate(`/main/default`);
            }

          }}
          type="link"
        >
          Cancel
        </StyledCancelButton>
      </StyledButtonsFooterContainer>
      <Modal
        saveToServer={saveToServer}
        autoFocus={false}
        centered={true}
        title={title}
        visible={modalVisibility}
        onCancel={handleModalCancel}
        footer={[
          <StyledButtonsModalContainer>
            <StyledSaveButton
              key="submit"
              htmlType="submit"
              type="primary"
              onClick={handleModalOk}
            >
              {hash === "new" ? "Save" : "Done"}
            </StyledSaveButton>
            <StyledCancelButton
              key="back"
              onClick={handleModalCancel}
              type="link"
            >
              Cancel
            </StyledCancelButton>
          </StyledButtonsModalContainer>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initialValues}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Please input the name of script!" },
              { min: 4, message: "Script name must be minimum 4 characters." },
              {
                max: 64,
                message: "Script name must be maximum 64 characters.",
              },
            ]}
          >
            <Input
              value={name}
              autoFocus={true}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
          {
            scriptType === "siteScripts" &&
            <Form.Item name="urlPattern" label="URL Pattern">
              <Input
                value={urlPattern}
                onChange={(e) => setUrlPattern(e.target.value)}
              />
            </Form.Item>
          }
          <Form.Item name="description" label="Description">
            <TextArea
              placeholder="Script Description"
              value={description}
              allowClear
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ExecuteScriptFooter;
