import React from 'react';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import { Input } from 'antd';
import {tooltipsContent} from '../data';
import {FormItem} from '../PatternForm';
import { KEYS } from '../consts';
import { apiClient } from 'Shared/apiClient';
import { SITE_SETTINGS_CONSTS } from 'GlobalContext/GlobalContext';

export const PatternName = ({form ,siteSettings, edit}) => {
    return (
        <div>
            <TooltipLabel required value="NAME" tooltip={{ text: tooltipsContent.patternName }} />
            <FormItem validateTrigger={['onBlur']}  hasFeedback name={KEYS.PATTERN_NAME} 
                rules={[
                    {required: true, message: 'Pattern name required'},
                    {min: 3, message: 'Name should contain minimum 3 letters'},
                    {max: 64, message: 'Name should not contain more then 64 letters'},
                    {validator: async (rule, name) => {
                        let patternName = form.getFieldValue([KEYS.PATTERN_NAME]);
                        let body = {
                            site_id: siteSettings[SITE_SETTINGS_CONSTS.SITE_ID],
                            name: patternName
                        };

                        if(edit && patternName === edit[KEYS.PATTERN_NAME]){
                            return Promise.resolve();
                        }

                        try{
                            let remoteValidation = await apiClient("/patterns/validate-name", {body});
                            return Promise.resolve();
                        }
                        catch(err){
                            return Promise.reject(err.response && err.response.data && err.response.data.Errors);  
                        }            
                    }}
                ]}
            >
                <Input id="patternName" placeholder="PATTERN NAME" />
            </FormItem>
        </div>
    )
}
