import React from 'react';
import StateBehaviors, {getStateData} from './StateBehaviors';
import SingleBehaviors, {getSingleData} from './SingleBehaviors';
import Selector, {getSelectorData} from './SelectorBehaviors';
import Description, {getDescriptionData} from './Description';
import ConfirmAction, {getConfirmActionData} from './ConfirmAction';

export const handleDynamicBehaviors = (form, selectedOption, codefixEditData, patternId) => {
    if (selectedOption) {
      switch (selectedOption.type) {
        case "state":
          return (
            <StateBehaviors
              form={form}
              codefixEditData={codefixEditData}
              selectedOption={selectedOption}
              patternId={patternId}
            />
          );
        case "single":
          return (
            <SingleBehaviors
              form={form}
              codefixEditData={codefixEditData}
              selectedOption={selectedOption}
            />
          );
        case "selector":
            return (
              <Selector
                form={form}
                codefixEditData={codefixEditData}
                selectedOption={selectedOption}
                patternId={patternId}
              />
            );
        case "lang":
          if(selectedOption.key === "description"){
            return (
              <Description 
                  form={form}
                  codefixEditData={codefixEditData}
                  selectedOption={selectedOption}
                  patternId={patternId}
              />
            )
          }
          else if(selectedOption.key === "confirmAction"){
            return (
              <ConfirmAction 
                  form={form}
                  codefixEditData={codefixEditData}
                  selectedOption={selectedOption}
                  patternId={patternId}
              />
            )
          }
          break;
        default:
            break;
      }
    }
    return false;
};

export const getBehaviorData = (form, selectedBehavior, description, patternId) => {
  let type = selectedBehavior.type || selectedBehavior.fullData.type?.toLowerCase();
  if(type === "lang"){
    type = selectedBehavior.key || selectedBehavior.fullData.key?.toLowerCase();
  }
  return behaviorTypes[type].getData(form, selectedBehavior, description, patternId);
}

const behaviorTypes = {
    simple: {
        getData: () => getSimpleData()
    },
    state: {
        getData: (form, selectedBehavior, patternId) => getStateData(form, selectedBehavior, patternId)
    },
    single: {
        getData: (form) => getSingleData(form)
    },
    selector: {
        getData: (form) => getSelectorData(form)
    },
    description: {
      getData: (form, selectedBehavior, description) => getDescriptionData(form, description)
    },
    confirmAction: {
      getData: (form, selectedBehavior, description) => getConfirmActionData(form, description)
    }
}


const getSimpleData = () => {
    return {}
}