import { LS_ACCESS_TOKEN } from 'Auth/AuthContext';
import {toastSuccess} from 'Shared/utils';
import * as SignalR from "@microsoft/signalr";

export const SOCKET_KEYS = {
    JOIN_GROUP: "JoinGroup",
    LEAVE_GROUP: "LeaveGroup",
    SEND_MESSAGE: "SendMessage",
    NEW_MESSAGE: "NewMessage",
    RECEIVE_MESSAGE: "ReceiveMessage"
}

const getToken = () => window.localStorage.getItem(LS_ACCESS_TOKEN);

class SocketService {
    messages = [];

    connect(url){
        const token = getToken();
        if(token){
            this.connection = new SignalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_SOCKET_ADDRESS}notification`, { accessTokenFactory: () => token })
            .withAutomaticReconnect()
            .configureLogging(SignalR.LogLevel.Information)
            .build();

            this.connection.start()
            .then(() => {
                console.log("socket connection succeed");
                this.invokeSocketAction(SOCKET_KEYS.JOIN_GROUP, url);
            })
            .catch(err => {
                console.log('SignalR Connection Error: ' + err);
            });
        }
    }

    addEvent(event, callback){
        this.connection.on(event, (msg) => {
            this.messages.push(msg);
            callback(this.messages);
            let message = `${msg.type} '${msg.name}' was ${msg.action} by ${msg.userName}`;
            if(msg.type === "rollback"){
                message = `Rollback was made by ${msg.userName}`;
            }
            toastSuccess(message);
        });
    }

    invokeSocketAction(key, url){
        this.connection.invoke(key, url).catch(err => console.log(err));
    }

    sendMessage(key,url, msg){
        this.connection.invoke(key, url, msg).catch(err => console.error(err));
    }
}

export const socketService = new SocketService();