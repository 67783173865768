import React from "react";
import styled from "styled-components";
import logo from "Shared/Icons/logo.svg";
import laptop from "Shared/Icons/laptop.svg";
import AddPatternButton from "./Sidebar/AddPattern/AddPatternButton";
import { Carousel } from "antd";
import { colors, backgrounds } from "Shared/colors";

const EmptyPage = styled.div`
  display: grid;
  grid-template-rows: 40px 30px calc(90vh - 160px);
  align-items: center;
  justify-items: ${(props) => (props.isMobile ? "none" : "center")};
  padding: ${(props) => (props.isMobile ? "41px 0 0 0" : "3rem 2rem 0 2rem")};
`;

const Title = styled.div`
  font-size: ${(props) => (props.isMobile ? "18px" : "1.6rem")};
  font-weight: 700;
  color: black;
  opacity: 0.6;
  img {
    filter: invert(1);
    margin-right: .4rem;
  }
  span {
    font-weight: 300;
  }
  ${(props) => (props.isMobile ? "41px 0 0 0" : "3rem 2rem 0 2rem")};
  ${(props) =>
    props.isMobile &&
    `
    justify-self: center;
    margin-right: 10%;
  `};
`;
const SubTitle = styled.div`
  color: black;
  opacity: 0.5;
  font-weight: 700;
  font-size: ${(props) => (props.isMobile ? "12px" : "0.8rem")};
  ${(props) =>
    props.isMobile &&
    `
    justify-self: center;
    margin-right: 10%;
  `};
`;

const Keyboard = styled.div`
  display: grid;
  grid-template-rows: 180px auto;
  grid-gap: 4rem;
  opacity: 0.6;
  width: 500px;
  align-items: center;
  ${(props) => !props.isMobile && `justify-items: center;`}
`;

const Laptop = styled.img`
  width: 300px;
  ${(props) =>
    props.isMobile &&
    `
    margin-left: 2rem !important;
  `}
`;

const Keystroke = styled.div``;

const Keys = styled.div`
  display: grid;
  grid-template-columns: 10rem 3rem 20px 3rem 20px 3rem;
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 0.6rem;
  ${(props) => (props.isMobile ? "12px" : "0.6rem")}
`;

const KeysTitle = styled.div`
  color: grey;
  line-height: 1.7rem;
  font-weight: 600;
  text-align: right;
  margin-right: 1rem;
`;

const Key = styled.div`
  border: 1px solid #999;
  border-radius: 0.3rem;
  background: #c1c1c1;
  color: grey;
  font-weight: 600;
  padding: 0.2rem;
`;

const StyledCarousel = styled(Carousel)`
  width: 500px;
  .slick-dots {
    bottom: -40px;
    li button {
      background: #000 !important;
      border-radius: 10px;
      height: 10px;
    }
  }
`;

const Lists = styled.div`
    display: grid!important;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    display: grid;
    grid-template-columns: 18px auto;
    grid-gap: 1rem;
    font-weight: 600;
    font-size: .7rem;
    color: grey;
    text-transform: uppercase;
    div {
      border-radius: 10%;
      height: 18px;
      width: 18px;
      margin: 2px 2px 0 0;
      text-align: center;
      line-height: 14px;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
    }
  }
`;

const DefaultRoute = ({ isMobile }) => {
  const subTitleText = isMobile
    ? `Click "ADD NEW PATTERN" to start`
    : `Click "ADD NEW PATTERN" on the bottom left corner to start`;
  return (
    <EmptyPage isMobile={isMobile}>
      <Title isMobile={isMobile}>
        <span>Welcome to </span>
        <img src={logo} alt="Logo" width="32" />
        uRemediate Studio
      </Title>
      <SubTitle isMobile={isMobile}>{subTitleText}</SubTitle>
      <Keyboard isMobile={isMobile}>
        <Laptop isMobile={isMobile} src={laptop} />
        <StyledCarousel autoplay autoplaySpeed={5000}>
          <Keystroke>
            <Keys isMobile={isMobile}>
              <KeysTitle>NEW PATTERN</KeysTitle>
              <Key>ALT</Key> + <Key>P</Key>
            </Keys>
            <Keys isMobile={isMobile}>
              <KeysTitle>PREVIEW</KeysTitle>
              <Key>ALT</Key> + <Key>SHIFT</Key> + <Key>P</Key>
            </Keys>
            <Keys isMobile={isMobile}>
              <KeysTitle>REFRESH</KeysTitle>
              <Key>ALT</Key> + <Key>SHIFT</Key> + <Key>R</Key>
            </Keys>
            <Keys isMobile={isMobile}>
              <KeysTitle>MINIMIZE</KeysTitle>
              <Key>ALT</Key> + <Key>SHIFT</Key> + <Key>M</Key>
            </Keys>
            <Keys isMobile={isMobile}>
              <KeysTitle>ZOOM +/-</KeysTitle>
              <Key>ALT</Key> + <Key>SHIFT</Key> + <Key>+/-</Key>
            </Keys>
          </Keystroke>
          <Lists>
            <List>
              <li>
                <div style={{ backgroundImage: backgrounds.NOT_FOUND }}>
                  <div></div>
                </div>
                selector not found on page
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.FOUND }}>
                  <div></div>
                </div>
                selector found
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.FOUND_MULTIPLE }}>
                  <div></div>
                </div>
                selector found more than once
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.BROKEN }}>
                  <div></div>
                </div>
                broken - parent selector not found on page
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.GREY }}>
                  <div></div>
                </div>
                hidden - hidden from preview
              </li>
            </List>
            <List>
              <li>
                <div style={{ backgroundImage: backgrounds.GREY }}>
                  <div>P</div>
                </div>
                PATTERN
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.GREY }}>
                  <div>G</div>
                </div>
                GROUP
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.GREY, borderRadius: '50%' }}>
                  <div>B</div>
                </div>
                BEHAVIOR
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.GREY, borderRadius: '50%' }}>
                  <div>R</div>
                </div>
                ROLE
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.GREY, borderRadius: '50%' }}>
                  <div>S</div>
                </div>
                SCRIPT
              </li>
              <li>
                <div style={{ backgroundImage: backgrounds.GREY, borderRadius: '50%' }}>
                  <div>W</div>
                </div>
                WIDGET
              </li>
            </List>
          </Lists>
        </StyledCarousel>
      </Keyboard>
      {isMobile && <AddPatternButton />}
    </EmptyPage>
  );
};

export default DefaultRoute;
