import React,{useEffect} from 'react';
import {Form, Input} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';

const DataTable = ({form, codefixEditData}) => {

    useEffect(() => {
        if(codefixEditData){
            form.setFieldsValue({
                rows: codefixEditData.data.rows,
                columns: codefixEditData.data.columns
            });
        }
    }, [codefixEditData])


    return (
        <div id="dataTable">
            <div>
                <TooltipLabel required value="ROW HEADERS" tooltip={{ text: "fill later" }} />
                <Form.Item name="rows" validateTrigger={['onChange']} >
                    <Input placeholder={`FILL NUMBER OF ROWS HEADERS`} />
                </Form.Item>
            </div>
            <div>
                <TooltipLabel required value="COLUMN HEADERS" tooltip={{ text: "fill later" }} />
                <Form.Item name="columns" validateTrigger={['onChange']} >
                    <Input placeholder={`FILL NUMBER OF COLUMNS HEADERS`} />
                </Form.Item>
            </div>
        </div>
    )
}

export const getDataTableData = (form) => {
    let value = form.getFieldsValue(["rows", "columns"]);
    return value;
}

export default DataTable;