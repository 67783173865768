import React, {useEffect} from 'react';
import {Form, Select} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';

const Languages = ({form, languages, setActiveLang, activeLang}) => {
    languages = languages && languages.map((lang) => {return {value: lang?.toUpperCase(), name: lang}})
    useEffect(() => {
        if(activeLang){
            form.setFieldsValue({
                language: activeLang
            });
        }
    }, [activeLang]);
    
    return (
        <div>
            <TooltipLabel value="LANGUAGE" tooltip={{ text: "fill later" }} />
            <Form.Item name="language">
                <Select onSelect={(value) => setActiveLang(value)} defaultValue={languages[0].value} options={languages} />
            </Form.Item>
        </div>
    )
}

export default Languages;
