import React, {useState, useEffect} from 'react';
import MenuItems from './MenuItems/Items';
import { Select, Form, Button, Switch, Collapse  } from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import styled from "styled-components";
import MenuSelector from './MenuSelector';
import {KEYS} from './consts';
import {useMenuWidget} from 'Main/Context/MenuWidget/MenuWidgetContext';
import {useCodefix} from 'Main/Context/Codefix/CodefixContext';
import {EditOutlined} from '@ant-design/icons';
import { colors } from 'Shared/colors';
import { useMediaQuery } from 'react-responsive'

const StyledSwitch = styled(Switch)`
    height: 2rem;
    width: 6rem;
    .ant-switch-handle{
        top: 7px !important;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 6rem;
    grid-gap: 10px;
    ${props => props.isMobile &&
        `
            grid-gap: 2px !important;
            *{
                font-size: 9px !important;
            }
        `
    }
`;

const IconContainer = styled.div`
    align-self: center;
    cursor: pointer;

    svg{
        width: 18px !important;
        height: 18px !important;
        margin-top: 10px;
    }
`;

const StyleSelect = styled.div`
    ${props => props.isScript &&
        `.ant-select-arrow{
            width: 33px;
            right: 0;
            height: 99%;
            top: 6px;
            background: ${colors.BLUE};
        
            svg {
                color: white;
                margin-top: 7px !important; 
            }
        }`
    }
`

const MenuConfiguration = ({patternId, index, setMenuAllowAddButton, form, uniqueName, menu, codefixEditData, menuSelector, setMenuSelector}) => {
    const [menuSelectorErrors, setMenuSelectorErrors] = useState(null);
    const [closeBtnSelectorErrors, setcloseBtnSelectorErrors] = useState(null);
    const [CloseButtonFieldVisible, setCloseButtonFieldVisible] = useState(false);
    const [closeButtonSelectorType, setCloseButtonSelectorType] = useState(false);
    const [closeByDisabled, setCloseByDisabled] = useState(false);
    const { menuScriptModal, setMenuScriptModal } = useCodefix();
    const {selectedScriptId, setSelectedScriptId, menuScriptsData, setScripts, editScriptData, setEditScriptData, editMenuSelectorArr, setEditMenuSelectorArr} = useMenuWidget();
    const [editMenuItems, setEditMenuItems] = useState(null);
    const uniqueMenuKey = `${KEYS.MENU_SELECTOR}_${uniqueName}`;
    const closeById = `${KEYS.CLOSE_BY}_${uniqueMenuKey}`;
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)'
    });

    const options = [
        {value: "closeByBlur", label: "BLUR"},
        {value: "closeByScript", label: "SCRIPT"},
        {value: "closeByTriggerElement", label: "TRIGGER ELEMENT"},
        {value: "closeByCloseButton", label: "CLOSE BUTTON"},
        {value: "closeByEscape", label: "ESCAPE"}
    ];

    useEffect(() => {
        if(menu && menu.editData && codefixEditData){
            setCloseByDisabled(true);
            const closeByValue = menu.editData[KEYS.CLOSE_BY];
            updateMenuSelectorOnEdit(menu.editData.selector);
            setCloseButtonFieldVisible(closeByValue === "closeByCloseButton")
            closeByValue === "closeByScript" && onEditScript(menu.editData);
            form.setFieldsValue({
                [uniqueMenuKey]: menu.editData[KEYS.SELECTOR],
                [closeById]: menu.editData[KEYS.CLOSE_BY],
                [`${KEYS.ATTACHED_MENU}_${uniqueMenuKey}`]:menu.editData[KEYS.ATTACHED_MENU]
            });

            if(menu.editData[KEYS.CLOSE_BY] === "closeByCloseButton"){
                const isAbsolute = menu.editData.closeButtonIsRelative && menu.editData.closeButtonIsRelative.toLowerCase() === "absolute";
                form.setFieldsValue({
                    [`${KEYS.CLOSE_BUTTON_SELECTOR}_${uniqueMenuKey}`]: menu.editData[KEYS.CLOSE_BUTTON_SELECTOR],
                    [`${KEYS.ABSOLUTE_RELATIVE_SWTICH}_${uniqueMenuKey}`]: isAbsolute
                });
            }

            const secondLevel = codefixEditData.data.secondLevel;
            let relevantItems = secondLevel.filter(s => s.type === "menuWidgetItem");
            relevantItems = relevantItems.filter(item => item.values.target === menu.editData[KEYS.SELECTOR]);
            setEditMenuItems(relevantItems);
        }
    }, [menu, codefixEditData]);

    const updateMenuSelectorOnEdit = (selector) => {
        if(editMenuSelectorArr && !editMenuSelectorArr[`${uniqueMenuKey}`]){
            setEditMenuSelectorArr(editMenuSelectorArr.push({[`${uniqueMenuKey}`]: selector}));
        }
    }


    useEffect(() => {
        if(editMenuSelectorArr && editMenuSelectorArr.length > 0){
            let data = {};
            editMenuSelectorArr.forEach(s => data[Object.keys(s)] = s[Object.keys(s)])
            setMenuSelector({...menuSelector, ...data});
            setEditMenuSelectorArr([]);
        }
    })

    useEffect(() => {
        if((!menuSelectorErrors || !menuSelectorErrors.length)){
            const selectorValue =  form.getFieldValue(uniqueMenuKey);
            setMenuAllowAddButton(selectorValue && selectorValue !== "");
        }
        else {
            setMenuAllowAddButton(false);
        }
    }, [menuSelectorErrors])

    const onCloseByChange = (value, editData) => {
        setCloseButtonFieldVisible(value === "closeByCloseButton")
        
        if(value === "closeByScript"){
            openScriptModal();
        }
    }

    const onEditScript = (editData) => {
        setSelectedScriptId(closeById);
        let script = `var init: Init = ${editData.script}`;
        let data = editScriptData;
        data.push({[closeById]: script});
        setEditScriptData(data);
    }

    useEffect(() => {
        if(editScriptData && editScriptData.length > 0){
            let data = {};
            editScriptData.forEach(s => data[Object.keys(s)] = s[Object.keys(s)])
            setScripts({...menuScriptsData, ...data});
            setEditScriptData([]);
        }
    })

    const openScriptModal = () => {
        setMenuScriptModal(true);
        setSelectedScriptId(closeById);
    }

    const closeByHasScript = selectedScriptId === closeById || menuScriptsData && menuScriptsData[closeById];

    return (
        <>
            <Container isMobile={isMobile}>
                <div>
                    <MenuSelector menuSelector={menuSelector} setMenuSelector={setMenuSelector} form={form} patternId={patternId} selectorErrors={menuSelectorErrors} setErrors={setMenuSelectorErrors} name={uniqueMenuKey} label="MENU SELECTOR" isAbsolutSelector={true}/>
                </div>
                <StyleSelect isScript={closeByHasScript}> 
                    <TooltipLabel required value="CLOSE BY" tooltip={{ text: "fill later" }} />
                    <Form.Item initialValue={options[0].value} name={closeById}>
                        <Select disabled={closeByDisabled} onSelect={onCloseByChange} options={options} suffixIcon={
                            closeByHasScript
                            &&
                            <IconContainer onClick={() => openScriptModal()}>
                                <EditOutlined style={{color: colors.BUTTON_BLUE}}/>
                            </IconContainer>
                        }/>
                    </Form.Item>
                </StyleSelect>
                <div style={{marginTop: '1.5rem'}}>
                    <Form.Item valuePropName="checked" name={`${KEYS.ATTACHED_MENU}_${uniqueMenuKey}`}> 
                        <StyledSwitch checkedChildren="ATTACHED" unCheckedChildren="ATTACHED" />
                    </Form.Item> 
                </div>
            </Container>
            {
                CloseButtonFieldVisible &&
                <Container>
                    <div>
                        <MenuSelector form={form} currentMenuSelector={uniqueMenuKey} isAbsolutSelector={closeButtonSelectorType} patternId={patternId} selectorErrors={closeBtnSelectorErrors} setErrors={setcloseBtnSelectorErrors} name={`${KEYS.CLOSE_BUTTON_SELECTOR}_${uniqueMenuKey}`} label="CLOSE BUTTON SELECTOR" />
                    </div>
                    <div style={{marginTop: '1.5rem'}}>
                        <Form.Item valuePropName="checked" name={`${KEYS.ABSOLUTE_RELATIVE_SWTICH}_${uniqueMenuKey}`}> 
                            <StyledSwitch onChange={(checked) => setCloseButtonSelectorType(checked)} checkedChildren="ABSOLUTE" unCheckedChildren="RELATIVE" />
                        </Form.Item>
                    </div>
                </Container>
            }
            <div>
                <MenuItems editSecondLevelMenuItems={editMenuItems} form={form} patternId={patternId} currentMenuSelector={uniqueMenuKey} name={`secondLevel_${uniqueMenuKey}`}/>
            </div> 
        </>
    )
}

export default MenuConfiguration;