export const KEYS = {
    OPEN_ATTACHED_MENU: "openAttachedMenu",
    TYPE: "type",
    SELECTOR: "selector",
    ATTACHED_MENU: "attachedMenu",
    CLOSE_BY: "closeBy",
    MENU_SELECTOR: "menuSelector",
    ABSOLUTE_RELATIVE_SWTICH: "absoluteRelativeSwitch",
    CLOSE_BUTTON_SELECTOR: "closeButtonSelector",
    DIRECTION: "direction",
    SCRIPT: "openByExecuteScript"
}