import { sendMessage, recieveMessage } from "Shared/messages";

export const keyStrokesService = {
    registered: {
        refresh: false,
        preview: false,
        addPattern: false,
        patternLayers: false,
        zoomIn: false,
        zoomOut: false
    },
    addEvent: (condition, callback) => {
        const listener = (e) => condition(e) && callback();
        keyStrokesService.removeEvent(listener);
        document.addEventListener("keydown", listener, true);
    },
    removeEvent: (listener) => {
        document.removeEventListener("keydown", listener, true);
    },
    refresh: (callback) => {
        const isRegistered = keyStrokesService.registered.refresh;
        const condition = (e) => e.altKey && e.shiftKey && e.keyCode === 82;
        !isRegistered && keyStrokesService.addEvent(condition, callback);
        keyStrokesService.registered.refresh = true;
    },
    preview: (callback) => {
        const isRegistered = keyStrokesService.registered.preview;
        const condition = (e) => e.altKey && e.shiftKey && e.keyCode === 80;
        !isRegistered && keyStrokesService.addEvent(condition, callback);
        keyStrokesService.registered.preview = true;
    },
    addPattern: (callback) => {
        const isRegistered = keyStrokesService.registered.addPattern;
        const condition = (e) => e.altKey && e.keyCode === 80;
        !isRegistered && keyStrokesService.addEvent(condition, callback);
        keyStrokesService.registered.addPattern = true;
    },
    patternLayers: (callback) => {
        const isRegistered = keyStrokesService.registered.patternLayers;
        const condition = (e) => e.altKey && e.shiftKey && e.keyCode === 76;
        !isRegistered && keyStrokesService.addEvent(condition, callback);
        keyStrokesService.registered.patternLayers = true;
    },
    minimize: (callback) => {
        const condition = (e) => e.altKey && e.shiftKey && e.keyCode === 77;
        sendMessage({ type: "keystrokes", key: "minimize" });
        keyStrokesService.addEvent(condition, callback);
    },
    zoomIn: (callback) => {
        const isRegistered = keyStrokesService.registered.zoomIn;
        const condition = (e) => e.altKey && e.shiftKey && e.key === "+";
        sendMessage({ type: "keystrokes", key: "zoomIn" });
        keyStrokesService.addEvent(condition, callback);
        keyStrokesService.registered.zoomIn = true;
    },
    zoomOut: (callback) => {
        const isRegistered = keyStrokesService.registered.zoomOut;
        const condition = (e) => e.altKey && e.shiftKey && (e.key === "-" || e.key === "_");
        sendMessage({ type: "keystrokes", key: "zoomOut" });
        keyStrokesService.addEvent(condition, callback);
        keyStrokesService.registered.zoomOut = true;
    }
}