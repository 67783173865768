
export const createDictionary = (patterns) => {
  let data = {};

    patterns.forEach((pattern) => {
        let selector = pattern.selector;
        let obj = {
          selector: selector,
          id: pattern.id,
          url: pattern.url,
          parent_from_selector: pattern.parent_from_selector || 0,
          name: pattern.name,
          elements: pattern.elements.map((item) => { return {...item, parent: pattern.selector} }),
          type: pattern.type,
          patterns: {},
          parent: pattern.parent || null,
          count: pattern.count,
          group: pattern.grouped,
          visible: pattern.visible
        }
        
        if(pattern.children.length > 0){
            pattern.children = pattern.children.map((item) => { return {...item, parent: pattern.selector} });
            let result = createDictionary(pattern.children);
            obj.patterns = {...obj.patterns, ...result};
        }

        data[pattern.id] = obj;
    })

    return data;
}


export const getChidrensIdsByPatternId = (patterns, patternId) => {
  let children = [];

    patterns.forEach((pattern) => {
        if(patternId === pattern.id){
          let ids = getChidrensId(pattern.children);
          children = children.concat(ids);
        }
        else if(pattern.children.length > 0){
          let result = getChidrensIdsByPatternId(pattern.children, patternId);
          children = children.concat(result);
        }
    });
    return children;
}

const getChidrensId = (children) => {
  let ids = [];

  children.forEach((child) => {
      ids.push(child.id);
      if(child.children.length > 0){
        const result = getChidrensId(child.children);
        ids = ids.concat(result);
      }
  })
  return ids;
}
