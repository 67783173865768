import React, {useEffect, useState} from "react";
import {colors} from "Shared/colors";
import {LeftOutlined} from "@ant-design/icons";
import styled, {css} from "styled-components";
import "styled-components/macro";
import {useScripterPreview} from "Scripter/Context/PreviewContext";
import {navigate} from "@reach/router";
import ErrorDetails from "./ErrorDetails";
import {camelCase} from "camel-case";
import {Tooltip} from "antd";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Main = styled.main`
  background-color: ${colors.SCRIPTER_PREVIEW_BLACK};
`;

const NavigationContainer = styled.div`
  background: linear-gradient(to right, #360095, #4e0e7e);
  padding: 0 10px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 500;
  line-height: 0px;

  color: white;

  border-radius: 5px;
  border: solid white 1px;
  background-color: ${colors.SCRIPTER_PREVIEW_BLACK};

  padding: 10px;

  :hover {
    cursor: pointer;
  }
`;

const TextButton = styled(Button)`
  gap: 2px;

  color: white;
  border: none;
  background-color: transparent;

  padding: 0;
`;

const ErrorDetailsContainer = ({validatorsErrors}) => {
    const {activeError, setActiveError} = useScripterPreview();
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState();

    useEffect(() => {
        if (validatorsErrors) {
            const priority = ["Blocker", "Critical", "Major", "Minor", "Low"];
            const data = priority
                .map((p) => validatorsErrors[p] && Object.values(validatorsErrors[p]).map((e) => e))
                .flat()
                .filter((i) => i);

            setActiveError(data.find(d => d.Code == activeError.Code));
        }
    }, [validatorsErrors]);

    const isErrorFixable = (errorCode) => {
        return !(errorCode === 'missingViewport');
    };

    const getTextToCopy = () => {
        const errorCode = (activeError.Code).replace(".", "");
        const apiCall = `api.${camelCase(errorCode)}([]);`;
        return apiCall;
    };

    const setClipboardText = (text) => {
        setIsCopiedToClipboard(true);
        setTimeout(() => {
            setIsCopiedToClipboard(false);
        }, 1000);
    }
    

    return (
        <Main>
            <NavigationContainer>
                <TextButton
                    onClick={() => {
                        navigate("/main/default");
                    }}
                >
                    <LeftOutlined
                        css={css`
              font-size: 15px;
            `}
                    />
                    Errors
                </TextButton>

                {isErrorFixable(activeError.Code) &&
                <Tooltip visible={isCopiedToClipboard} placement="bottom" title={"Copied!"}>
                    <CopyToClipboard text={getTextToCopy()}
                                     onCopy={(text) => setClipboardText(text)}>
                    <Button>
                        <svg
                            id="iconfinder_Magic_Wand_1021022"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                        >
                            <path
                                id="Path_30"
                                data-name="Path 30"
                                d="M12.6-243.023a4.473,4.473,0,0,1,.455.722l.338.634.65.343c.716.374.859.476.859.63a4.059,4.059,0,0,1-.435.762c-.343.517-.435.7-.435.839,0,.174.118.3,2,2.216a47.146,47.146,0,0,1,4.158,4.436.907.907,0,0,1-.143,1.218,1.059,1.059,0,0,1-.967-.031c-.486-.225-1.606-1.249-4.5-4.119-1.851-1.842-2.092-2.062-2.235-2.062a1.7,1.7,0,0,0-.66.328c-.956.624-1.089.594-1.591-.369l-.322-.619-.614-.317a4.185,4.185,0,0,1-.716-.445c-.189-.24-.118-.471.363-1.192l.322-.471-.036-.721c-.031-.665-.026-.731.066-.885.143-.23.373-.281,1.141-.24l.634.036.588-.394C12.165-243.146,12.38-243.208,12.6-243.023Zm-.639.85a3.844,3.844,0,0,0-.486.286l-.312.21-.583-.036c-.45-.026-.6-.02-.665.031s-.082.179-.072.686l.01.619-.245.384c-.317.5-.363.635-.266.783a2.052,2.052,0,0,0,.532.338l.46.225.2.4c.338.7.511.762,1.064.4l.338-.22-.159-.2a5.4,5.4,0,0,0-.517-.517c-.68-.609-.977-1.08-.864-1.382a2.84,2.84,0,0,1,.486-.517c.639-.573.783-.552,1.488.174.225.23.552.558.721.721l.317.3.261-.425a1.916,1.916,0,0,0,.261-.563c0-.169-.179-.322-.65-.547-.327-.154-.368-.195-.491-.445-.271-.547-.424-.737-.639-.783A.889.889,0,0,0,11.966-242.174Zm.261,2.9c-.23.2-.261.307-.153.517.092.179,5.483,5.593,6.48,6.5.609.558.8.66,1.059.578.148-.046.225-.235.184-.46-.031-.164-.261-.42-1.488-1.683-.8-.819-2.378-2.415-3.514-3.551C12.492-239.667,12.59-239.595,12.226-239.273Z"
                                transform="translate(-8.36 243.122)"
                                fill="#fff"
                            />
                        </svg>
                        Auto Correct
                    </Button>
                    </CopyToClipboard>
                </Tooltip>}

            </NavigationContainer>
            <ErrorDetails activeError={activeError}/>
        </Main>
    );
};

export default ErrorDetailsContainer;
