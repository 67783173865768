import React from "react";
import styled from "styled-components";
import { ellipsis } from "Shared/utils";
import { Popover, Dropdown, Button } from "antd";
import TreeMenu from "Main/Views/Sidebar/PatternsTree/TreeItem/TreeMenu";
import { MoreOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import TreeItemBadge from "Main/Views/Sidebar/PatternsTree/TreeItem/TreeItemBadge";
import { useTree } from 'Main/Context/TreeContext/TreeContext';

const StyledTitle = styled.div`
  color: white;
  display: grid;
  grid-template-columns: 15px auto 26px;
  grid-gap: 3px;
  padding: 0;
  height: 30px;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-tree-node-selected & .tree-pattern-name {
    border-radius: 5px;
    background-color: #333;
  }

  .tree-pattern-name {
    font-size: 0.7rem;
    text-indent: 0.2rem;
  }

  .tree-pattern-name:hover {
    border-radius: 5px;
    background-color: #222;
  }

  font-size: 0.8rem;
  img {
    width: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;

const MenuButton = styled(Button)`
  color: white;
  height: 30px;
  width: 30px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  svg {
    width: 26px;
    height: 26px;
    padding: 2px;
  }
`;

const ClickableTitle = styled.div`
  height: 30px;
  line-height: 30px;
`;

const TreeItemTitle = ({
  patternId,
  hash,
  url,
  name,
  count,
  data,
  type,
  marked,
  isBroken,
  visible
}) => {
  const { setSelectedItem } = useTree();
  let className = marked
    ? "site-tree-search-value tree-pattern-name"
    : "tree-pattern-name";
  let selector = data.selector;
  let popoverContent = data && selector && (
    <>
      <div>
        <span style={{ fontWeight: 700 }}>NAME:</span> {data.name}
      </div>
      <div>
        <span style={{ fontWeight: 700 }}>SELECTOR:</span> {selector}
      </div>
      {data.description && (
        <div>
          <span style={{ fontWeight: 700 }}>DESCRIPTION:</span>{" "}
          {data.description}
        </div>
      )}
    </>
  );

  const changeView = () => {
    switch (type) {
      case "pattern":
        setSelectedItem(data);
        navigate(`/main/${patternId}/codefix`);
        break;
      case "script":
        navigate(`/main/${patternId}/edit-codefix/script-${hash}`);
        break;
      case "behavior":
      case "role":
      case "widget":
        navigate(`/main/${patternId}/edit-codefix/${hash}`);
        break;
      default:
        break;
    }
  };
  return (
    <StyledTitle>
      <TreeItemBadge
        type={type == "pattern" && data.type == "group" ? "group" : type}
        count={count}
        isBroken={isBroken}
        visible={visible}
      />
      <span className={className}>
        <ClickableTitle onClick={changeView}>
          <Popover placement="right" content={popoverContent}>
            {ellipsis(name)}
          </Popover>
        </ClickableTitle>
      </span>
      <Dropdown
        overlay={TreeMenu({
          type,
          dataType: data.type,
          patternId,
          name,
          selector,
          url,
          isGroup: data.group,
          data,
          setSelectedItem
        })}
        placement="bottomCenter"
        arrow
        trigger="click"
      >
        <MenuButton type="link" icon={<MoreOutlined />} />
      </Dropdown>
    </StyledTitle>
  );
};

export default TreeItemTitle;
