import React, { useEffect, useState } from 'react';
import {Select, Tag} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import {tooltipsContent} from '../data';
import {FormItem} from '../PatternForm';
import { KEYS, OPTIONS } from '../consts';
import { SITE_SETTINGS_CONSTS } from 'GlobalContext/GlobalContext';

const devicesRuleOnSelected = (selectedValues, options) => {
    if(selectedValues.includes(OPTIONS.ALL_DEVICES)){
        options.forEach((item) => item.value !== OPTIONS.ALL_DEVICES && (item.disabled = "true"))
    }
    else {
        options.forEach((item) => item.value === OPTIONS.ALL_DEVICES && (item.disabled = "true"))
    }
}

const devicesRuleOnDeselect = (selectedValues, options) => {
    options.forEach(option => option.disabled = false);
    if(selectedValues.length && !selectedValues.includes(OPTIONS.ALL_DEVICES)){
        options.forEach((item) => item.value === OPTIONS.ALL_DEVICES && (item.disabled = "true"))
    } 
}

export const Devices = ({form, isBodySelector, constants, edit}) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if(isBodySelector && !edit){
            form.setFieldsValue({ [KEYS.SUPPORTED_DEVICES]: OPTIONS.ALL_DEVICES });
            devicesRuleOnSelected(form.getFieldValue(KEYS.SUPPORTED_DEVICES), options);
        }
    }, [isBodySelector, edit])

    useEffect(() => {
        if(constants){
            let data = constants[SITE_SETTINGS_CONSTS.DEVICES].map((option) => { 
                return option.value !== OPTIONS.ALL_DEVICES ? { value: option.value, disabled: true} : {value: option.value} 
            })
            setOptions(data);
        }
    }, [constants])

    return (
        <div>
            <TooltipLabel required value="SUPPORTED DEVICES" tooltip={{ text: tooltipsContent.supportedDevices }} />
            <FormItem rules={[{required: true, message: 'device required'}]} name={KEYS.SUPPORTED_DEVICES}>
                <Select
                    disabled={isBodySelector}
                    onSelect={() => devicesRuleOnSelected(form.getFieldValue(KEYS.SUPPORTED_DEVICES), options)}
                    onDeselect={() => devicesRuleOnDeselect(form.getFieldValue(KEYS.SUPPORTED_DEVICES), options)}
                    mode="multiple" 
                    tagRender={(props) => 
                        <Tag closable={!isBodySelector} 
                            onClose={() => {
                                props.onClose();
                                devicesRuleOnDeselect(form.getFieldValue(KEYS.SUPPORTED_DEVICES), options); 
                            }}
                        >
                            {props.value}
                        </Tag>
                    }
                    style={{ width: '100%' }}
                    options={options}
                />
            </FormItem>
        </div>
    )
}
