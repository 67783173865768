import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd';
import { Select, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import useAxios from 'axios-hooks'
import { colors } from 'Shared/colors';
import { toastError } from 'Shared/utils';

import "./ExecuteScript.css"
import styled from 'styled-components';

const { SubMenu, Item } = Menu;
const { Header } = Layout;
const { Option, OptGroup } = Select;
const StyledHeader = styled(Header)`
height: 50px;
background: ${colors.DARK90}!important;
.ant-menu{
    background: ${colors.DARK90}!important;
    height: 50px;
}
.ant-select-selector {
    border-radius: 5px!important;
}
`

const StyledDownOutlined = styled(DownOutlined)`
font-size: 0.5rem !important;
`;

const StyledMenu = styled(Menu)`
display:flex;
flex-direction: row;
align-items: center;
align-content:flex-start;
`;

const StyledSelect = styled(Select)`
flex: ${props => props.isMenuScript ? 'none' : 1};
width: ${props => props.isMenuScript ? '50%' : 'auto'};
z-index: 100 !important;
margin:0px 5px;
.ant-select-selector {
    color: white;
    background-color: ${colors.DARK90}!important;
    border-radius: 0!important;
    border: 0!important;
    border-bottom: 1px solid white!important;
}
.ant-select-arrow {
    color: white;
}
`
const StyledSubMenu = styled(SubMenu)`
flex:1;
max-width:150px;
`
const Title = styled.span``;
const ExecuteScriptHeader = ({ scriptType, library, snippets, insertSnippet, editorValue, editor, setEditorValue, runTimes, runtime, handleRuntimeChange, isMenuScript }) => {
    //Select->Option value is accepts valus as string (and not as objects), for performance considerations we pass splitted values to option in order to prevent looping latter
    const VALUE_SPLITTER = "!@#$%";
    const GITHUB_SCRIPTS = "@githubScripts@";

    //get script from server
    const [scriptName, setScriptName] = useState(null);
    const [{ data: scriptValue }, getSingleScript] = useAxios(
        {
            method: 'GET',
            url: `/scripts/github/${scriptName}`,
            headers: {
                'content-type': 'application/json',
            }
        },
        { manual: true }
    )

    useEffect(() => {
        if (scriptName) {
            getSingleScript()
                .catch(error => {
                    toastError(error.response.data.Errors)
                });
        }
    }, [scriptName])


    useEffect(() => {
        if (scriptValue) {
            insertSnippet(editorValue, editor, setEditorValue, scriptValue.content);
        }
    }, [scriptValue])

    const sortByAlpha = (a, b) => {
        var nameA = a.name?.toUpperCase();
        var nameB = b.name?.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    const apiSelectFilterFunction = (input, option) => {
        if (option.children) {
            const val = option.children;
            if (typeof val === "object") {
                return val.props.children?.toLowerCase().includes(input?.toLowerCase());
            }
            else if (typeof val === "string") {
                return val?.toLowerCase().includes(input?.toLowerCase());
            }
        }
        else {
            return false;
        }
    }

    const apiSelectOnChange = (optionVal) => {
        if (optionVal) {
            const splittedVal = optionVal.split(VALUE_SPLITTER);
            const content = splittedVal[0];
            const value = splittedVal[1] ?? null;
            if (content === GITHUB_SCRIPTS) {
                setScriptName(value)
            } else {
                insertSnippet(editorValue, editor, setEditorValue, content, value)
            }
        }
    }

    return (
        < StyledHeader >
            <StyledMenu
                selectable={false} theme="dark" mode="horizontal" className="executeScriptMenu">
                {/* <StyledSubMenu popupClassName="executeScriptSubMenu" title={<Title>Import <StyledDownOutlined /></Title>} >
                    {
                        library && library.map((name, i) =>
                            <Item
                                onClick={() => {
                                    setScriptName(name)
                                }}
                                key={name}>
                                {name}
                            </Item>
                        )
                    }
                </StyledSubMenu> */}
                {/* {
                    runtime && snippets && Object.entries(snippets).map(([key, value]) =>
                        key !== "runtimes" && <StyledSubMenu
                            key={key}
                            popupClassName="executeScriptSubMenu"
                            title={key === "apis" ? <Title>APIs <StyledDownOutlined /></Title> : <Title>Conditions <StyledDownOutlined /></Title>}>
                            {
                                value.categories && value.categories.sort(sortByAlpha)
                                && value.categories.map((categories, i) =>
                                    <SubMenu
                                        key={categories.name}
                                        popupClassName={categories.items.length < 10 ? "executeScriptSubMenu" : "executeScriptSubMenu executeScriptSubMenu2Cols"}
                                        title={categories.name}>
                                        {categories.items && categories.items.sort(sortByAlpha)
                                            && categories.items.map((item, j) =>
                                                (item.runtime.indexOf("0") !== -1 || item.runtime.indexOf(`${runtime.id}`) !== -1) &&
                                                console.log(item) &&
                                                <Item
                                                    onClick={() => { insertSnippet(editorValue, editor, setEditorValue, item.content, item.defaultValues) }}
                                                    key={item.name}>
                                                    <Tooltip placement="right" title={item.description} overlayStyle={{ position: "fixed" }}>
                                                        {item.name}
                                                    </Tooltip >
                                                </Item>
                                            )}
                                    </SubMenu>
                                )
                            }
                        </StyledSubMenu>
                    )
                } */}
                <StyledSelect
                    isMenuScript={isMenuScript}
                    value='APIs & Conditions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                    onChange={apiSelectOnChange}
                    showSearch
                    filterOption={apiSelectFilterFunction}
                    listHeight={480}
                >
                    {
                        runtime && snippets && Object.entries(snippets).map(([key, value]) =>
                            key !== "runtimes" &&
                            value.categories &&
                            value.categories.sort(sortByAlpha) &&
                            value.categories.map((categories, i) =>
                                <OptGroup
                                    key={categories.name}
                                    label={categories.name}>
                                    {categories.items && categories.items.sort(sortByAlpha)
                                        && categories.items.map((item, j) =>
                                            <Option
                                                disabled={!(item.runtime.indexOf("0") !== -1 || item.runtime.indexOf(`${runtime.id}`) !== -1)}
                                                value={item.content + VALUE_SPLITTER + (item.defaultValues ?? '')}
                                                key={item.name}>
                                                <Tooltip placement="right" title={item.description} overlayStyle={{ position: "fixed" }}>
                                                    {item.name}
                                                </Tooltip >
                                            </Option>
                                        )}
                                </OptGroup>
                            )
                        )
                    }
                    < OptGroup label="Github Scripts" >
                        {
                            library && library.map((name) =>
                                <Option value={GITHUB_SCRIPTS + VALUE_SPLITTER + name} key={name}>
                                    {name}
                                </Option>
                            )
                        }
                    </OptGroup>
                </StyledSelect>
                {
                    scriptType !== "menuScript" && runtime && runTimes && snippets && scriptType && < StyledSelect
                        defaultValue={runtime.id}
                        value={runtime.id}
                        optionLabelProp="label"
                        listHeight={480}
                        onChange={handleRuntimeChange}
                        theme="dark"
                        className="SelectExecuteScript"
                        dropdownClassName="SelectDropdownExecuteScript"
                    >
                        {
                            // scriptType === "scripts" ?
                            runTimes.map((category) =>
                                <OptGroup
                                    key={category.name}
                                    label={category.name}
                                >
                                    {category.items.map((runtime) =>
                                        <Option
                                            key={runtime.id}
                                            value={runtime.id}
                                            label={`Runtime: ${category.name !== "Other" ? `${runtime.name} ${category.name}` : `${runtime.description}`} `}
                                        >
                                            <Tooltip placement="right" title={runtime.description} overlayStyle={{ position: "fixed" }}>
                                                {runtime.name}
                                            </Tooltip >
                                        </Option>)}
                                </OptGroup >)
                            // :
                            // runTimes.items.map((runtime) =>
                            //     <Option
                            //         key={runtime.id}
                            //         value={runtime.id}
                            //         label={`Runtime: ${runtime.name}`}
                            //     >
                            //         {runtime.name}
                            //     </Option>)
                        }
                    </StyledSelect>
                }
            </StyledMenu>
        </StyledHeader >
    )
}

export default ExecuteScriptHeader;




