import React, { useEffect } from 'react';
import Main from './Main/Views';
import { Spin } from 'antd'
import { useAuth } from './Auth/AuthContext';
import Login from "./Auth";
import styled from 'styled-components';
import { recieveMessage, sendMessage } from 'Shared/messages';
import { toastError } from 'Shared/utils';
import './App.less';
import { axios } from 'Shared/apiClient'; // Do not remove, this is STD_client import
import { NotificationProvider } from './Main/Context/Notification/NotificationContext';
import { TreeProvider } from './Main/Context/TreeContext/TreeContext';
import { useGlobal } from 'GlobalContext/GlobalContext';
import { ErrorsSiteSettingsModal } from 'Shared/Components/ErrorsSiteSettingsModal'
import Scripter from './Scripter/Views/index'
import { ScripterProvider } from './Scripter/Context/ScripterContext'
import { SCRIPTER_PREVIEW_MODE } from "Scripter/Views/consts"
import { SwitchProvider } from 'Scripter/Context/SwitchContext';
import { VisibilityProvider } from 'Scripter/Context/ElementVisibilityContext';
import PreviewSpiner from 'Shared/Components/PreviewSpinner';


const StyledLoadingContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
${({ loading }) => loading && `background-color: #000000;`}
${({ loading }) => `height: ${loading ? '100vh;' : 'calc(97vh - 40px);'}`}
`

export const Loading = ({ msg }) => {
  return (
    <StyledLoadingContainer>
      <Spin tip={msg || ""} size="large"></Spin>
    </StyledLoadingContainer>
  )
}

const App = () => {

  const { isLoggedIn } = useAuth();
  const { errorsSiteSettings, siteSettings, scripterApp } = useGlobal();

  useEffect(() => {
    recieveMessage("error", (msg) => {
      if (msg && msg.error) {
        toastError(msg.error);
      }
    })
    sendMessage({ type: "urs_loaded" });
  }, [])

  useEffect(() => {
    if (isLoggedIn !== null) {
      const loadUrs = !isLoggedIn
        || (
          siteSettings &&
          !window.localStorage.getItem(`${SCRIPTER_PREVIEW_MODE}`) &&
          !window.sessionStorage.getItem(`${SCRIPTER_PREVIEW_MODE}`)
        )
        || errorsSiteSettings;
    }
  }, [siteSettings, errorsSiteSettings, isLoggedIn])

  const scripter =
    <VisibilityProvider>
      <SwitchProvider>
        <ScripterProvider>
          <Scripter />
        </ScripterProvider>;
      </SwitchProvider>
    </VisibilityProvider>

  const main =
    < TreeProvider >
      <NotificationProvider>
        <Main />
        {errorsSiteSettings && ErrorsSiteSettingsModal()}
      </NotificationProvider>
    </TreeProvider>;


  return (
    isLoggedIn === null ?
      <></>
      :
      isLoggedIn
        ?
        scripterApp === undefined || !siteSettings ?
          <StyledLoadingContainer loading>
            <PreviewSpiner />
          </StyledLoadingContainer>
          :
          siteSettings.scripter
            ?
            scripter
            :
            main
        :
        <Login />
  )
}

export default App;
