import React, { useState, useEffect } from 'react'
import { } from 'antd';
import { useScripter } from 'Scripter/Context/ScripterContext';
import MonacoEditor from 'react-monaco-editor';
import useAxios from "axios-hooks";
import { getCurrentUrl } from 'Shared/Services/getCurrentUrl';
import { findCriticalErrorCodes } from "../../../Utils/Utils";
import { toastError, toastSuccess } from "../../../../Shared/utils";
import { wrapperFunctionSingature } from './functionWrapperSignature';
import { promiseMessage } from 'Shared/messages';
import { apiClient } from 'Shared/apiClient';

const Editor = ({ thisTab, activeTabKey }) => {
    const {
        treeData,
        SaveScript,
        sideBarCollapsed,
        changeAcordingToMarkers,
        searchValue,
        setSearchValue,
        scopeModalProps,
        setUrlChangeProps,
        setScriptSaveData,
        resizeFlag,
        setResizeFlag
    } = useScripter();

    const [{ data: declarationData, loading: declarationLoading, error: declarationError }, fetchDeclaration,] = useAxios(
        {
            method: "POST",
            url: `/scripter/declaration`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const [{ data: scopeData, error: scopeError }, getScopeSelector] = useAxios(
        {
            method: "GET",
            url: `/scripter/scope/${scopeModalProps.id}`,
            headers: {
                "content-type": "application/json",
            },
        },
        {manual: true}
    );

    const [editor, setEditor] = useState(null);
    const [monaco, setMonaco] = useState(null);
    const [timeoutFlag, setTimeoutFlag] = useState(null);
    const [id, setId] = useState(null)
    const languageDict = {
        js: "typescript",
        html: "html",
        json: "json"
    };

    useEffect(() => {
        if (monaco && editor && id) {
            onChange(editor.getValue());
            onSave(monaco, editor, thisTab);
        }
    }, [monaco, editor, id, thisTab, treeData]);

    useEffect(() => {
        (async () => {
            const url = await getCurrentUrl();
            if (url) {
                fetchDeclaration({ data: { url } })
            }
        })();
    }, []);

    useEffect(() => {
        if (monaco && declarationData) {
            monaco.languages.typescript.typescriptDefaults.setExtraLibs([
                {
                    content: `declare global {
                        interface ParentNode {
                            querySelector<K extends keyof HTMLElementTagNameMap>(selectors: K): HTMLElementTagNameMap[K] | null;
                            querySelector<K extends keyof SVGElementTagNameMap>(selectors: K): SVGElementTagNameMap[K] | null;
                            querySelector<E extends Element = HTMLElement>(selectors: string): E | null;
                            querySelectorAll<K extends keyof HTMLElementTagNameMap>(selectors: K): NodeListOf<HTMLElementTagNameMap[K]>;
                            querySelectorAll<K extends keyof SVGElementTagNameMap>(selectors: K): NodeListOf<SVGElementTagNameMap[K]>;
                            querySelectorAll<E extends Element = HTMLElement>(selectors: string): NodeListOf<E>;
                        }
                        interface Window {
                            u1st: U1st;
                        }
                        type Init = (api: U1st) => void
                        ${declarationData}
                    }
                    export { }
                ` },
            ]);
        }
    }, [monaco, declarationData]);

    const onSave = async (monaco, editor, thisTab) => {
        editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, async () => {
            const editorValue = editor.getValue();
            const errors = monaco.editor.getModelMarkers().filter(m => m.severity === 8 && m.resource.path === id);
            const foundCriticalCodes = findCriticalErrorCodes(errors);
            if (!foundCriticalCodes) {
                const validationResult = await checkSelectorValidation(editorValue);
                if (validationResult) {
                    const currentUrl = await getCurrentUrl();
                    if (currentUrl && thisTab.originUrl && currentUrl != thisTab.originUrl) {
                        setScriptSaveData({thisTab, editorValue, errors: errors?.length})
                        setUrlChangeProps({visible: true, originUrl: thisTab.originUrl, validationError: validationResult});
                    } else {
                        toastError(validationResult);
                    }
                } else {
                    SaveScript(thisTab, editorValue, errors?.length);
                    toastSuccess("Changes saved successfully!");
                }
            } else {
                toastError(foundCriticalCodes.message);
            }
        });
    }

    const checkSelectorValidation = async (editorValue) => {
        let selectorValidation = null;
        if (editorValue.includes("var handler")) {
            let scope;
            try {
                scope = (await apiClient(`/scripter/scope/${thisTab.path[thisTab.path.length - 1]}`)).data.selector;
            } catch (e) {
                scope = "body";
            }
            selectorValidation = await promiseMessage({ type: "save_scripter_validation", content: editorValue, scope });
        }
        return selectorValidation?.result;
    }

    const editorDidMount = (_editor, _monaco) => {
        setEditor(_editor);
        setMonaco(_monaco);
        setId(`/${_editor._id}`);
        _editor.focus();
    }

    const scanEditor = (newValue) => {
        let changeFlag = false;
        let changeDetected = thisTab.changeDetected;
        let errorDetected = thisTab.errorDetected;
        if (thisTab.content && (thisTab.content.length !== newValue.length || thisTab.content !== newValue)) {
            if (!thisTab.changeDetected) {
                changeFlag = true;
                changeDetected = true;
            }
        } else if (thisTab.changeDetected) {
            changeFlag = true;
            changeDetected = false;
        }
        const errors = monaco?.editor.getModelMarkers().filter(m => m.severity === 8 && m.resource.path === id);
        if (errors?.length > 0) {
            if (!thisTab.errorDetected) {
                changeFlag = true;
                errorDetected = true;
            }
        } else if (thisTab.errorDetected) {
            changeFlag = true;
            errorDetected = false;
        }

        if (changeFlag) {
            changeAcordingToMarkers(thisTab.key, changeDetected, errorDetected);
        }

    }

    const onChange = (newValue, e) => {
        if (timeoutFlag) {
            clearTimeout(timeoutFlag);
        }
        setTimeoutFlag(setTimeout(() => scanEditor(newValue), 1000))
    }

    const options = {
        selectOnLineNumbers: true
    };


    useEffect(() => {
        if (editor && activeTabKey == thisTab.key) {
            editor.layout();
        }
    }, [activeTabKey]);

    useEffect(() => {
        if (editor && sideBarCollapsed != null) {
            if (!sideBarCollapsed || resizeFlag) {
                setTimeout(() => {
                    editor && editor.layout();
                }, 300)
            }
            setResizeFlag(false);
        }
    }, [editor, sideBarCollapsed]);

    useEffect(() => {
        if (editor) {
            setTimeout(() => {
                setSearchValue("");
                if (searchValue && searchValue !== "") {
                    const range = editor.getModel().findMatches(searchValue)[0]?.range;
                    if (range) {
                        editor.setSelection(range);
                        editor.revealLineInCenter(range.startLineNumber);
                    }
                }
            }, 500);
        }
    }, [searchValue, editor]);

    useEffect(() => {
        if (monaco && editor) {
            onChange(editor.getValue());
        }
    }, [monaco, editor]);

    const shouldWrap = !thisTab.content && thisTab.title?.endsWith(".js") && thisTab.title !== "scope.js";
    const initialValue = shouldWrap ? wrapperFunctionSingature.trim() : thisTab.content;

    return (
        <MonacoEditor
            language={languageDict[thisTab.extension ? thisTab.extension.replace(".", "") : "js"]}
            theme="vs-dark"
            defaultValue={initialValue}
            options={options}
            onChange={onChange}
            editorDidMount={editorDidMount}
        />
    )
}

export default Editor;