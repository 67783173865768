import styled from 'styled-components';
import { colors } from 'Shared/colors';
import { Input, Form, Tabs, Button } from 'antd';

const StyledForm = styled(Form)`
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-flow: column; 
`

const ScrollContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 1250px) {
        height: 95px;
    }

    @media (min-width: 1250px) { 
        height: ${props => props.count && props.count > 1 ? '168px' : '95px'};
    }

    ::-webkit-scrollbar {
        width: 0.5rem;
    }
  
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  
    ::-webkit-scrollbar-thumb {
        background-color: #5977AD;
        outline: 1px solid #5977AD;
        border-radius: 20px;
    }

    .ant-form-item-children-icon{
        margin-right: 1rem;
    }
`

const SelectorContainer = styled.div`
    @media (max-width: 1250px) {
        height: 95px;
    }

    @media (min-width: 1250px) { 
        height: ${props => props.count && props.count > 1 ? '168px' : '95px'};
    }
`


const StyledTabs = styled(Tabs)`
    @media (max-width: 1250px) {
        height: 13rem;
    }

    @media (min-width: 1250px) { 
        height: ${props => props.count && props.count > 1 ? '17.5rem' : '13rem'};
    }
`

const SaveButton = styled(Button)`
    color: white;
    background: ${colors.BLUE};
    font-weight: 700;
    margin-left: 1rem;
`


const LangInput= styled(Input)`
    margin-left: ${props => props.isLongLangCode ? 0 : '1.4rem'};

    @media (max-width: 1250px) {
       width: ${props => props.isLongLangCode ? '98%' : '93%'};
    }

    @media (min-width: 1250px) { 
        width: ${props => props.isLongLangCode ? '99%' : '96%'};
    }

`

export {LangInput, SaveButton, SelectorContainer, ScrollContainer, StyledForm, StyledTabs};