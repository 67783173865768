import React from 'react';
import { Modal } from 'antd';

export const ErrorsSiteSettingsModal = () => {
    Modal.error({
        centered: true,
        keyboard: false,
        title: 'This website cannot be loaded',
        okType: 'text',
        okText: ' ',
        content: (
            <div>
                <br />
                Please check possible causes and come back:
                <ol>
                    <li>Domain is not defined for this website. </li>
                    <li>Your user has no permissions to view this website. </li>
                </ol>
            </div>
        ),
    })
}