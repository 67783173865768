import React, { useState } from 'react'
import { Layout, Tree } from 'antd';
import { colors } from 'Shared/colors';
import styled from "styled-components";
import { useScripter } from '../../../Context/ScripterContext';
import NodeMenuPopup from './NodeMenuPopup';
import TreeNodeIcon from './TreeNodeIcon';
import { DownOutlined } from '@ant-design/icons';


const { DirectoryTree } = Tree;


const { Content } = Layout;

const StyledContent = styled(Content)`
    padding:0 0 0 10px;
    background: ${colors.BLACK};
    color: white;
    z-index: 1;
    height: calc(100vh - 80px);
    overflow:auto;

    ::-webkit-scrollbar {
        width: 0.8rem !important;
      }
    
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
      }
    
      ::-webkit-scrollbar-thumb {
        background-color: transparent !important;
        outline: 0px solid slategrey !important;
        border-radius: 0px !important;
      }

      :hover{
        ::-webkit-scrollbar-thumb {
            background-color: ${colors.BLACK} !important;
            outline: 0px solid slategrey !important;
            border-radius: 0px !important;
          }
          ::-webkit-scrollbar-thumb:hover {
            background-color: ${colors.DARK60} !important;
            outline: 0px solid slategrey !important;
            border-radius: 0px !important;
          }
      }
`
const StyledDirectoryTree = styled(DirectoryTree)`
    background: ${colors.BLACK};
    color: white;
    font-size: .7rem;
    .ant-tree-treenode:not(.ant-tree-treenode-selected){
        :before{
            background: ${colors.BLACK} !important;
        }
    }
    .ant-tree-treenode-selected{
        :before{
            background: ${colors.DARK60} !important;
        }
    }
    .ant-tree-treenode:not(.ant-tree-treenode-selected):hover{
        :before{
            background: ${colors.DARK70} !important;
        }
    }
`


const TreeContent = () => {

    const {
        selectedTreeNode,
        setSelectedTreeNode,
        expandedKeys,
        setExpandedKeys,
        treeData,
        MoveTreeNode
    } = useScripter();

    const [nodeMenuPopupState, setNodeMenuPopupState] = useState({ visible: false });

    const rightClickMenuTrigger = ({ event, node }) => {
        if (!nodeMenuPopupState.visible) {
            document.addEventListener(`click`, function onClickOutside(e) {
                if (e.target.parentElement.getAttribute("data") != "u1st_prevent_close_popup") {
                    setNodeMenuPopupState({ visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                }
            })
        }
        setNodeMenuPopupState({
            node,
            visible: true,
            x: event.clientX,
            y: event.clientY
        })
    };

    return (
        <StyledContent>
            {
                treeData != null &&
                <>
                    <StyledDirectoryTree
                        switcherIcon={<DownOutlined />}
                        onSelect={(keys, { node }) => setSelectedTreeNode(node)}
                        onExpand={setExpandedKeys}
                        treeData={treeData}
                        selectedKeys={[selectedTreeNode?.key]}
                        expandedKeys={expandedKeys}
                        onRightClick={rightClickMenuTrigger}
                        icon={(props) => TreeNodeIcon(props)}
                        draggable={true}
                        onDragEnter={info => setExpandedKeys([...new Set(expandedKeys.concat(info.expandedKeys))])}
                        onDrop={e => MoveTreeNode(e.dragNode, e.node, e.dropToGap)}
                    />
                    <NodeMenuPopup nodeMenuPopupState={nodeMenuPopupState} setNodeMenuPopupState={setNodeMenuPopupState} />
                </>
            }
        </StyledContent>
    )
}

export default TreeContent;