import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Checkbox } from 'antd';

const StyledCheckbox = styled(Checkbox)`
.ant-checkbox-inner{
    background-color: transparent !important;
    border: 1px solid white;
    border-color: white !important;
}
.ant-checkbox-checked::after{
    border-color: white !important;
}
`;

const IgnoreCheckbox = ({ checkedItems, setCheckedItems, image }) => {
    const determineIsActive = () => image.checked || checkedItems.length == 0 || checkedItems[0].ignore == image.ignore;

    const [isActive, setIsActive] = useState(determineIsActive)
    const [checked, setChecked] = useState(image.checked);

    useEffect(() => {
        setIsActive(determineIsActive);
        !checkedItems.length && setChecked(false);
    }, [checkedItems]);

    useEffect(() => {
        image.checked = checked;
        if (checked) {
            setCheckedItems([...checkedItems, image]);
        } else {
            setCheckedItems(checkedItems.filter(i => i.id != image.id));
        }
    }, [checked]);


    return (
        <StyledCheckbox checked={checked} disabled={!isActive} onChange={(e) => { setChecked(e.target.checked) }} />
    );
}

export default IgnoreCheckbox;