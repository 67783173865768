import React, { useState, useEffect } from "react";
import { Layout, Button, Col, Row } from "antd";
import { colors } from "Shared/colors";
import styled from "styled-components";
import PatternsTree from "./PatternsTree/PatternTree";
import HistoryList from "./History/HistoryList";
import { Tabs } from "antd";
import {
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { BsClockHistory } from "react-icons/bs";
import AddPatternButton from "./AddPattern/AddPatternButton";

const { TabPane } = Tabs;
const { Sider } = Layout;

const sideBarWidth = "350";
const sideNavBackgroundColor = colors.DARK90;

const StyledSider = styled(
  ({ rightPosition, bgColor, collapseColor, children, ...props }) => (
    <Sider {...props}>{children}</Sider>
  )
)`
  background: ${sideNavBackgroundColor};

  .ant-layout-sider-zero-width-trigger {
    top: 0;
    right: ${(props) => props.rightPosition}px;
    height: 43px;
    width: 43px;
    background: ${(props) => props.bgColor};
    border-radius: 0;
    color: ${(props) => props.collapseColor};
  }

  .ant-tabs-nav-list {
    background-color: ${colors.DARK90};
  }
`;

const StyledTabs = styled(Tabs)`
  color: white;
  display: grid;

  .ant-tabs-nav {
    margin: 0;
    padding: 0;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-nav span:hover {
    color: ${colors.BLUE};
  }

  .ant-tabs-tab-active span:hover {
    color: white;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    background: ${colors.BLUE};
    color: white;
    border-color: ${colors.BLUE};
  }

  .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }

  .ant-tabs-tab-next,
  .ant-tabs-tab-prev {
    color: white;
  }
`;

const StyledTabIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${sideBarWidth / 2}px;
  font-size: 12px;
`;

const Sidebar = ({
  sideBarCollaped,
  setSideBarCollaped,
  collapseColor,
  setCollapseColor,
  isMobile,
}) => {
  const [shouldRefreshHistory, setShouldRefreshHistory] = useState(false);

  return (
    <StyledSider
      collapsible
      onCollapse={(collapsed, type) => {
        setSideBarCollaped(collapsed);
      }}
      collapsed={sideBarCollaped}
      defaultCollapsed={sideBarCollaped}
      collapsedWidth="0"
      width={isMobile ? "400px" : `${sideBarWidth}px`}
      trigger={null}
      rightPosition={isMobile && !sideBarCollaped ? 4 : -43}
      collapseColor={collapseColor}
      bgColor={isMobile && !sideBarCollaped ? colors.LIGHT_BLUE : "none"}
    >
      <span
        className={
          "ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left"
        }
        onClick={() => setSideBarCollaped(!sideBarCollaped)}
      >
        {sideBarCollaped && <MenuUnfoldOutlined />}
        {!sideBarCollaped && <MenuFoldOutlined />}
      </span>
      <Row>
        <Col span={24}>
          <StyledTabs
            onTabClick={(key) => key == 2 && setShouldRefreshHistory(true)}
            type="line"
            tabBarGutter={2}
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: `calc(${sideBarWidth}px - 4px)` }}
              tab={
                <StyledTabIconWrapper>
                  <FileTextOutlined style={{ marginRight: "0.5rem" }} />{" "}
                  PATTERNS
                </StyledTabIconWrapper>
              }
              key="1"
            >
              <PatternsTree
                sideBarWidth={sideBarWidth}
                backgroundColor={sideNavBackgroundColor}
              />
              <AddPatternButton />
            </TabPane>

            <TabPane
              style={{ width: `calc(${sideBarWidth}px - 4px)` }}
              tab={
                <StyledTabIconWrapper>
                  <BsClockHistory style={{ marginRight: "0.5rem" }} /> HISTORY
                </StyledTabIconWrapper>
              }
              key="2"
            >
              <HistoryList
                shouldRefreshHistory={shouldRefreshHistory}
                setShouldRefreshHistory={setShouldRefreshHistory}
              />
            </TabPane>
          </StyledTabs>
        </Col>
      </Row>
    </StyledSider>
  );
};

export default Sidebar;
