import React, { useState } from "react";
import { Input, Form, Button, Alert } from "antd";
import { colors } from "Shared/colors";
import styled from "styled-components";
import { useAuth } from "./AuthContext";
import logo from "Shared/Icons/logo.svg";

const StyledScreen = styled.div`
  background: ${colors.BLACK};
  height: 100vh;
  display: grid;
  grid-template-rows: 110px 300px;
  justify-items: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  padding: 10rem 0;
`;

const StyledHeading = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: 96px auto;
  width: 300px;
  justify-items: center;
  align-items: center;
`;

const StyledHeadingText = styled.span`
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
`;

const LoginForm = styled(Form)`
  width: 300px;
`;

const VerificationForm = styled(Form)`
  width: 300px;
`;

const StyledLabel = styled.span`
  color: white;
  font-size: 0.7rem;
`;

const Login = () => {
  const { loginProcess, isLogginError, isTwoFactorVerificationEnabled, isTwoFactorVerificationPageEnabled, verifyUser, sendVerificationCode, logginErrorMessage } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [isCorrectCode, setIsCorrectCode] = useState(false);

  const [loginForm] = Form.useForm();
  const [verificationForm] = Form.useForm();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleCode = (e) => {
    setCode(e.target.value);
    const verificationCodeValue = verificationForm.getFieldValue("Verification code");
    if(verificationCodeValue.length === 6) {
      setIsCorrectCode(true);
    }
    else {
      setIsCorrectCode(false);
    }
  }

  return (
    <>
      {isTwoFactorVerificationEnabled && isTwoFactorVerificationPageEnabled ? (
        <>
          <StyledScreen id="Verification">
            <StyledHeading>
              <img src={logo} alt="Logo" width="64" />{" "}
              <StyledHeadingText>uRemediate Studio</StyledHeadingText>
            </StyledHeading>
            <VerificationForm
              form={verificationForm}
              name="control-hooks"
              layout="vertical"
              validateMessages={{ required: "'${name}' is required!" }}
              autocomplete="off"
            >
              <Form.Item
                name="Verification code"
                label={<StyledLabel>ENTER THE VERIFICATION CODE</StyledLabel>}
                rules={[{ required: true, len: 6 }]}
              >
                <Input type="text" onChange={handleCode} />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ background: colors.BLUE }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => isCorrectCode && verifyUser(code, email, password)}
                  disabled={!isCorrectCode}
                >
                  VERIFY
                </Button>
                <div style={{marginTop: '15px', fontSize: '0.7rem'}}>
                  <p style={{color: colors.WHITE}}>DIDN'T RECIEVE THE CODE? <a onClick={() => sendVerificationCode(email)} style={{color: colors.WHITE, textDecoration: 'underline'}}>RESEND IT</a></p>
                </div>
              </Form.Item>
            </VerificationForm>
          </StyledScreen>
        </>
      ) : (
        <>
        <StyledScreen id="Login">
            <StyledHeading>
              <img src={logo} alt="Logo" width="64" />{" "}
              <StyledHeadingText>uRemediate Studio</StyledHeadingText>
            </StyledHeading>
            <LoginForm
              form={loginForm}
              name="control-hooks"
              layout="vertical"
              validateMessages={{ required: "'${name}' is required!" }}
            >
              {isLogginError && (
                <Alert message={logginErrorMessage} type="error" showIcon />
              )}
              <Form.Item
                name="email"
                label={<StyledLabel>EMAIL</StyledLabel>}
                rules={[{ type: "email", required: true }]}
              >
                <Input type="email" placeholder="email" onChange={handleEmail} />
              </Form.Item>

              <Form.Item
                name="password"
                label={<StyledLabel>PASSWORD</StyledLabel>}
                rules={[{ required: true }]}
              >
                <Input.Password
                  type="password"
                  placeholder="password"
                  onChange={handlePassword}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ background: colors.BLUE }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => loginProcess(email, password)}
                >
                  SIGN IN
                </Button>
              </Form.Item>
            </LoginForm>
          </StyledScreen>
        </>
      )}
    </>
  );
};

export default Login;
