import React from 'react'
import { colors } from "Shared/colors"
import { FILE_EXTENSION } from 'Scripter/Views/consts';
import { htmlFileIcon, jsFileIcon, folderIcon } from "Shared/Icons/Icons.js"
import styled from 'styled-components';

const TreeNodeIcon = ({ isLeaf, title, errorDetected }) => {

    const FileIcon = styled.img`
        height: 18px;
        width: 18px;
    `;

    return (
        isLeaf ?
            <FileIcon src={typeof title === "string" && title.includes(FILE_EXTENSION.HTML) ? htmlFileIcon : jsFileIcon} />
            :
            <FileIcon src={folderIcon} style={{ color: colors.ORANGE }} />
    )
}

export default TreeNodeIcon;