import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { Menu, Row, Button, Popconfirm, Modal } from "antd";
import {
  DownCircleOutlined,
  UpCircleOutlined,
  MenuOutlined,
  RetweetOutlined,
  AppstoreOutlined,
  CloudUploadOutlined,
  RadarChartOutlined,
  WindowsOutlined,
  GlobalOutlined,
  CodeOutlined,
  UserOutlined,
  LogoutOutlined,
  PlusOutlined,
  FileDoneOutlined,
  FileImageOutlined,
  BellOutlined,
  CopyOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";
import { colors } from "Shared/colors";
import { useSiteScripts } from "../../../Context/SiteScripts/SiteScriptsContext";
import { sendMessage, recieveMessage, sendMessageInteg } from "Shared/messages";
import styled from "styled-components";
import { useAuth } from "Auth/AuthContext";
import logo from "Shared/Icons/logo.svg";
import { useGlobal } from "GlobalContext/GlobalContext";
import { navigate } from "@reach/router";
import { useNotification } from "Main/Context/Notification/NotificationContext";
import { LS_ACCESS_TOKEN } from "../../../../Auth/AuthContext";
import { getCurrentUrl, getSnippetUrl } from "Shared/Services/getCurrentUrl";
import { toastSuccess, toastError } from "Shared/utils";
import { useTree } from "Main/Context/TreeContext/TreeContext";
import { keyStrokesService } from "Main/Views/keyStrokes";
import "./navigation.css";
import { CopyModal } from "../../Copy/CopyModal";
const { SubMenu, Item } = Menu;

const StyledRow = styled(({ isMobile, ...props }) => (
  <Row {...props}>{props.children}</Row>
))`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isMobile ? "40px auto 50px 40px 32px" : "40px auto 65px 200px 32px"};
  .submenu-site-script {
    top: 0 !important;
  }
`;

const StyledLogoutWrapper = styled.div`
  width: ${(props) => (props.isMobile ? "20%" : "100%")};
  background-color: ${colors.BLUE};
  font-size: 0.7rem;
  font-weight: 700;
  display: grid;
  grid-template-columns: 30px auto 35px;
  align-items: center;
  .anticon {
    font-size: 1rem;
  }
  ${(props) =>
    props.isMobile &&
    `height: 2.2rem !important; justify-self: end; width: 42px;`}
`;

const StyledMenu = styled(Menu)`
  background: black;
  color: white;
  border: none;
  text-align: ${(props) => (props.left ? "start" : "end")};
  .ant-menu-submenu-title {
    padding: 0;
    margin-left: 15px;
  }
  .ant-menu-submenu-popup {
    left: 0 !important;
  }
`;

const MinimizeButton = styled(Button)`
  background: ${colors.DARK100}!important;
  color: white !important;
  border: 0 !important;
  top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoutButton = styled(Button)`
  color: white;
  padding: 0;
  margin: 0;
  &:hover {
    background: ${colors.DARK_BLUE} !important;
    color: white;
  }
  &:focus {
    background: ${colors.DARK_BLUE} !important;
    color: white;
  }
`;

const NotificationIcon = styled((props) => <BellOutlined />)`
  font-size: 20px;
  align-self: center;
  cursor: pointer;
  position: relative;
  top: 2px;
  ${(props) => (props.hasBadge ? `right: 3px;` : `left: 20px;`)}
`;

const NotificationNewContainer = styled.span`
  position: relative;
  bottom: 8px;
  left: 36px;
  border-radius: 50%;
  background: #e63939;
  padding: 0 2px;
`;

const NotificationNew = styled.span`
  font-size: 9px;
  color: white;
  font-weight: bold;
`;

const Navigation = ({
  setSideBarCollaped,
  isMobile,
  setNotificationVisible,
}) => {
  const { siteScriptsData, updateSiteScripts } = useSiteScripts();
  const { newMessages } = useNotification();
  const { treeDictionary, expandedKeys, setExpandedKeys, updatePatternTree } =
    useTree();
  const { logout } = useAuth();
  const [isMinimized, setMinimized] = useState(false);
  const {
    user,
    setScripterApp,
    siteSettings,
    url,
    fetchSiteSettings,
    publishData,
    publishLoading,
    publishErrors,
    publishSite,
  } = useGlobal();
  const [activatePatternsTimeout, setActivatePatternsTimeout] = useState(null);
  const [publishModal, setPublishModal] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);

  const [
    { data: ocrPreview, loading: ocrPreviewLoading, error: ocrPreviewError },
    fetchOcrPreview,
  ] = useAxios(
    {
      method: "POST",
      url: `/site/preview`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    siteSettings && updateSiteScripts();
  }, [siteSettings]);

  useEffect(() => {
    keyStrokesService.minimize(() => toggleSize());
    keyStrokesService.zoomIn(() => {
      sendMessage({ type: "zoomIn" }, "*");
    });
    keyStrokesService.zoomOut(() => {
      sendMessage({ type: "zoomOut" }, "*");
    });
    recieveMessage("zoomChanged", (data) => {
      toastSuccess(`Zoom: ${Math.round(100 * data.data)}%`, 1);
    });
  }, []);

  useEffect(() => {
    if (publishData) {
      toastSuccess("Site successfully published!");
      fetchSiteSettings({ data: { url: url } });
    }
  }, [publishData]);

  useEffect(() => {
    if (publishErrors) {
      const errors =
        publishErrors.response &&
        publishErrors.response.data &&
        publishErrors.response.data.Errors;
      toastError("Issue publishing site! " + errors);
    }
  }, [publishErrors]);

  const toggleSize = () => {
    sendMessage({ type: "minimize", data: !isMinimized, product: "urs" }, "*");
    setMinimized(!isMinimized)
  };

  const Preview = () => {
    if (siteSettings && siteSettings.ocr) {
      (async () => {
        var url = await getCurrentUrl();
        if (url) {
          fetchOcrPreview({ data: { url } });
        }
      })();
    } else {
      const token = localStorage.getItem(LS_ACCESS_TOKEN);
      (async () => {
        var url = await getCurrentUrl();
        var cdnUrl = await getSnippetUrl();
        if (url) {
          sendMessageInteg({
            type: "preview",
            token,
            url,
            cdnUrl,
          });
        }
        sendMessage({
          type: "preview",
          token,
        });
      })();
    }
  };

  useEffect(() => {
    if (ocrPreview) {
      const token = localStorage.getItem(LS_ACCESS_TOKEN);
      sendMessage({
        type: "preview",
        token,
      });
    }
  }, [ocrPreview]);

  useEffect(() => {
    sendMessage({ type: "determine_patterns_layer_activation" });
    recieveMessage("determine_patterns_layer_activation", (data) => {
      if (data.isActive === true) {
        CreatePatternsLayers(true);
      }
    });
  }, [treeDictionary]);

  keyStrokesService.preview(() => {
    Preview();
  });

  const CreatePatternsLayers = (forceShow = false) => {
    const filteredTreeDictionary = {};
    FilterOnlyVisibleTreeDictionaryElements(
      treeDictionary,
      filteredTreeDictionary
    );
    sendMessage({
      type: "patterns_layer",
      patterns: filteredTreeDictionary,
      forceShow,
    });
    recieveMessage("forceMinimize", (data) => {
      setMinimized(true);
    });
    recieveMessage("patterns_layer", (data) => {
      setMinimized(false);
      setExpandedKeys(data.result.keysToOpen);
      Array.from(document.querySelectorAll(".tree-pattern-name span")).forEach(
        (el) => {
          const parent = el.closest(".tree-pattern-name");
          parent.classList.remove("site-tree-search-value");
          if (el.innerText === data.result.name) {
            el.scrollIntoView({ behavior: "smooth", block: "nearest" });
            setTimeout(() => {
              el.click();
            }, 1000);
            parent.classList.add("site-tree-search-value");
          }
        }
      );
    });
  };

  const FilterOnlyVisibleTreeDictionaryElements = (
    i_treeDictionary,
    o_filteredTreeDictionary,
    i_parentId = null
  ) => {
    Object.entries(i_treeDictionary).forEach(([key, value]) => {
      if (value.count > 0) {
        const patternItem = {
          id: value.id,
          name: value.name,
          selector: value.selector,
          parentId: i_parentId,
          innerPatterns: {},
          haveChildren: !!(
            Object.getOwnPropertyNames(value.patterns).length > 0 ||
            value.elements.length > 0
          ),
        };
        o_filteredTreeDictionary[value.id] = patternItem;
        FilterOnlyVisibleTreeDictionaryElements(
          value.patterns,
          patternItem.innerPatterns,
          patternItem.id
        );
      }
    });
  };

  const Publish = () => {
    (async () => {
      const url = await getCurrentUrl();
      publishSite({ data: { url: url } });
    })();
  };

  const hasMultipleSiteScript = siteScriptsData && siteScriptsData.length > 8;

  return (
    <>
      <StyledRow isMobile={isMobile}>
        <StyledMenu left="start" mode="horizontal">
          <SubMenu icon={<MenuOutlined />} key="navigation">
            <Item
              icon={<CopyOutlined />}
              key="copy"
              onClick={() => {
                setCopyModalVisible(true);
              }}
            >
              COPY
            </Item>
            <SubMenu icon={<WindowsOutlined />} key="layers" title="LAYERS">
              <Item
                icon={<CodeSandboxOutlined />}
                key="scripter"
                onClick={() => {
                  setScripterApp(true);
                }}
              >
                ADVANCED MODE
              </Item>
              <Item
                icon={<FileImageOutlined />}
                key="OCR"
                onClick={() => {
                  setSideBarCollaped(true);
                  navigate("/images");
                }}
              >
                OCR
              </Item>
              <Item
                icon={<AppstoreOutlined />}
                key="patterns"
                onClick={() => {
                  CreatePatternsLayers();
                }}
              >
                PATTERNS
              </Item>
            </SubMenu>
            <Item
              icon={<FileDoneOutlined />}
              key="preview"
              onClick={() => Preview()}
            >
              PREVIEW
            </Item>
            <Item
              icon={<CloudUploadOutlined />}
              key="publish"
              onClick={() => setPublishModal(true)}
            >
              PUBLISH
            </Item>
            <Item
              onClick={() => updatePatternTree()}
              icon={<RetweetOutlined />}
              key="refresh"
            >
              REFRESH
            </Item>
            <Item
              icon={<GlobalOutlined />}
              key="site_languages"
              onClick={() => {
                setSideBarCollaped(true);
                navigate("/languageScript");
              }}
            >
              SITE LANGUAGES
            </Item>
            <SubMenu
              popupClassName={
                hasMultipleSiteScript ? "submenu-site-script" : ""
              }
              icon={<CodeOutlined />}
              key="site_scripts"
              title="SITE SCRIPTS"
            >
              <Item style={{ background: "white" }} key="new_site_scripts">
                <Button
                  style={{
                    background: colors.BLUE,
                    borderRadius: 3,
                    color: "white",
                  }}
                  onClick={() => {
                    setSideBarCollaped(true);
                    navigate("/siteScripts/new");
                  }}
                >
                  <PlusOutlined style={{ marginRight: 0 }} />
                  NEW
                </Button>
              </Item>
              {siteScriptsData &&
                siteScriptsData.map((scriptsName) => (
                  <Item
                    key={scriptsName}
                    onClick={() => {
                      setSideBarCollaped(true);
                      navigate(`/siteScripts/${scriptsName}`);
                    }}
                  >
                    {scriptsName}
                  </Item>
                ))}
            </SubMenu>
          </SubMenu>
        </StyledMenu>

        <div style={{ width: "150px" }} key="brand">
          <img src={logo} alt="Logo" width="16" /> uRemediate Studio
        </div>

        <div onClick={() => setNotificationVisible(true)}>
          {!!newMessages.length && (
            <NotificationNewContainer>
              <NotificationNew>NEW</NotificationNew>
            </NotificationNewContainer>
          )}
          <NotificationIcon hasBadge={!!newMessages.length} />
        </div>

        <StyledLogoutWrapper isMobile={isMobile}>
          {!isMobile && (
            <>
              <UserOutlined />
              <span>
                {user.name} {user.surname}
              </span>
            </>
          )}
          <Popconfirm
            placement="leftTop"
            title="Are you sure want to logout?"
            onConfirm={logout}
            okText="YES"
            cancelText="NO"
          >
            <LogoutButton type="link" icon={<LogoutOutlined />} />
          </Popconfirm>
        </StyledLogoutWrapper>

        <MinimizeButton
          key="minimize"
          onClick={() => toggleSize()}
          icon={isMinimized ? <DownCircleOutlined /> : <UpCircleOutlined />}
        ></MinimizeButton>
        <CopyModal
          copyModalVisible={copyModalVisible}
          setCopyModalVisible={setCopyModalVisible}
        />
        <Modal
          title="User action required"
          visible={publishModal}
          onOk={() => {
            Publish();
            setPublishModal(false);
          }}
          onCancel={() => setPublishModal(false)}
          okText="YES"
          cancelText="NO"
        >
          <p>Are you sure you want to publish this version?</p>
        </Modal>
      </StyledRow>
    </>
  );
};

export default Navigation;
