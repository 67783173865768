import React, { useState, useEffect, useRef } from "react";
import { colors } from "Shared/colors";
import styled from "styled-components";
import useAxios from "axios-hooks";
import { getCurrentUrl } from 'Shared/Services/getCurrentUrl';
import { Input, Select, Tag, Menu, Button } from 'antd';
import { toastError, toastSuccess } from "Shared/utils";
import { SearchOutlined } from "@ant-design/icons";
import { IMAGE_STATUS, IDS } from "./consts";
import { Filter } from "./Filters";

const { Option } = Select;
const StyledSearchOutlined = styled(SearchOutlined)`
color:white;
`
const StyledMenu = styled(Menu)`
display:flex;
flex-direction: row;
align-items: center;
align-content:flex-start;
`;

const StyledInput = styled(Input)`
flex:  1;
background-color:${colors.DARK90}!important;
color: white;
width: 50%;
border-radius: 0!important;
border: 0!important;
border-bottom: 1px solid white!important;
z-index: 100 !important;
margin:0px 5px;
.ant-input {
    color: white;
    background-color: ${colors.DARK90}!important;
    border: 0!important;
}
span[role='button']{
    color:white;
}
`

const StyledSelect = styled(Select)`
flex:  1;
width: 50%;
z-index: 100 !important;
margin:0px 5px;
.ant-select-selector {
    color: white;
    background-color: ${colors.DARK90}!important;
    border-radius: 0px!important;
    border: 0!important;
    border-bottom: 1px solid white!important;
}
.ant-select-arrow {
    color: white;
}
.ant-select-selection-item{
    background: ${colors.BLUE};
    border: none;
    color: white;
    font-size: 12px;
    font-weight: 500;
} 
`

const PublishButton = styled(Button)`
    background-color: ${colors.BLUE};
    margin-left: 10px;
    color: white;
    border: none;
    height: 1.8rem;
    font-size: 0.7rem;
    font-weight: bold;
`

const ImagesHeader = ({ fullImages, setImages, urlFilter, setUrlFilters, tagFilter, setTagFilters }) => {

    const [{ data: publishImages, loading, error: publishError }, fetchPublishImages] = useAxios(
        {
            method: "POST",
            url: `/image/publish`,
            headers: {
                "content-type": "application/json",
            },
        },
        { manual: true }
    );

    const publish = () => {
        (async () => {
            var url = await getCurrentUrl();
            if (url) {
                fetchPublishImages({ data: { url } });
            }
        })();
    }

    useEffect(() => {
        if (publishImages) {
            toastSuccess(`ocr images published successfully`);
        }
    }, [publishImages])

    useEffect(() => {
        if (publishError) {
            toastError("Error while pulibsh images", publishError?.response?.data?.Errors);
        }
    }, [publishError])

    return (
        <StyledMenu selectable={false} theme="dark" mode="horizontal">
            <ImagesFilter tagFilter={tagFilter} setTagFilters={setTagFilters} fullImages={fullImages} setImages={setImages} urlFilter={urlFilter} setUrlFilters={setUrlFilters} />
            <PublishButton disabled={!fullImages.length} onClick={publish}>PUBLISH</PublishButton>
        </StyledMenu>
    );
}

const ImagesFilter = ({ tagFilter, setTagFilters, fullImages, setImages, urlFilter, setUrlFilters }) => {
    const [selectFlag, setSelectFlag] = useState(null);
    const [inputFlag, setInputFlag] = useState(null);
    const [imageUrlInputText, setImageUrlInputText] = useState(null);
    const imgUrlInput = useRef(null);

    useEffect(() => {
        if (imgUrlInput.current.value != urlFilter) {
            setImageUrlInputText(urlFilter);
        }
        let filteredImages = Filter(fullImages, tagFilter, urlFilter);
        setImages(filteredImages);
    }, [tagFilter, urlFilter])


    const handleSelectChange = (v) => {
        if (selectFlag != null) {
            clearTimeout(selectFlag);
        }
        setSelectFlag(setTimeout(() => {
            setTagFilters(v);
        }, 1000))
    }

    const handleInputChange = (e) => {
        e.persist();
        setImageUrlInputText(e.target.value);
        if (inputFlag != null) {
            clearTimeout(inputFlag);
        }
        setInputFlag(setTimeout(() => {
            let url = e.target.value;
            if (url && url.endsWith("/")) {
                url = url.slice(0, -1); // remove / from the end of the url
            }
            setUrlFilters(url);
        }, 1000))
    }

    return (
        <>
            <StyledInput
                ref={imgUrlInput}
                id={IDS.IMG_URL}
                prefix={<StyledSearchOutlined />}
                placeholder="IMAGE URL"
                onChange={handleInputChange}
                value={imageUrlInputText}
                allowClear={true}
            />

            <StyledSelect
                mode="multiple"
                showArrow
                defaultValue={tagFilter.tags}
                style={{ width: '100%' }}
                options={Object.entries(IMAGE_STATUS).map(([key, value]) => {
                    return {
                        label: value,
                        value: key
                    }
                })}
                onChange={handleSelectChange}
            />
        </>
    );
}


export default ImagesHeader;