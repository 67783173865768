const SCRIPTER_PREVIEW_MODE = "U1ST_SCRIPTER_PREVIEW";

//https://github.com/microsoft/TypeScript/blob/release-4.2/src/compiler/diagnosticMessages.json
const CRITICAL_DIAGNOSTIC_CODES = ["1127", "1128", "1002", "1003", "1005", "1007", "1009", "1010", "1012"];

const PRODUCT ={
    FRONTEND: 'frontend',
    TOOLBAR: 'toolbar',
    IDE: 'ide',
    PREVIEW: 'preview'
}

const ADDRESS ={
    PROD: 'http://urs.user1st.info/',
    LOCALHOST: 'http://localhost',
}

const MESSAGES ={
    LOADER_MODE: 'loaderMode',
    ADDRESS: 'address',
    PRODUCT: 'product',
    IDE_VISIBLE: 'ideVisibile',
    TOOLBAR_LOADED: 'toolbarLoaded',
    RELOAD: 'reload'
}

const STORAGE_NAMES ={
    ADDRESS: 'address',
    PRODUCT: 'product',
    IS_AUHENTICATED: 'isAuthenticated',
    IDE_ACTION: 'ideAction'
}

const KEYS = {
    PRODUCTION: 'production',
    LOCAL_HOST: 'localhost',
    IDE: 'ide',
    FRONTEND: 'frontend',
    TOOLBAR: 'toolbar'
}

const IDE_ACTIONS = {
    PREVIEW: 'Preview',
    PUBLISH: 'Publish'
}

const FILE_EXTENSION = {
    HTML: '.html',
    JAVA_SCRIPT: '.js'
}

export { SCRIPTER_PREVIEW_MODE, CRITICAL_DIAGNOSTIC_CODES, PRODUCT, ADDRESS, MESSAGES, STORAGE_NAMES, KEYS, IDE_ACTIONS, FILE_EXTENSION }