import React, {useState, useEffect} from 'react'
import {Form, Select, Tooltip} from 'antd';
import { KEYS,TYPES } from '../consts';
import { useCodefix } from 'Main/Context/Codefix/CodefixContext';
import{ constructOptions } from './options';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';


const {Item} = Form;

const SmartAutocomplete = ({form, activeCodefixType, currentOptionsList, setSelectedOption, patternId, codefixEditData}) => {
    const { isSelectorValid, patternProperties, selector } = useCodefix();
    const [searchedOptions, setSearchedOptions] = useState([]);
     
    useEffect(() => {
        if(currentOptionsList){
            setSearchedOptions(currentOptionsList);
            if(codefixEditData){
                let selectedOption = currentOptionsList.filter((op) => {
                    return op.name && op.name.toLowerCase() === codefixEditData.name || op.value && op.value.toLowerCase() === codefixEditData.name
                })[0];
                selectedOption && onSelected(null, selectedOption);
            }
        }
    }, [currentOptionsList, codefixEditData])

    const onSelected = (value, options) => {
        setSelectedOption(options);
        setSearchedOptions(currentOptionsList);
        if(codefixEditData){
            form.setFieldsValue({
                fixes: options.value
            });
        }
    }
        
    return (
            <div>
                <TooltipLabel required value={activeCodefixType?.toUpperCase()} tooltip={{ text: "fill later" }} />
                <Item name={KEYS.FIXES} validateTrigger={['onBlur']} rules={[{ required: true, message: `required field` }]}>
                    <Select
                        disabled={!isSelectorValid || codefixEditData && codefixEditData.type !== TYPES.ROLES}
                        onSelect={onSelected}
                        showSearch
                        placeholder={`select ${activeCodefixType}`.toUpperCase()}
                        showArrow={true}
                    >
                        {constructOptions(activeCodefixType, searchedOptions, patternProperties, selector)}
                    </Select>
                </Item>
            </div>
    )
}




export default SmartAutocomplete;