import React, {useState, useEffect} from 'react';
import {Form, Input} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import Languages from './Languages';
import styled from 'styled-components';
import { useCodefix } from 'Main/Context/Codefix/CodefixContext';
import { useGlobal, SITE_SETTINGS_CONSTS } from 'GlobalContext/GlobalContext';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
`;

const ConfirmAction = ({form, codefixEditData, selectedOption, patternId}) => {
    const {description, setDescription} = useCodefix();
    const {siteSettings} = useGlobal();
    const [activeLang, setActiveLang] = useState(null);
    const [languages, setLanguages] = useState (null);

    useEffect(() => {
        if(codefixEditData){
            let values = codefixEditData.data;
            var descriptionData = {}
            values && values.values.forEach(l => {
                descriptionData[l.lang] = l.text
            });
            descriptionData.id = values.id;
            setDescription(descriptionData);
            let keys = Object.keys(descriptionData).filter(d => d !== "id");
            setLanguages(keys);
            setActiveLang(keys[0]);
        }
    }, [codefixEditData])

    useEffect(() => {
        if(siteSettings){
            const languages = siteSettings && siteSettings[SITE_SETTINGS_CONSTS.LANGUAGES];
            let data = {};
            languages.forEach(lang => {
                data[lang] = "";
            })
            if(!codefixEditData){
                setDescription(data);
                setLanguages(languages);
                setActiveLang(languages[0]);
            }
        }
    }, [siteSettings])

    useEffect(() => {
        return () => {
            setDescription(null);
        }
    }, [])

    const updateContentOnChange = (value) => {
        if(activeLang && description){
            setDescription({
                ...description,
                [activeLang?.toLowerCase()]: value
            })
        }
    }

    return (
        <>
            {
                languages
                &&
                <Wrapper>
                    <Languages languages={languages} setActiveLang={setActiveLang}/>
                    <div>
                        <TooltipLabel required value="CONFIRMATION TEXT" tooltip={{ text: "fill later" }} />
                        <Input
                            name="confirmAction"
                            onChange={(e) => updateContentOnChange(e.target.value)}
                            value={activeLang && description && description[activeLang?.toLowerCase()]}
                            placeholder="CONFIRMATION TEXT"
                        />
                    </div>
                </Wrapper>
            }
        </>
    )
}

export const getConfirmActionData = (form, descriptionData) => {
    let result = {
        method: "manual",
        id:  descriptionData.id,
        values: []
    }

    const langs = Object.keys(descriptionData).filter(d => d !== "method" && d !== "id");
    langs.forEach((langCode) => {
        if(langCode !== "method"){
            result.values.push({
                lang: langCode,
                text: descriptionData[langCode]
            });
        }
    });

    return result;
}

export default ConfirmAction;
