import {KEYS} from './consts';

export const extractFirstLevelData = (data, menuScriptsData, form) => {
    const items = Object.entries(data);
    const selectors = items.filter((k) => k[0].startsWith(KEYS.SELECTOR));
    const types = items.filter((k) => k[0].startsWith(KEYS.TYPE));
    const openAttachedMenus = items.filter((k) => k[0].startsWith(KEYS.OPEN_ATTACHED_MENU));
    let firstLevelmenuItems = [];

    for(var i = 0; i < selectors.length; i++){
        const selector = selectors[i] && selectors[i][1];
        const typeKey = types[i] && types[i][0];
        const typeValue = types[i] && types[i][1];
        let openAttachedMenu = openAttachedMenus[i] && openAttachedMenus[i][1];
        openAttachedMenu = openAttachedMenu === undefined ? false : openAttachedMenu;
        const obj = {
            type: "menuWidgetItem",
            values: {
                menuSelector: ".",
                selector: selector,
                type: typeValue
            }
        }

        if(typeValue === KEYS.SCRIPT){
            obj.values["script"] = menuScriptsData[typeKey];
        }

        if(openAttachedMenu){
            obj.values["openAttachedMenu"] = openAttachedMenu;
        }

        firstLevelmenuItems.push(obj);
    }

    let direction = form.getFieldValue(`${KEYS.DIRECTION}_firstLevel`);
    direction = direction === undefined || direction ?  "Vertical" : "Horizontal";
    const menuObj = {
        type: "menuWidgetMenu",
        direction,
        values: {
            selector: "."
        }
    }
    firstLevelmenuItems.push(menuObj);

    return firstLevelmenuItems;
}

export const extractSecondLevelData = (data, menuScriptsData, form) => {
    const items = Object.entries(data);
    const selectors = items.filter((k) => k[0].startsWith(KEYS.SELECTOR));
    const menuSelectors = items.filter((k) => k[0].startsWith(KEYS.MENU_SELECTOR));
    const closeBys = items.filter((k) => k[0].startsWith(`${KEYS.CLOSE_BY}_`));
    const closeButtonSelectors = items.filter((k) => k[0].startsWith(`${KEYS.CLOSE_BUTTON_SELECTOR}`));
    const closeButtonIsRelativeSelectors = items.filter((k) => k[0].startsWith(`${KEYS.ABSOLUTE_RELATIVE_SWTICH}`));
    const types = items.filter((k) => k[0].startsWith(KEYS.TYPE));
    const attachedMenus = items.filter((k) => k[0].startsWith(KEYS.ATTACHED_MENU));
    const openAttachedMenus = items.filter((k) => k[0].startsWith(KEYS.OPEN_ATTACHED_MENU));
    let direction = form.getFieldValue(`${KEYS.DIRECTION}_secondLevel`);
    direction = direction === undefined || direction ?  "Vertical" : "Horizontal";
    let secondLevelmenuItems = [];
    for(var i = 0; i < menuSelectors.length; i++){
        const menuKey = menuSelectors[i][0];
        const menuSelector = menuSelectors[i][1];
        const closeByKey = closeBys[i][0];
        const closeByValue = closeBys[i][1];
        let closeButtonSelector = closeButtonSelectors && closeButtonSelectors.length && closeButtonSelectors.filter(c => c[0].endsWith(menuKey))[0]
        closeButtonSelector = closeButtonSelector && !!closeButtonSelector.length && closeButtonSelector[1];
        
        let closeButtonIsRelative = closeButtonIsRelativeSelectors && closeButtonIsRelativeSelectors.length && closeButtonIsRelativeSelectors.filter(c => c[0].endsWith(menuKey))[0]
        closeButtonIsRelative = closeButtonIsRelative && !!closeButtonIsRelative.length && closeButtonIsRelative[1];
    
        const attachedMenu = attachedMenus[i][1];
        const openAttchedMenu = openAttachedMenus.filter(s => s[0].endsWith(menuKey));
        let relatedMenuItemsSelectors = selectors.filter(s => s[0].endsWith(menuKey));
        let relatedMenuItemsTypes = types.filter(s => s[0].endsWith(menuKey));
        
        let menuObj = {
            type: "menuWidgetMenu",
            direction,
            values: {
                closeBy: closeByValue,
                selector: menuSelector,
                attachedMenu: attachedMenu ? true : false,
            }
        }

        if(closeButtonSelector && closeByValue === "closeByCloseButton"){
            menuObj.values["closeButtonSelector"] = closeButtonSelector;
            menuObj.values["closeButtonIsRelative"] = closeButtonIsRelative === undefined || !closeButtonIsRelative ? "Relative" : "Absolute";
        }

        if(closeByValue === "closeByScript"){
            menuObj.values["script"] = menuScriptsData[closeByKey];
        }

        secondLevelmenuItems.push(menuObj);

        for(let i = 0; i < relatedMenuItemsSelectors.length; i++){
            let selector = relatedMenuItemsSelectors[i][1];
            const typeKey = relatedMenuItemsTypes[i][0];
            const typeValue = relatedMenuItemsTypes[i][1];
            let openAttched = openAttchedMenu && openAttchedMenu.length && openAttchedMenu[i][1];
            let itemObj = {
                type: "menuWidgetItem",
                values: {
                    selector,
                    type: typeValue,
                    target: menuSelector,
                    openAttachedMenu: openAttched ? true : false
                }
            }

            if(typeValue === KEYS.SCRIPT){
                itemObj.values["script"] = menuScriptsData[typeKey];
            }

            secondLevelmenuItems.push(itemObj)
        }
    }

    return secondLevelmenuItems;
}


export const extractDirection = (data) => {
    const firstLevel = data.firstLevel;
    let firstLevelDirection = firstLevel.filter((f) => f.direction)[0];
    firstLevelDirection = firstLevelDirection[KEYS.DIRECTION]?.toLowerCase() === "horizontal" ? false : true;

    const secondLevel = data.secondLevel;
    let secondLevelDirection = !!secondLevel.length && secondLevel.filter((f) => f.direction)[0];
    secondLevelDirection = secondLevelDirection && secondLevelDirection[KEYS.DIRECTION]?.toLowerCase() === "horizontal" ?  false : true;

    return {firstLevel: firstLevelDirection, secondLevel: secondLevelDirection};
}