import React, {useState, useEffect} from 'react';
import MenuSelector from '../MenuSelector';
import { Select, Form, Checkbox, Button  } from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import styled from "styled-components";
import {PlusCircleOutlined, CloseCircleOutlined, EditOutlined, PlusOutlined, MinusOutlined} from '@ant-design/icons';
import { colors } from 'Shared/colors';
import { navigate } from "@reach/router";
import {KEYS} from '../consts';
import {useCodefix} from 'Main/Context/Codefix/CodefixContext';
import {useMenuWidget} from 'Main/Context/MenuWidget/MenuWidgetContext';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
    display: grid;
    grid-template-columns: ${props => props.hasScript ? "20px 2.5fr 1.6fr 0 1.4rem": "20px 2fr 1.4fr 1.4rem"};
    grid-gap: ${props => props.isMobile ? '1px' : '20px'};
`;

const AttachedContainer = styled.div`
    display: ${props => props.show ? 'grid' : 'none'};
    grid-template-columns: 2fr 1.6fr;
    .ant-form-item{
        margin-bottom: 10px !important;
    }
    ${props => props.isMobile && `
        *{
            font-size: 10px !important;
        }     
    `}
`;

const IconContainer = styled.div`
    background: ${props => props.color};
    height: 21px;
    border-radius: 10px;
    margin-top: ${props => props.marginTop || '30px'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;    

    span{
        font-size: 13px;
    }

    svg{
        color: white !important;
        margin-left: 1px;
    }
`;

const IconScriptContainer = styled.div`
    background: ${props => colors.BLUE};
    height: 32px;
    width: 35px;
    margin-top: ${props => props.marginTop || '30px'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
    position: relative;
    top: 22px;
    right: 35px;   

    span{
        font-size: 13px;
    }

    svg{
        color: white !important;
        margin-left: 1px;
    }
`;

const StyleSelect = styled.div`
    ${props => props.hideArrowIcon && `
        .ant-select-arrow{
            display:none;
        }
    `}
    ${props => props.isMobile &&
        `
            *{
                font-size: 10px !important;
            }
            .ant-select-item {
                font-size: 10px !important;
            }
        `
    }
`

const MenuItem = ({item, addItem, removeItem, index, items, level, patternId, currentMenuSelector, form}) => {
    const [showAttachedCheckbox, setAttachedCheckbox] = useState(false);
    const [selectorErrors, setErrors] = useState(null);
    const [disableType, setDisableType] = useState(null);
    const { menuScriptModal, setMenuScriptModal } = useCodefix();
    const {selectedScriptId, setSelectedScriptId, menuScriptsData, setScripts, editScriptData, setEditScriptData} = useMenuWidget();
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)'
    });
    const typeId = `${KEYS.TYPE}_${item.key}_${level}`;
    const options = [
        {value: "noAction", label: "NO ACTION"},
        {value: "openByHover", label: "OPEN BY HOVER"},
        {value: "openByClick", label: "OPEN BY CLICK"},
        {value: "openByHoverAndClick", label: "OPEN BY HOVER AND CLICK"},
        {value: "openByExecuteScript", label: "OPEN BY SCRIPT"},
        {value: "itemContainer", label: "ITEM CONTAINER"}
    ];

    useEffect(() => {
        if(item && item.editData){
            triggerEditMode();
        }
    }, [item])

    const onTypeSelect = (value) => {
        if(["openByHover", "openByClick", "openByHoverAndClick"].includes(value)){
            setAttachedCheckbox(true);
            setSelectedScriptId(null);
            menuScriptsData && menuScriptsData[typeId] && delete menuScriptsData[typeId];
        }
        else if(value === KEYS.SCRIPT){
            openScriptModal();
        }
        else {
            menuScriptsData && menuScriptsData[typeId] && delete menuScriptsData[typeId];
            setAttachedCheckbox(false);
            setSelectedScriptId(null);
        }
    }

    const openScriptModal = () => {
        setAttachedCheckbox(false);
        setMenuScriptModal(true);
        setSelectedScriptId(typeId);
    }

    const triggerEditMode = () =>{
        let type = item.editData[KEYS.TYPE];
        if(type !== KEYS.SCRIPT){
            onTypeSelect(type);
        }
        else{
            setSelectedScriptId(typeId);
            let script = `var init: Init = ${item.editData.script}`;
            let data = editScriptData;
            data.push({[typeId]: script});
            setEditScriptData(data);
        }
        setDisableType(true);
        form.setFieldsValue({
            [`${KEYS.SELECTOR}_${item.key}_${level}`]: item.editData[KEYS.SELECTOR],
            [typeId]: type,
            [`${KEYS.OPEN_ATTACHED_MENU}_${item.key}_${level}`]: item.editData[KEYS.OPEN_ATTACHED_MENU]
        });
    }

    const onRemove = () => {
        menuScriptsData && menuScriptsData[typeId] && delete menuScriptsData[typeId];
        removeItem(item.key);
    }

    useEffect(() => {
        if(editScriptData && editScriptData.length > 0){
            let data = {};
            editScriptData.forEach(s => data[Object.keys(s)] = s[Object.keys(s)])
            setScripts({...menuScriptsData, ...data});
            setEditScriptData([]);
        }
    });

    const showAddButton = items && index === items.length -1;
    const itemHasScript = selectedScriptId === typeId || menuScriptsData && menuScriptsData[typeId];
    const isEditModeLastItem = item && item.editData && index < items.length;
    return (
        <>
            <Container hasScript={itemHasScript} isMobile={isMobile}>
                {
                    showAddButton 
                    ?
                        <IconContainer  color={colors.BLUE} onClick={addItem}>
                            <PlusOutlined style={{color: 'white'}}/>
                        </IconContainer>
                    :
                    <div></div>
                }
                <div>
                    <MenuSelector 
                        form={form} 
                        currentMenuSelector={currentMenuSelector}  
                        patternId={patternId} 
                        selectorErrors={selectorErrors} 
                        setErrors={setErrors} 
                        name={`selector_${item.key}_${level}`} 
                        label="MENU ITEM SELECTOR" 
                    />
                </div>
                <StyleSelect hideArrowIcon={itemHasScript && disableType} isMobile={isMobile}>
                    <TooltipLabel required value={KEYS.TYPE.toUpperCase()} tooltip={{ text: "fill later" }} />
                    <Form.Item initialValue={options[0].value} name={typeId}>
                        <Select disabled={disableType} onSelect={onTypeSelect} options={options} />
                    </Form.Item>
                </StyleSelect>
                {
                    itemHasScript
                    &&
                    <IconScriptContainer marginTop="2px" onClick={() => openScriptModal()}>
                        <EditOutlined style={{color: colors.BUTTON_BLUE}}/>
                    </IconScriptContainer>
                }
                {
                    items.length > 1
                    ?
                    <IconContainer onClick={onRemove} color={'rgb(247, 61, 61)'}>
                        <MinusOutlined />
                    </IconContainer>
                    :
                    <div></div>
                } 
            </Container>
            {
                <AttachedContainer isMobile={isMobile} show={showAttachedCheckbox}>
                    <div></div>
                    <Form.Item valuePropName="checked" name={`${KEYS.OPEN_ATTACHED_MENU}_${item.key}_${level}`}>
                        <Checkbox>
                            ATTACHED MENU
                        </Checkbox>
                    </Form.Item>
                </AttachedContainer>
            }
        </>
    )
}

export default MenuItem;