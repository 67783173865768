import React, { useContext, createContext, useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useGlobal } from 'GlobalContext/GlobalContext';
import { KEYS } from "../../Views/Patterns/PatternForm/consts";
import {MenuWidgetProvider} from '../MenuWidget/MenuWidgetContext';
import { getCurrentUrl } from "Shared/Services/getCurrentUrl";
const CodefixContext = createContext();

function CodefixProvider({ children , isMobile, setSideBarCollaped }) {
  const [selector, setSelector] = useState(null);
  const [scriptSelector, setScriptSelector] = useState(null);
  const [selectorErrors, setSelectorErros] = useState(null);
  const [isSelectorValid, setSelectorValid] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [patternSelectorSelected, setPatternSelectorSelected] = useState(null);
  const [patternProperties, setPatternProperties] = useState(null);
  const [description, setDescription] = useState(null);
  const [menuScriptModal, setMenuScriptModal] = useState(null);
  const [patternId, setPatternId] = useState(null);

  const [
    { data: patternRaw, loading: patternLoading, error: PatternErrors }, getPatternDetails] = useAxios(
    {
      method: "POST",
      url: `/patterns/get-pattern`,
      headers: {
        "content-type": "application/json",
      },
    },
    { manual: true }
  );
  
  useEffect(() => {
    if (patternId) {
      (async () => {
        var url = await getCurrentUrl();
        if (url) {
          getPatternDetails({ data: { id: patternId, url: url } });
        }
      })();
    }
    if(isMobile){
      setSideBarCollaped(true);
    }
  }, [patternId]);

  useEffect(() => {
    if (patternRaw) {
      let data = {
        [KEYS.PATTERN_NAME]: patternRaw.name,
        [KEYS.SELECTOR]: patternRaw.selector,
        [KEYS.PARENT]: patternRaw[KEYS.PARENT],
        [KEYS.HIERARCHY]: patternRaw.hierarchy.map((h) => h?.toUpperCase()),
        [KEYS.TYPE]: patternRaw.type && patternRaw.type?.toUpperCase(),
        [KEYS.SUPPORTED_DEVICES]: patternRaw.supported_devices.map((d) =>
          d?.toUpperCase()
        ),
        [KEYS.SPECIAL_PROPERTIES]: patternRaw.special_properties.map((p) =>
          p?.toUpperCase()
        ),
        [KEYS.DISPLAY_NAME]:
          patternRaw[KEYS.DISPLAY_NAME_SELECTOR] ||
          (patternRaw[KEYS.DISPLAY_NAME] &&
            patternRaw[KEYS.DISPLAY_NAME].map((d) => d.lang_code)),
        id: patternId,
        [KEYS.GROUP]:patternRaw.group
      };
      !data[KEYS.DISPLAY_NAME] && delete data[KEYS.DISPLAY_NAME];
      setPatternProperties(data)
    }
  }, [patternRaw]);

  
  useEffect(() => {
    if(selector){
      let isValid = selector !== "" || selectorErrors && !selectorErrors.length;
      setSelectorValid(isValid);
    }
  }, [selector, selectorErrors])

  const clearAllStates = () => {
    setSelector(null);
    setSelectorErros(null);
    setSelectorValid(null);
    setSelectedOption(null);
    setDescription(null);
    setPatternSelectorSelected(null);
    setMenuScriptModal(null);
    //setPatternProperties(null);
  }

  return (
    <CodefixContext.Provider 
      value={{ 
        selector, 
        setSelector, 
        selectorErrors, 
        setSelectorErros, 
        isSelectorValid, 
        selectedOption, 
        setSelectedOption, 
        scriptSelector, 
        setScriptSelector, 
        patternProperties, 
        setPatternProperties, 
        clearAllStates, 
        description, 
        setDescription,
        patternSelectorSelected, 
        setPatternSelectorSelected,
        menuScriptModal,
        setMenuScriptModal,
        setPatternId,
        patternRaw,
        patternLoading,
        PatternErrors
        }}>
            <MenuWidgetProvider>
              {children}
            </MenuWidgetProvider>
      
    </CodefixContext.Provider>
  )
}

function useCodefix() {
  const context = useContext(CodefixContext)
  if (context === undefined) {
    throw new Error(`useCodefix must be used within a CodefixContext`)
  }
  return context
}

export { CodefixProvider, useCodefix }
