import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Tooltip, Button } from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import styled from "styled-components";
import { LeftCircleOutlined, RightCircleOutlined, CloseCircleOutlined, SyncOutlined, ReloadOutlined } from '@ant-design/icons';
import { colors } from "Shared/colors";
import SelectorWidget from "../Utils/SelectorWidget"
import {useTree} from 'Main/Context/TreeContext/TreeContext';

const DatepickerConteinr = styled.div` 
margin-top: 20px;
margin-bottom: 20px;
width: auto;
display: grid;
grid-template-columns: repeat(7, 1fr [col-start]);
grid-template-rows: repeat(7, 28px [row-start]);
`
const CloseBtn = styled(CloseCircleOutlined)`
margin-left: 40px;
border-bottom:2px solid ${colors.LIGHT_BLUE};
background: ${props => props.isValid === null ? colors.LIGHT_ORANGE : !props.isValid.length ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
font-size: 18px;
grid-row: 1;
grid-column: 7;
&:hover{cursor: pointer;}
`
const PrevMonth = styled(LeftCircleOutlined)`
border:2px solid ${colors.LIGHT_BLUE};
background: ${props => props.isValid ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
grid-row: 1;
grid-column: 2;
font-size: 18px;
&:hover{cursor: pointer;}
`
const NextMonth = styled(RightCircleOutlined)`
border:2px solid ${colors.LIGHT_BLUE};
background: ${props => props.isValid ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
grid-row: 1;
font-size: 18px;
grid-column: 6;
&:hover{cursor: pointer;}
`
const DaysHeader = styled.div`
font-weight: bold;
border-bottom:2px solid ${colors.LIGHT_BLUE};
background: ${props => props.isValid === null ? colors.LIGHT_ORANGE : !props.isValid.length ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
grid-row: 2;
grid-column: ${props => props.grid};
&:hover{cursor: pointer;}
`
const ActiveDays = styled.div`
background: ${props => props.isValid ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
color: black;
grid-column: ${props => props.grid};
&:hover{cursor: pointer;}
`
const DisabledDays = styled.div`
border-top:2px solid ${colors.LIGHT_BLUE};
border-left:2px solid ${props => props.isFirst ? colors.LIGHT_BLUE : "rgba(0,0,0,0)"};
background: ${props => props.isValid ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
grid-column: ${props => props.grid};
&:hover{cursor: pointer;}
`
const MonthDate = styled.div`
font-weight: bold;
border:2px solid ${colors.LIGHT_BLUE};
background: ${props => props.isValid ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
grid-row: 1;
grid-column: 3/span 2;
&:hover{cursor: pointer;}
`
const YearDate = styled.div`
font-weight: bold;
border:2px solid ${colors.LIGHT_BLUE};
background: ${props => props.isValid === null ? colors.LIGHT_ORANGE : !props.isValid.length ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
grid-row: 1;
grid-column: 5;
&:hover{cursor: pointer;}
`
const SelectedDays = styled.div`
box-sizing:border-box;
border-right:2px solid ${colors.LIGHT_BLUE};
border-bottom:2px solid ${colors.LIGHT_BLUE};
background: ${props => props.isValid === null ? colors.LIGHT_ORANGE : !props.isValid.length ? colors.LIGHT_GREEN : colors.LIGHT_RED};
display: flex;
justify-content: center;
align-items: center;
color: white;
grid-row: 3;
grid-column: 1;
&:hover{cursor: pointer;}
`
const Circle = styled.div`
line-height: 19px;
border: 2px solid ${colors.BLUE};
border-radius: 50%;
background: ${colors.BLUE};
text-align: center;
vertical-align: middle;
width: 22px;
height: 22px;
`
const StyledCol = styled.div`
text-align: center;
vertical-align: middle;
`
const OpacityCol = styled.div`
opacity: 0.4;
text-align: center;
vertical-align: middle;
`

const StyledRefreshButtonCol = styled(Col)`
display:${props => props.isVisible ? 'flex' : 'none'};
justify-content: flex-end;
align-items: center;
`
const StyledRefreshButton = styled(Button)`
font-size: 14px;
   height: 2rem;
  width:2.0rem;
  border: none;
  border-radius: 0;
  color:white;
  background: ${colors.BLUE};
  &:hover {
    background: ${colors.DARK90} !important;
    color:white;
  }
  &:focus {
    background: ${colors.BLUE} !important;
    color:white;
  }
`

const StyledSelectorWidgetContainer = styled.div`
display: ${props => props.visible};
`


const DatePicker = ({ form, codefixEditData, patternId }) => {

    const [prevMonth, setPrevMonth] = useState({
        required: true,
        label: "previous month",
        name: "prevMonth",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [nextMonth, setNextMonth] = useState({
        required: true,
        label: "Next month",
        name: "nextMonth",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [daysHeader, setDaysHeader] = useState({
        required: false,
        label: "Days header",
        name: "daysHeader",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [selectedDays, setSelectedDays] = useState({
        required: false,
        label: "Selected days",
        name: "selectedDays",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [activeDays, setActiveDays] = useState({
        required: true,
        label: "Active days",
        name: "activeDays",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [disabledDays, setDisabledDays] = useState({
        required: true,
        label: "Disabled days",
        name: "disabledDays",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [monthDate, setMonthDate] = useState({
        required: true,
        label: "Month date",
        name: "monthDate",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [yearDate, setYearDate] = useState({
        required: false,
        label: "Year date",
        name: "yearDate",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "relative"
    });
    const [calendar, setCalendar] = useState({
        required: true,
        label: "Calendar",
        name: "calendar",
        tooltip: "later",
        selectorErrors: null,
        visible: true,
        selectorType: "relative"
    });
    const [closeButton, setCloseButton] = useState({
        required: false,
        label: "Close button",
        name: "closeButton",
        tooltip: "later",
        selectorErrors: null,
        visible: false,
        selectorType: "absolute"
    });
    const [isFormFieldsHaveErrors, setIsFormFieldsHaveErrors] = useState(null);
    const {updatePatternTree, tree} = useTree();

    const formFields = {
        prevMonth: {
            state: prevMonth,
            setState: setPrevMonth
        },
        nextMonth: {
            state: nextMonth,
            setState: setNextMonth
        },
        daysHeader: {
            state: daysHeader,
            setState: setDaysHeader
        },
        selectedDays: {
            state: selectedDays,
            setState: setSelectedDays
        },
        activeDays: {
            state: activeDays,
            setState: setActiveDays
        },
        disabledDays: {
            state: disabledDays,
            setState: setDisabledDays
        },
        monthDate: {
            state: monthDate,
            setState: setMonthDate
        },
        yearDate: {
            state: yearDate,
            setState: setYearDate
        },
        closeButton: {
            state: closeButton,
            setState: setCloseButton
        },
    }


    useEffect(() => {
        if (codefixEditData) {
            form.setFieldsValue({
                prevMonth: codefixEditData.data.prevMonth,
                nextMonth: codefixEditData.data.nextMonth,
                daysHeader: codefixEditData.data.daysHeader,
                selectedDays: codefixEditData.data.selectedDays,
                activeDays: codefixEditData.data.activeDays,
                disabledDays: codefixEditData.data.disabledDays,
                monthDate: codefixEditData.data.monthDate,
                yearDate: codefixEditData.data.yearDate,
                calendar: codefixEditData.data.calendar,
                closeButton: codefixEditData.data.closeButton,
            });
            form.validateFields();
        }
    }, [codefixEditData])


    useEffect(() => {
        setIsFormFieldsHaveErrors(!!(Object.entries(formFields).filter(([key, field]) => field.state.selectorErrors && field.state.selectorErrors.length).length));
    }, [prevMonth, nextMonth, daysHeader, selectedDays, activeDays, disabledDays, monthDate, yearDate, calendar, closeButton])

    useEffect(() => {
        updatePatternTree();
    },[])

    useEffect(() => {
        form.validateFields();
    }, [tree])

    const activeDaysArr = [];
    for (let i = 2; i < 31; i++) {
        activeDaysArr.push(i);
    }
    const disabledDaysArr = [];
    for (let i = 1; i < 6; i++) {
        disabledDaysArr.push(i);
    }
    const daysHeaderArr = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

    const showFormField = (name) => {
        Object.entries(formFields).map(([key, value]) => value.setState({ ...value.state, visible: false }))
        formFields[name].setState({ ...formFields[name].state, visible: true })
        // setTimeout(() => {
        //     const activeElement = document.querySelector(`#codefix_${name}`);
        //     activeElement.scrollIntoView();
        // }, 500)
    }

    return (
        <div id="datePicker">
            <Row >
                <Col span={isFormFieldsHaveErrors ? 22 : 24}>
                    <SelectorWidget
                        setState={setCalendar}
                        state={calendar}
                        patternId={patternId} />
                </Col>
                <StyledRefreshButtonCol span={2} isVisible={isFormFieldsHaveErrors}>
                    <Tooltip placement="left" title="Refresh after datepicker is visible">
                        <StyledRefreshButton icon={<ReloadOutlined />} onClick={() => updatePatternTree()} />
                    </Tooltip >
                </StyledRefreshButtonCol>
            </Row>
            <DatepickerConteinr id="container">

                <PrevMonth isValid={prevMonth.selectorErrors && !prevMonth.selectorErrors.length} onClick={() => { showFormField("prevMonth") }} />
                <MonthDate isValid={monthDate.selectorErrors && !monthDate.selectorErrors.length} onClick={() => { showFormField("monthDate") }}><StyledCol>August</StyledCol></MonthDate>
                <YearDate isValid={yearDate.selectorErrors} onClick={() => { showFormField("yearDate") }}><StyledCol>2020</StyledCol></YearDate>
                <NextMonth isValid={nextMonth.selectorErrors && !nextMonth.selectorErrors.length} onClick={() => { showFormField("nextMonth") }} />
                <CloseBtn isValid={closeButton.selectorErrors} onClick={() => { showFormField("closeButton") }} />
                {
                    daysHeaderArr.map((val, i) => <DaysHeader isValid={daysHeader.selectorErrors} onClick={() => { showFormField("daysHeader") }} grid={i + 1} key={val}><StyledCol>{val}</StyledCol></DaysHeader>)
                }
                <SelectedDays isValid={selectedDays.selectorErrors} onClick={() => { showFormField("selectedDays") }}><Circle>1</Circle></SelectedDays>
                {
                    activeDaysArr.map((val, i) => <ActiveDays isValid={activeDays.selectorErrors && !activeDays.selectorErrors.length} onClick={() => { showFormField("activeDays") }} grid={((i + 1) % 7) + 1} key={val}><StyledCol>{val}</StyledCol></ActiveDays>)
                }
                {
                    disabledDaysArr.map((val, i) => <DisabledDays isFirst={!i} isValid={disabledDays.selectorErrors && !disabledDays.selectorErrors.length} onClick={() => { showFormField("disabledDays") }} grid={((i + 2) % 7) + 1} key={val}><OpacityCol>{val}</OpacityCol></DisabledDays>)
                }
            </DatepickerConteinr>
            {
                Object.entries(formFields).map(([key, value]) =>
                    <StyledSelectorWidgetContainer visible={value.state.visible ? "block" : "none"}>
                        <SelectorWidget
                            key={key}
                            state={value.state}
                            setState={value.setState}
                            patternId={patternId}
                        />
                    </StyledSelectorWidgetContainer>)
            }
        </div >
    )
}

export const getDatePickerData = (form) => {
    let value = form.getFieldsValue([
        "prevMonth",
        "nextMonth",
        "daysHeader",
        "selectedDays",
        "activeDays",
        "disabledDays",
        "monthDate",
        "yearDate",
        "calendar",
        "closeButton"
    ]);
    return value;
}

export default DatePicker;