import React, { useState, useEffect, useRef } from "react";
import { Tooltip, Popover, Button, Input } from "antd";
import styled from "styled-components";
import { toastInfo, toastSuccess } from "Shared/utils";
import { colors } from "Shared/colors";
import { FileTextOutlined, EditOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const StyledButton = styled(Button)`
border: 0px;
width:25px !important;
:focus{
    color:#fff
}
`;


const Selector = ({ image, updateImages, setForceVisible, forceVisivle }) => {
    const selector = unescape(image.selector);
    return (
        <Popover
            onVisibleChange={(visible) => setForceVisible({ ...forceVisivle, selector: visible })}
            title={<div>Selector</div>}
            content={<EditSelector updateImages={updateImages} image={image} />}
            placement="topRight"
            mouseLeaveDelay="1" >
            <CopyToClipboard text={selector}
                onCopy={() => toastInfo(`Selector: ${selector} copied to clipboard`, 2)}>
                <StyledButton size={"large"} ghost={true} icon={<FileTextOutlined />} />
            </CopyToClipboard>
        </Popover >
    );
}

const StyledEditButton = styled(Button)`
width:25px !important;
align-self: center;
text-align: center;
border:0px;
`
const StyledInput = styled(Input)`
flex:1;
padding:0px;
padding-right:5px;
background-color: transparent !important;
border: 0px !important;
color: black !important;
cursor: default !important;
:focus{
  box-shadow: none !important;
}
text-overflow:ellipsis;
width:${props => props.textLength > 60 ? 55 : props.textLength - 5}ch;
`;
const StyledContainer = styled.div`
    display:flex;
    width:auto;
`

const EditSelector = ({ image, updateImages }) => {
    const selector = unescape(image.selector);
    const [isEditable, setIsEditable] = useState(false);
    const [textLength, setTextLength] = useState(selector.length)
    const inputRef = useRef(null);

    useEffect(() => {
        if (selector.length) {
            setTextLength(selector.length);
        }
    }, [image]);

    const HandleInlineTextChange = (newText) => {
        if (newText != selector) {
            const newImage = {
                ...image,
                selector: escape(newText)
            };
            updateImages([newImage]);
        }
        setIsEditable(false);
    }

    useEffect(() => {
        if (isEditable) {
            inputRef.current.focus();
        }
    }, [isEditable]);

    return (
        <StyledContainer>
            <StyledInput
                textLength={textLength}
                ref={inputRef}
                disabled={!isEditable}
                autoComplete="off"
                onBlur={(e) => HandleInlineTextChange(e.target.value)}
                defaultValue={selector}
            />
            <Tooltip title="Edit">
                <StyledEditButton
                    onClick={() => setIsEditable(!isEditable)}
                    size={"large"}
                    ghost={true}
                    icon={!isEditable && <EditOutlined style={{ color: colors.BLUE }} />
                    }
                />
            </Tooltip>
        </StyledContainer>
    )
}



export default Selector;