import React from "react"
import styled from "styled-components";
import { colors } from "../../../../Shared/colors"
import { Popconfirm } from 'antd';
import { useScripter } from "Scripter/Context/ScripterContext";

const StyledUl = styled.ul`
    z-index:10 !important;
    padding:5px !important;
    background-clip: padding-box !important;
    background-color: ${colors.BLACK} !important;
    border-radius: 4px !important;
    box-shadow:-2px 2px 10px ${colors.BLACK} !important;
    list-style-type: none !important;
    margin: 0 !important;
    outline: none !important;
    position: absolute !important;
    text-align: left !important; 
    overflow: hidden !important;
    -webkit-box-shadow:-2px 2px 10px ${colors.BLACK} !important;
`

const StyledLi = styled.li`
    clear: both;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    transition: all .3s;
    white-space: nowrap;
    -webkit-transition: all .3s;
    :hover{
        background-color: ${colors.DARK100};
    }
`
const NodeMenuPopup = ({ nodeMenuPopupState, setNodeMenuPopupState }) => {
    const { DeleteItem, Rename, scopeModalProps, setScopeModalProps} = useScripter();

    return (
        nodeMenuPopupState.visible &&
        <StyledUl data="u1st_prevent_close_popup" style={{ left: `${nodeMenuPopupState.x}px`, top: `${nodeMenuPopupState.y - 30}px` }}>
            <Popconfirm
                placement="right"
                title={`Are you sure you want to delete this ${nodeMenuPopupState.node.isLeaf ? 'script' : 'folder'}?`}
                onConfirm={() => {
                    DeleteItem(nodeMenuPopupState.node);
                    setNodeMenuPopupState({ visible: false });
                }}
                okText="Yes"
                cancelText="No">
                <StyledLi>Delete</StyledLi>
            </Popconfirm>
            <StyledLi onClick={() => {
                Rename(nodeMenuPopupState.node);
                setNodeMenuPopupState({ visible: false });
            }}>Rename</StyledLi>
            <StyledLi onClick={() => {
                setNodeMenuPopupState({ visible: false });
            }}>Report</StyledLi>
            {
                nodeMenuPopupState?.node && !nodeMenuPopupState.node.isLeaf &&
                <StyledLi onClick={() => {
                    setScopeModalProps({
                        folderName: nodeMenuPopupState.node.title,
                        visible: true,
                        id: nodeMenuPopupState.node.key
                    })
                    setNodeMenuPopupState({ visible: false });
                }}>Properties</StyledLi>
            }
        </StyledUl>
    )
}

export default NodeMenuPopup