import React from "react";
import styled from "styled-components";
import { colors, backgrounds } from "Shared/colors";

const StyledBadge = styled.div`
  height: 15px;
  width: 15px;
  color: ${colors.DARK100};
  text-align: center;
  line-height: 15px;
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
  font-family: sans-serif;
`;

function Badge({ title, style }) {
  return (
    <StyledBadge style={style}>
      {title}
    </StyledBadge>
  );
}

const TreeItemBadge = ({ type, count, isBroken, visible }) => {
  const title = type.charAt(0)
  let borderRadius = '10%'
  if(title !== "p" && title !== "g") {
    borderRadius = '50%'
  }
  if (isBroken) {
    return (
      <Badge title={title}
        style={{ backgroundImage: backgrounds.BROKEN, borderRadius: borderRadius }}
      />
    );
  } else 
  {
    if(!visible)
    {
      return (
        <Badge title={title}
          style={{ backgroundImage: backgrounds.GREY, borderRadius: borderRadius }}
        />
      );
    }
    else {
      if (count && count > 1) {
        return (
          <Badge title={title}
            style={{ backgroundImage: backgrounds.FOUND_MULTIPLE, borderRadius: borderRadius }}
          />
        );
      }
      if (count && count == 1) {
        return (
          <Badge title={title}
            style={{ backgroundImage: backgrounds.FOUND, borderRadius: borderRadius }}
          />
        );
      }
      if (!count || count == 0) {
        return (
          <Badge title={title}
            style={{ backgroundImage: backgrounds.NOT_FOUND, borderRadius: borderRadius }}
          />
        );
      }
    }
  }
};

export default TreeItemBadge;
