import React from 'react';
import { Modal } from 'antd';
import Copy from "./Copy";

export const CopyModal = ({ copyModalVisible, setCopyModalVisible }) => {

    return (
        <Modal
            title="Copy site definition"
            footer={null}
            visible={copyModalVisible}
            onCancel={() => { setCopyModalVisible(false) }}
            destroyOnClose={true}
        >
            <Copy />
        </Modal >
    )
}