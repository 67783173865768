import Axios from 'axios'
import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import { LS_ACCESS_TOKEN, LS_LOGGED_IN } from 'Auth/AuthContext'
import { toastError } from 'Shared/utils'

export const axios = Axios.create({
	baseURL: `${process.env.REACT_APP_API_ADDRESS}`,
	timeout: 200000000
})

axios.interceptors.request.use(
	function(request) {
		const accessToken = window.localStorage.getItem(LS_ACCESS_TOKEN)
		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`
		}
		return request
	}
)

axios.interceptors.response.use(
	function(response) {
		return response
	},
	function(error) {
		let status = error && error.response ? error.response.status : 404;
		if(status == 401) {
			window.localStorage.removeItem(LS_LOGGED_IN);
			window.localStorage.removeItem(LS_ACCESS_TOKEN);
			window.location.reload();
		}
		if(status == 403) {
			toastError("You're not authorized to view this content");
		}
		if(status == 503) {
			toastError(error.response.data);
		}
		return Promise.reject(error)
	}
)


export const apiClient = (route, { body, ...customConfig } = {}) => {
	const headers = { 'content-type': 'application/json' }
	const accessToken = window.localStorage.getItem(LS_ACCESS_TOKEN)
	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`
	}
	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers
		}
	}
	if (body) {
		config.data = body
	}
	return axios(route, config)
}

const cache = new LRU({ max: 10 })
configure({ axios, cache})