import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Login from "./Auth";
import Main from "./Main/Views";
import { Router } from "@reach/router";
import { AuthProvider } from "./Auth/AuthContext";
import { GlobalProvider } from "GlobalContext/GlobalContext";

const Routes = () => (
  <Router>
    <App path="/" />
    <Login path="/login" />
    <Main path="/main/default" />
  </Router>
);

const root = (
  <AuthProvider>
    <GlobalProvider>
      <div id="app">
        <App />
      </div>
    </GlobalProvider>
  </AuthProvider>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
