import styled from "styled-components";
import { Menu } from 'antd';
import React from "react";
import { PRODUCT, ADDRESS, MESSAGES, KEYS, SCRIPTER_PREVIEW_MODE } from 'Scripter/Views/consts';
import { StyledMenu, MenuItem } from "Scripter/Shared/MenuStyles";
import { useSwitch } from "Scripter/Context/SwitchContext";
import { sendMessage } from "Shared/messages";
import { Storage } from "Shared/Services/storage";

const { SubMenu } = Menu;

const StyledSubMenu = styled(SubMenu)`
&:hover .ant-dropdown-menu-submenu-title{
    background-color: transparent;
}
&:hover{
    background-color: #4E0E7E;
    border-radius: 10px;
}
.ant-dropdown-menu-title-content, svg{
    color: white;
}
border: 5px solid black;
`;

const StyledDropdown = () => {

    const { activeAddress, setActiveAddress, activeProd, setActiveProd, changeProduct, changeAddress } = useSwitch();

    const activateProduct = (prodToActivate) => {

        const prevProd = activeProd;
        changeProduct(prodToActivate);
        setActiveProd(prodToActivate);

        const previewMode = Storage.GetItem(SCRIPTER_PREVIEW_MODE);
        const shouldReload = (prevProd == PRODUCT.FRONTEND || previewMode == 'true' || prodToActivate == PRODUCT.FRONTEND);
        if (shouldReload) {
            Storage.RemoveItem(SCRIPTER_PREVIEW_MODE);
            sendMessage({ type: MESSAGES.RELOAD });
        }

    };

    return (<StyledMenu mode="vertical">
        <MenuItem activeItem={activeProd ? activeProd == KEYS.TOOLBAR : undefined} key={KEYS.TOOLBAR}>
            <a target="_blank" rel="noopener noreferrer" onClick={() => {
                activateProduct(PRODUCT.TOOLBAR);
            }}>
                Toolbar
            </a>
        </MenuItem>
        <MenuItem activeItem={activeProd == KEYS.FRONTEND} key={KEYS.FRONTEND}>
            <a target="_blank" rel="noopener noreferrer" onClick={() => {
                activateProduct(PRODUCT.FRONTEND);
            }}>
                Frontend
            </a>
        </MenuItem>
        <MenuItem activeItem={activeProd == KEYS.IDE} key={KEYS.IDE}>
            <a target="_blank" rel="noopener noreferrer" onClick={() => {
                activateProduct(PRODUCT.IDE);
            }}>
                IDE
            </a>
        </MenuItem>
        <StyledSubMenu key="sub" title={<span style={{ fontSize: "12px" }}>DEV</span>}>
            <MenuItem activeItem={activeAddress == KEYS.PRODUCTION} key={KEYS.PRODUCTION}>
                <a target="_blank" rel="noopener noreferrer" onClick={() => {
                    changeAddress(ADDRESS.PROD)
                    setActiveAddress(KEYS.PRODUCTION)
                    sendMessage({ type: MESSAGES.RELOAD })
                }}>Production</a>
            </MenuItem>
            <MenuItem activeItem={activeAddress == KEYS.LOCAL_HOST} key={KEYS.LOCAL_HOST}>
                <a target="_blank" rel="noopener noreferrer" onClick={() => {
                    changeAddress(ADDRESS.LOCALHOST)
                    setActiveAddress(KEYS.LOCAL_HOST)
                    sendMessage({ type: MESSAGES.RELOAD })
                }}>Localhost</a>
            </MenuItem>
        </StyledSubMenu>
    </StyledMenu>
    );
}

export { StyledDropdown };