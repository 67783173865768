import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { colors } from "Shared/colors";
import { useTree } from "Main/Context/TreeContext/TreeContext";
const CheckboxGroup = Checkbox.Group;

const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: grid;
  grid-template-rows: 32px 24px 24px;
  grid-gap: 5px;

  .ant-checkbox-wrapper {
    margin: 0;
  }

  .ant-checkbox input {
    border-color: ${colors.BLUE};
    &:hover {
      border-color: ${colors.BLUE};
    }
    &:after {
      background-color: ${colors.BLUE};
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.BLUE};
    border-color: ${colors.BLUE};
    &:hover {
      border-color: ${colors.BLUE};
    }
  }

  .ant-checkbox-inner {
    background-color: white;
    border-color: ${colors.BLUE};
    &:hover {
      border-color: ${colors.BLUE};
    }
    &:after {
      background-color: ${colors.BLUE};
    }
  }
`;

const Title = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
`;

const StyledSearchMenu = () => {
  const { selectedFilterOptions, setSelectedFilterOptions } = useTree();
  const showHidden = selectedFilterOptions && selectedFilterOptions.includes("hidden");

  const onFilter = (values) =>
  {
    if(values.includes("hidden")){
      values = ["hidden"];
    }
    else if(showHidden){
      values = (["notOnPage", "broken"]);
    }
    setSelectedFilterOptions(values);
  }


  return (
    <StyledCheckboxGroup
      value={selectedFilterOptions}
      onChange={onFilter}
    >
      <Title>Please select items to view</Title>
      <Checkbox disabled={showHidden} value="notOnPage">Items not on page</Checkbox>
      <Checkbox disabled={showHidden} value="broken">Broken items</Checkbox>
      <Checkbox value="hidden">Show only Hidden items</Checkbox>
    </StyledCheckboxGroup>
  );
};

export default StyledSearchMenu;
