import React, { useContext, createContext, useEffect, useState, useReducer } from 'react'
import {socketService, SOCKET_KEYS} from './SocketService';
import {useTree} from '../TreeContext/TreeContext';
import {useGlobal} from 'GlobalContext/GlobalContext';

const NotificationContext = createContext();

function messageReducer(state, action) {
    switch (action.type) {
      case 'new':
        return [...state, action.msg];
      default:
        return [];
    }
}

const NotificationProvider = ({children}) => {
    const {updatePatternTree} = useTree();
    const {url} = useGlobal();
    const [changeDetectionModal, setChangeDetectionModal] = useState(null); // {executionFunc: function}
    const [newMessages, setNewMessages] = useReducer(messageReducer, []);

    useEffect(() => {
        return () => {
            socketService.invokeSocketAction(SOCKET_KEYS.LEAVE_GROUP);
            clearAllStates();
        }
    }, [])

    useEffect(() => {
        if(url){
            socketService.connect(url);
            socketService.addEvent(SOCKET_KEYS.RECEIVE_MESSAGE, function(msg){
                setNewMessages({type: "new", msg});
                updatePatternTree();
            });
        }
    }, [url])

    const sendSocketMessage = (msg) => {
        socketService.sendMessage(SOCKET_KEYS.SEND_MESSAGE, url, msg)
    }

    const clearAllStates = () => {
        setNewMessages([]);
        setChangeDetectionModal(false);
    }

    return (
        <NotificationContext.Provider value={{ sendSocketMessage, newMessages, setNewMessages, changeDetectionModal, setChangeDetectionModal}}>
            {children}
        </NotificationContext.Provider>
    )
}

function useNotification() {
    const context = useContext(NotificationContext)
    if (context === undefined) {
        throw new Error(`useNotification must be used within a NotificationContext`)
    }
    return context
}

export { NotificationProvider, useNotification }
