const monacoEditorOptions = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: false,
    cursorStyle: "line",
    scrollBeyondLastLine: false
};
const defaultEditorValue = {
    scripts: `function(domElement, $, options) {
        //write your code here ...
        
    }
    `,
    siteScripts: ' ',
    menuScript: `function(domElement, $, api) {
    //write your code here ...        
}`
}


export {
    monacoEditorOptions,
    defaultEditorValue
}