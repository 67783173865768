export const KEYS = {
    ID: "id",
    URL: "url",
    PATTERN_NAME: "patternName",
    SELECTOR: "selector",
    PARENT: "parent_from_selector",
    HIERARCHY: "hierarchy",
    TYPE: "type",
    SUPPORTED_DEVICES: "supported_devices",
    SPECIAL_PROPERTIES: "special_Properties",
    DISPLAY_NAME: "display_names",
    DISPLAY_NAME_SELECTOR: "display_name_selector",
    SITE_ID: "site_id",
    PARENT_PATTERN: "parent_pattern",
    LANGUAGES: "languages",
    ALLOW_GROUPING: "allow_grouping",
    GROUP: "group"
}

export const OPTIONS = {
    ALL_DEVICES: "ALL DEVICES",
    DESKTOP: "DESKTOP",
    MOBILE: "SMARTPHONE",
    TABLET_PROTRET: "TABLET PROTRAIT",
    TABLET_LANDSCAPE: "TABLET LANDSCAPE",
    DYNAMIC: "DYNAMIC",
    DYNAMIC_DEPRECATED: "DYNAMIC (DEPRECATED)",
    LAZY_LOADING: "LAZY LOADING",
    IMPORTANT: "IMPORTANT",
    FLASHING: "FLASHING",
    MOVING: "MOVING",
    ALTERNETIVE_NAVIGATION: "INCLUDE IN ALTERNATIVE NAVIGATION",
    HIERARCHY_DEFAULT: "DEFAULT",
    HIERARCHY_ROOT: "ROOT",
    NO_CHILDREN: "NO CHILDREN",
    COMPLEMENTRY: "COMPLEMENTRY",
    FOOTER: "FOOTER",
    FORM: "FORM",
    MAIN: "MAIN",
    REGION: "REGION",
    SEARCH: "SEARCH",
    HEADER: "HEADER",
    SITE_NAVIGATION: "SITE NAVIGATION",
    ALERT: "ALERT",
    APPLICATION: "APPLICATION",
    BLOCKED_TOOLTIP: "BLOCKED TOOLTIP",
    DIALOG: "DIALOG",
    DROPDOWN: "DROPDOWN",
    MENU_WIDGET: "MENU WIDGET",
    NOTIFY: "NOTIFY",
    SECTION: "SECTION",
    TOOLTIP: "TOOLTIP",
    GROUP: "GROUP",
}

export const CRATE_PATTERN_KEYS = [
    KEYS.PATTERN_NAME, 
    KEYS.SELECTOR, 
    KEYS.PARENT, 
    KEYS.HIERARCHY, 
    KEYS.TYPE, 
    KEYS.SPECIAL_PROPERTIES, 
    KEYS.DISPLAY_NAME,
    KEYS.SUPPORTED_DEVICES
]