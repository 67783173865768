import React, {useEffect} from 'react';
import {Select} from 'antd';
import { TooltipLabel } from 'Shared/Components/TooltipLabel';
import {tooltipsContent} from '../data';
import {FormItem} from '../PatternForm';
import { KEYS, OPTIONS } from '../consts';

export const isMainOrSiteNavLandmark = (selectedOption) => {
    return selectedOption === OPTIONS.SITE_NAVIGATION || selectedOption ===  OPTIONS.MAIN;
}

export const isLandmark = (landmarks, value) => {
    let landmarkList = landmarks && landmarks.map((l) => l.value);
    return landmarkList && landmarkList.includes(value);
}

export const Type = ({setLandmarkType, disabled, landmarks, components, isLandmarkType}) => {
    const { Option, OptGroup } = Select;
    landmarks = landmarks || [];
    components = components || [];
    return (
        <div>
            <TooltipLabel value="TYPE" required tooltip={{ text: tooltipsContent.type }} />
            <FormItem name={KEYS.TYPE} rules={[{ required: true }]}>
                <Select
                    showSearch
                    disabled={disabled}
                    onSelect={(value) => {
                        isLandmark(landmarks, value) ? setLandmarkType({state: true, selectedOption: value}) : setLandmarkType({state: false, selectedOption: value});
                    }}
                    placeholder="TYPE"
                >
                    <OptGroup label="LANDMARKS">
                        {
                            landmarks.map((option, i) => <Option value={option.value} key={i}>{option.value}</Option>)
                        }
                    </OptGroup>
                    <OptGroup label="COMPONENTS">
                        {
                            components.map((option, i) => <Option disabled={option.value === OPTIONS.BLOCKED_TOOLTIP || option.value === OPTIONS.GROUP}  value={option.value} key={i+option.value}>{option.value}</Option>)
                        }
                    </OptGroup>
                </Select>
            </FormItem>
        </div>

    )
}
